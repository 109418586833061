import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataDocs } from "../../types/tables-data";

export type UploadConfiguration = {
  // individual id
  uid: string;
  // cloud storage path
  path: string;
  // Firestore path path
  collectionPath?: string;
  // mainDocument Id if present
  mainDocument?: DataDocs;
  // super class for upload by category
  super_class?: string;
  // category for upload by category
  category?: string;
  // type for upload by category
  type?: string;
};

export type FileUploadState = {
  files: File[];
  uploadProgress: { [key: string]: number };
  isLoading: boolean;
  visible: boolean;
  expanded: boolean;
  error: string | null;
  uploadConfiguration: UploadConfiguration;
};
const initialState: FileUploadState = {
  files: [],
  uploadProgress: {},
  isLoading: false,
  error: null,
  visible: false,
  expanded: false,
  uploadConfiguration: {
    path: "",
    uid: ""
  }
};

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    setUploadConfiguration: (
      state,
      action: PayloadAction<UploadConfiguration>
    ) => {
      const configuration = action.payload;
      state.uploadConfiguration = { ...configuration };
    },
    setVisibilty: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setExpanded: (state, action: PayloadAction<boolean>) => {
      state.expanded = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFiles(state, action: PayloadAction<File[]>) {
      state.files = [...action.payload];
      state.isLoading = true;
    },
    clearFiles(state) {
      state.files = [];
      state.uploadProgress = {};
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setUploadProgress: (
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      state.uploadProgress = action.payload;
      console.log(state.uploadProgress);
    }
  }
});

export const {
  setFiles,
  clearFiles,
  setVisibilty,
  setExpanded,
  setUploadProgress,
  setIsLoading,
  setError,
  setUploadConfiguration
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
