import { ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db, storage } from "../api/firebaseApi";
import { DATABASE, DataDocs } from "../types/tables-data";
import { createUniqueFileForUpload } from "../helpers/file_helpers";

export const useAddFileToDB = () => {
  const [isLoadingAddFile, setIsLoadingAddFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<Map<string, number>>(
    new Map()
  );
  const { id } = useParams();
  const fileRef = useRef<HTMLInputElement | null>(null);
  const filePath = `${DATABASE.INDIVIDUALS}/documents/${id}`;

  const [files, setFiles] = useState<File[]>([]);

  const onSubmitAddFileWithPercentage = async (_files: File[], allowOverwrite = false) => {
    setIsLoadingAddFile(true);
    const currentProgress = new Map();

    const uploadPromises = await Promise.all(_files.map(async (originalFile) => {
      let result;
      if (allowOverwrite) {
        // Directly use the provided path without renaming
        result = { file: originalFile, path: `${filePath}/${originalFile.name}` };
      } else {
        // Use the existing logic to create a unique file name
        result = await createUniqueFileForUpload(filePath, originalFile);
      }
      const { file, path } = result;

      return new Promise((resolve, reject) => {
        const imageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(imageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            currentProgress.set(file.name, progress);
            setUploadProgress(new Map(currentProgress));
          },
          (error) => {
            console.error(error);
            reject(error);
          },
          async () => {
            const userDocRef = collection(db, `documents/${id}/docs`);
            const document = {
              filePath: imageRef.fullPath,
              docNames: imageRef.name,
              documentTitle: "",
              uploadBy: "Test",
              uploadDate: Date.now()
            };
            await addDoc(userDocRef, document);
            resolve(true);
          }
        );
      });
    }));

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAddFile(false);
      setFiles([]); // Clear the files after upload
    }
  };

  // const onSubmitAddFileWithPercentage = async (
  //   _files: File[],
  //   path: string
  // ) => {
  //   setIsLoadingAddFile(true);
  //   const currentProgress: Map<string, number> = new Map();

  //   _files.forEach((file) => {
  //     const imageRef = ref(storage, `${path}/${file.name}`);
  //     const uploadTask = uploadBytesResumable(imageRef, file);
  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {
  //         const progress =
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         currentProgress.set(file.name, progress);

  //         setUploadProgress(new Map(currentProgress));
  //       },
  //       (error) => {
  //         console.error(error);
  //         setIsLoadingAddFile(false);
  //       },
  //       async () => {
  //         // finish upload
  //         setIsLoadingAddFile(false);
  //         const filteredFiles = _files?.filter(
  //           (_file) => _file.name !== file.name
  //         );

  //         const userDocRef = collection(db, `documents/${id}/docs`);

  //         const document: DataDocs = {
  //           filePath: imageRef.fullPath,
  //           docNames: imageRef.name,
  //           documentTitle: "",
  //           uploadBy: "Test",
  //           uploadDate: Date.now()
  //         };
  //         await addDoc(userDocRef, document);
  //       }
  //     );
  //   });
  // };
  useEffect(() => {
    if (files && files?.length !== 0) {
      onSubmitAddFileWithPercentage(files);
    }
  }, [files?.length]);

  const onSubmitAddFile = async (file: File, path: string, allowOverwrite = false) => {
    let result;
    if (allowOverwrite) {
      // Directly use the provided path without renaming
      result = { file, path };
    } else {
      // Rename the file to guarantee existing files aren't accidentally overwritten
      result = await createUniqueFileForUpload(path, file);
    }

    const imageRef = ref(storage, result.path);
    setIsLoadingAddFile(true);

    try {
      await uploadBytes(imageRef, result.file);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAddFile(false);
    }
    return { file: result.file, path: result.path };
  };

  return {
    isLoadingAddFile,
    fileRef,
    files,
    setFiles,
    uploadProgress,
    onSubmitAddFileWithPercentage,
    onSubmitAddFile
  };
};
