import { useDispatch } from "react-redux";
import { filesHasUnsupportedExtension } from "../helpers/file_helpers";
import { VisaDocumentType } from "../redux/documents/types";
import {
  UploadConfiguration,
  setError,
  setExpanded as setFileWindowExpanded,
  setFiles,
  setUploadConfiguration,
  setVisibilty
} from "../redux/file-upload/fileUploadSlice";
import { uploadFiles } from "../redux/file-upload/fileUploadThunk";
import { DataDocs } from "../types/tables-data";

export type FileActionsProps = {
  visaDocumentType?: VisaDocumentType;
  files: File[];
  uid: string;
  mainDocumentId?: string;
  mainDocument?: DataDocs;
  path: string;
  collectionPath?: string;
  type?: string;
  category?: string;
  super_class?: string;
};

const useFileActions = () => {
  const dispatch = useDispatch();

  const handleFileActions = ({
    files,
    uid,
    mainDocumentId,
    mainDocument,
    visaDocumentType,
    path,
    collectionPath,
    super_class,
    category,
    type
  }: FileActionsProps) => {
    const filesAsArray = Array.from(files);
    const filesHasOtherExtension = filesHasUnsupportedExtension(filesAsArray);
    dispatch(setFiles(filesAsArray));

    const uploadConfig: UploadConfiguration = {
      uid: uid ?? "",
      path,
      collectionPath,
      mainDocument,
      super_class,
      type,
      category
    };

    console.log(
      "Dispatching setUploadConfiguration with:",
      JSON.stringify(uploadConfig, null, 2)
    );

    dispatch(setUploadConfiguration(uploadConfig));

    if (!filesHasOtherExtension) {
      dispatch(setVisibilty(true));
      dispatch(setFileWindowExpanded(true));

      dispatch(uploadFiles() as any);
    } else {
      dispatch(setError("extension-error"));
    }
  };

  return {
    handleFileActions
  };
};

export default useFileActions;
