import { Box, BoxProps, Flex, Heading, Text } from "@chakra-ui/react";

interface Props extends BoxProps {
  title: string;
  backNav?: boolean;
  asTab?: boolean;
  withInfo?: React.ReactNode;
  subTitle?: string;
  topTitle?: string;
}

export const SpecialHeading = ({
  title,
  backNav = false,
  withInfo,
  topTitle,
  ...rest
}: Props) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="start"
      width="100%"
      {...rest}
      textAlign="left"
    >
      {topTitle && topTitle !== "" && (
        <Text textColor="#111928" fontSize="12px" fontWeight={600}>
          {topTitle.toUpperCase()}
        </Text>
      )}
      <Heading
        // mx={4}
        mt={2}
        fontFamily="Inter"
        color="text.display.light"
        size="md"
        textAlign="start"
        {...rest}
      >
        <Text className="relative">
          {title === "" ? null : title} {withInfo}
        </Text>
      </Heading>
    </Flex>
  );
};

/** a wrapper for more consistent panel layouts
 *  @param {string} title : heading title
 */

export const PanelLayout = ({
  children,
  title,
  asTab,
  withInfo,
  ...rest
}: Props) => {
  const excludedTitles = ["Expert letter questionnaires", "Drafts", "Packet"];
  return (
    <Box
      className="!w-[100%] bg-white p-2 border"
      borderRadius={4}
      style={{ boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.1)" }}
    >
      {!excludedTitles.includes(title) && (
        <SpecialHeading px={2} title={title} withInfo={withInfo} {...rest} />
      )}
      <Box className="flex flex-col">{children}</Box>
    </Box>
  );
};
