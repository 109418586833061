import { Box } from "@chakra-ui/react";
import { getSubdomain } from "../helpers/helpers";

export const DomainFilter = ({ children, inline }: any) => {
  const subdomain = getSubdomain();

  if (subdomain !== "localhost" && subdomain !== "app") return null;
  return (
    <Box style={{ display: inline ? "inline" : "contents" }} as="span">
      {children}
    </Box>
  );
};
