import { AddIcon, ArrowDownIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getShortString } from "../../helpers/string_helpers";

type BaseProps<T> = {
  label?: string;
  withArrow?: boolean;
  list: T[];
  isDisabled?: boolean;
  withToolTip?: boolean;
  withFilter?: boolean;
  searchField?: keyof T;
  mergingField?: keyof T;
  groupBy?: keyof T; // Added groupBy prop
  checkedItems?: string[] | null | undefined;
};
type MultiSelectProps<T> = BaseProps<T> & {
  singleSelect: false; // Discriminator
  getSelectedItems: (items: T[]) => void;
  withCheck?: boolean;
  withView?: boolean;
};

type SingleSelectProps<T> = BaseProps<T> & {
  singleSelect: true; // Discriminator
  getSelectedItem: (item: T) => void;
  withCheck?: never; // Disallow
  withView?: never; // Disallow
};

type Props<T> = MultiSelectProps<T> | SingleSelectProps<T>;

type CheckedItem<T> = {
  checked: boolean;
  value: string;
  id: string;
} & T;

// Styling configurations
const tagStyles = {
  borderRadius: "md",
  border: "1px solid",
  borderColor: "stroke.stroke1Light",
  bg: "transparent",
  color: "primary.blue",
  _hover: { bg: "primary.focusOutline" },
  _focus: { boxShadow: "0 0 0 2px rgba(13, 89, 255, 0.5)" }
};

export const DropdownGeneric = <T,>({
  label,
  singleSelect,
  list,
  isDisabled,
  withToolTip = false,
  withFilter = true,
  withArrow = false,
  searchField,
  mergingField,
  groupBy,
  checkedItems,
  ...rest
}: Props<T>) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState<CheckedItem<T>>();
  const [derivedList, setDerivedList] = useState<CheckedItem<T>[]>(
    list.map<CheckedItem<T>>((element) => ({
      checked: checkedItems?.includes(getUniqueValue(element)) || false,
      value: getValue(element),
      id: getUniqueValue(element),
      ...element
    }))
  );

  useEffect(() => {
    setDerivedList((prevList) =>
      prevList.map((item) => ({
        ...item,
        checked: checkedItems?.includes(item.id) || false
      }))
    );
  }, [checkedItems]);

  function getUniqueValue(item: T): string {
    if (typeof item === "string") {
      return item;
    }
    if (mergingField) {
      return String(item[mergingField]);
    }
    return "";
  }

  function getValue(item: T): string {
    if (typeof item === "string") {
      return item;
    }
    if (searchField) {
      return String(item[searchField]);
    }
    return "";
  }

  const groupItems = (list: CheckedItem<T>[]) => {
    if (!groupBy) return { "": list };

    return list.reduce<Record<string, CheckedItem<T>[]>>((groups, item) => {
      const groupKey = String(item[groupBy]) || "Uncategorized";
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(item);
      return groups;
    }, {});
  };

  const handleSelect = (item: CheckedItem<T>) => {
    if (singleSelect) {
      setSelectedItem(item);
      setMenuIsOpen(false); // Close menu in single-select mode
      return;
    }

    const updatedList = derivedList.map((_item) =>
      _item.id === item.id ? { ..._item, checked: !_item.checked } : _item
    );
    setDerivedList(updatedList);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (!singleSelect && "getSelectedItems" in rest) {
      rest.getSelectedItems(derivedList.filter((item) => item.checked) as T[]);
    }
  }, [derivedList]);

  useEffect(() => {
    if (singleSelect && "getSelectedItem" in rest && selectedItem) {
      rest.getSelectedItem(selectedItem);
    }
  }, [selectedItem]);

  const filteredList = derivedList.filter((item) =>
    item.value.toLowerCase().includes(filter.toLowerCase())
  );

  const groupedItems = groupItems(filteredList); // Group the filtered list by the groupBy field

  return (
    <>
      <Box mb={2} display="flex" justifyContent="flex-start">
        <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)}>
          <Flex flexDirection="column">
            <MenuButton
              mr={4}
              as={Button}
              isDisabled={isDisabled}
              leftIcon={!singleSelect ? <AddIcon /> : undefined}
              variant="add"
              borderRadius="md"
              onClick={() => setMenuIsOpen((prev) => !prev)}
              rightIcon={withArrow ? <ChevronDownIcon /> : undefined}
            >
              {singleSelect && selectedItem
                ? selectedItem.value
                : label || "Select"}
            </MenuButton>
            <MenuList
              width="30vw"
              maxHeight="50vh"
              overflow="auto"
              display="flex"
              flexDirection="column"
              sx={{
                "&::-webkit-scrollbar": { width: "10px" },
                "&::-webkit-scrollbar-track": {
                  background: "primary.lightGray"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "accent.lapis",
                  borderRadius: "8px"
                }
              }}
            >
              {withFilter && (
                <Box px={2} mb={2}>
                  <Input
                    placeholder="Search..."
                    variant="filled"
                    bg={useColorModeValue(
                      "background.white",
                      "background.darkWhite"
                    )}
                    _focus={{
                      borderColor: useColorModeValue(
                        "primary.focusOutline",
                        "primary.red"
                      )
                    }}
                    onChange={handleChange}
                  />
                </Box>
              )}
              {Object.keys(groupedItems).map((groupKey) => (
                <Box key={groupKey}>
                  {groupKey && (
                    <Text fontWeight="bold" px={4} py={2} color="black">
                      {groupKey}
                    </Text>
                  )}
                  {groupedItems[groupKey].map((item, index) => (
                    <MenuItem
                      key={`${item.id}-${index}`}
                      closeOnSelect={singleSelect}
                      onClick={() => handleSelect(item)}
                      _hover={{ bg: "primary.focusOutline" }}
                    >
                      {rest.withCheck ? (
                        <Checkbox pointerEvents="none" isChecked={item.checked}>
                          {withToolTip ? (
                            <Tooltip label={item.value}>
                              {getShortString(item.value)}
                            </Tooltip>
                          ) : (
                            <Text color="black">{item.value}</Text>
                          )}
                        </Checkbox>
                      ) : (
                        <Text color="black">{item.value}</Text>
                      )}
                    </MenuItem>
                  ))}
                </Box>
              ))}
            </MenuList>
          </Flex>
        </Menu>
      </Box>
      {!singleSelect && rest.withView && (
        <Wrap>
          {derivedList
            .filter((item) => item.checked)
            .map((item) => (
              <WrapItem key={item.id}>
                <Tag size="lg" sx={tagStyles}>
                  <TagLabel>{item.value}</TagLabel>
                  <TagCloseButton onClick={() => handleSelect(item)} />
                </Tag>
              </WrapItem>
            ))}
        </Wrap>
      )}
    </>
  );
};
