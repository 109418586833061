import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomTemplate, CustomTemplateType } from "../../types/drafts.type";

export interface TemplatesState {
  customTemplates: CustomTemplate[];
  selectedCustomBriefIndex?: number | undefined;
}

const initialState: TemplatesState = {
  customTemplates: [],
  selectedCustomBriefIndex: undefined
};

export interface TemplatesReducer {
  templates: TemplatesState;
}

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplates: (
      state,
      action: PayloadAction<{
        templates: CustomTemplate[];
      }>
    ) => {
      const { templates } = action.payload;

      if (!Array.isArray(templates)) {
        throw new Error("Templates payload must be an array");
      }
      state.customTemplates = templates;
    },

    setSelectedCustomBriefIndex: (state, action: PayloadAction<number>) => {
      state.selectedCustomBriefIndex = action.payload;
    }
  }
});

export const { setTemplates, setSelectedCustomBriefIndex } =
  templatesSlice.actions;

export default templatesSlice.reducer;
