import {
  doc,
  DocumentData,
  DocumentReference,
  setDoc
} from "firebase/firestore";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { ExpertLetter } from "../redux/clients/clientsSlice";
import { DATABASE } from "../types/tables-data";

// TODO: Refactor to more generic
export const useUpdateIndivFormsData = (indivId: string) => {
  const [isLoadingUpdateFormsData, setIsLoadingUpdateFormsData] =
    useState(false);
  const [errorUpdateFormsData, setErrorUpdateFormsData] = useState();

  const params = useParams();

  const onSubmitAddUpdateLetter = async ({
    values,
    expertLetterId,
    _useLoadingFlag = true,
    docRef,
    docId
  }: {
    values: any;
    expertLetterId?: string;
    useLoadingFlag?: boolean;
    docRef?: DocumentReference<DocumentData> | null;
    docId?: string | null;
    _useLoadingFlag?: boolean;
  }) => {
    console.log("[upload_testing] Function called with arguments:", {
      values,
      expertLetterId,
      _useLoadingFlag,
      docRef,
      docId
    });

    if (_useLoadingFlag) {
      console.log("[upload_testing] Setting isLoadingUpdateFormsData to true");
      setIsLoadingUpdateFormsData(true);
    }

    try {
      let ref;
      if (indivId && (docRef || expertLetterId)) {
        console.log(
          "[upload_testing] Creating reference for Firestore document"
        );
        ref =
          docRef ||
          doc(
            db,
            DATABASE.FORMS,
            `${indivId}`,
            "expert_letters",
            `${expertLetterId}`
          );
        console.log("[upload_testing] Firestore document reference:", ref.path);
      }

      if (ref) {
        console.log("[upload_testing] Filtering values:", values);
        const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([_, v]) => v != null)
        );
        console.log("[upload_testing] Filtered values:", filteredValues);

        await setDoc(
          ref,
          { ...filteredValues, uid: expertLetterId ?? docId, isDeleted: false },
          { merge: true }
        );
        console.log("[upload_testing] Document successfully updated");
      }
    } catch (e: any) {
      const errorCode = e.code;
      console.error("[upload_testing] Error occurred:", errorCode);
      setErrorUpdateFormsData(errorCode);
    } finally {
      if (_useLoadingFlag) {
        console.log(
          "[upload_testing] Setting isLoadingUpdateFormsData to false"
        );
        setIsLoadingUpdateFormsData(false);
      }
    }
  };

  return {
    isLoadingUpdateFormsData,
    onSubmitAddUpdateLetter,
    errorUpdateFormsData
  };
};
