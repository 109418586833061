import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  SkeletonText,
  Box,
  SkeletonCircle
} from "@chakra-ui/react";

// Table Skeleton
export const TableSkeleton: React.FC<{ rows?: number; columns?: number }> = ({
  rows = 5,
  columns = 4
}) => (
  <Table variant="simple">
    <Thead>
      <Tr>
        {Array.from({ length: columns }).map((_, index) => (
          <Th key={`header-${index}`}>
            <Skeleton height="20px" />
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Tr key={`row-${rowIndex}`}>
          {Array.from({ length: columns }).map((_, colIndex) => (
            <Td key={`row-${rowIndex}-col-${colIndex}`} height={4}>
              <Skeleton noOfLines={1} height="16px"/>
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  </Table>
);
