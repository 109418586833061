import { Button } from "@chakra-ui/react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const VisaList = () => {
  const [visaTypes, setVisaTypes] = useState<string[]>([]);
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVisaTypes = async () => {
      const visaQuestionsRef = collection(db, "visa_questions");
      const querySnapshot = await getDocs(visaQuestionsRef);
      const visas = querySnapshot.docs.map((doc) => doc.id);
      setVisaTypes(visas);
    };

    fetchVisaTypes();
  }, [db]);

  return (
    <div className="flex gap-2 justify-center items-center h-full">
      {visaTypes.map((visa) => (
        <div key={visa}>
          <h3>{visa}</h3>
          <Button
            onClick={() => {
              navigate(`/admin/visa-questionnaire-builder/${visa}`);
            }}
          >
            Edit
          </Button>
        </div>
      ))}
      {/* <button onClick={createNewVisa}>Create New Visa Type</button> */}
    </div>
  );
};
