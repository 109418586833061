import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VisaDocumentType } from "../redux/documents/types";
import { RootState } from "../redux/store";
import { SelectedItem } from "../types/dropdown.types";
import { DataDocs, visaFromPath } from "../types/tables-data";

export const useDocumentCategoriesAndTypes = (
  document?: DataDocs,
  visaType?: string
) => {
  // Access documentTypesMap from Redux
  const documentTypesMap = useSelector(
    (state: RootState) => state.document.documentTypes
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  if (!documentTypesMap || !document)
    return { categories, types, reduceCategoriesToSelectedItems: () => [] };
  // eslint-disable-next-line prettier/prettier, @typescript-eslint/naming-convention
  const { documentType, super_class } = document;

  const getDocumentCategories = () => {
    // Older documents might not have a `super_class` field
    // Check by super_class
    // Very very important : Double check with John to re-run the classification
    // on all documents when merging to staging/Qa/prod

    if (super_class && visaType) {
      return Object.keys(documentTypesMap[super_class]?.[visaType]);
    }
    return [];
  };

  const getDocumentTypesByCategory = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { criterion, super_class } = document;

    if (!criterion || !super_class || !visaType) return [];

    if (super_class in documentTypesMap &&
        visaType in documentTypesMap[super_class] && 
        criterion in documentTypesMap[super_class][visaType]
    ){
      return Object.values(
        documentTypesMap[super_class][visaType][criterion]
      ) as string[];
    }


    return [];
  };

  const reduceCategoriesToSelectedItems = (
    data: any,
    visaType: string
  ): SelectedItem[] => {
    if (!data) return [];
    const visaTypeForMap = visaFromPath[visaType];

    const result: SelectedItem[] = [];
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (data[key] && data[key][visaTypeForMap]) {
        Object.keys(data[key][visaTypeForMap]).forEach((category) => {
          result.push({
            super_class: key,
            category
          });
        });
      }
    });

    return result;
  };
  useEffect(() => {
    const categories = getDocumentCategories();
    setCategories(categories);
  }, [document]);

  useEffect(() => {
    if (document.criterion) {
      const types = getDocumentTypesByCategory();
      setTypes(types);
    }
  }, [document]);

  return {
    categories,
    types,
    reduceCategoriesToSelectedItems
  };
};
