import { Box, ChakraProps, Container, Flex } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import CustomBackButton from "./CustomBackButton";
import { IndividualEditBox } from "./IndividualEditBox";

type Props = {
  withBackNav?: boolean;
  withIndividualEditBox?: boolean;
  backTargetPage?: string;
  bg?: string;
  onClick?:any;
} & PropsWithChildren &
  ChakraProps;

export const SectionWrapper = ({
  withBackNav = true,
  withIndividualEditBox = true,
  children,
  backTargetPage = "Dashboard",
  onClick = undefined,
  bg = "primary.backgroundLight",
  ...rest
}: Props) => {
  const backAriaLabel = `Back to ${backTargetPage}`;
  return (
    <>
      <Flex justifyContent="start">
        {withBackNav && <CustomBackButton ariaLabel={backAriaLabel} onClick={onClick}/>}
      </Flex>
      <Container py={4} my={4} bg={bg} maxW="98%">
        {withIndividualEditBox && <IndividualEditBox />}

        <Container maxW="100%" {...rest}>
          {children}
        </Container>
      </Container>
    </>
  );
};
