import {
  Avatar,
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  Image,
  useDisclosure
} from "@chakra-ui/react";
import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { supportedExtensionsString } from "../constans";
import { useAddFileToDB } from "../hooks/useAddFileToDB";
import { useGetFilesFromDB } from "../hooks/useGetFilesFromDB";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { ClientData, DATABASE } from "../types/tables-data";
import { DataTable } from "./DataTable";
import { SpecialHeading } from "./individualTabs/PanelLayout";
import { LawyerState } from "../redux/lawyer/lawyerSlice";
import { getFileExtension } from "../helpers/file_helpers";

export const LawFirmSettings = () => {
  const { id } = useParams();
  const lawyer = useSelector(lawyerSelectors.selectAll);
  const { onSubmitAddFile, fileRef, isLoadingAddFile } = useAddFileToDB();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [data, setData] = useState<ClientData[] | null>(null);
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();
  const customersRef = collection(db, "/customers");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [avatar, setAvatar] = useState("");
  const [firm, setFirm] = useState<ClientData | null>(null);
  const getImagesFromBD = async () => {
    try {
      await onSubmitGetFiles(`${DATABASE.FIRMS}/avatars/${firm?.practiceName}`);
    } catch (e) {
      //   console.log(e);
      //   dispatch(updateLawyerAvatar({ avatarSrc: "/", avatarName: "" }));
    }
  };
  const uploadImage = async () => {
    if (selectedFile) {
      const extension = getFileExtension(selectedFile);
      try {
        await onSubmitAddFile(
          selectedFile,
          `${DATABASE.FIRMS}/avatars/${firm?.practiceName}/logo${extension}`,
          true
        );
        setSelectedFile(null);
        getImagesFromBD();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    const fetchDataAndImages = async () => {
      // Fetch customers
      const data = await getDocs(customersRef);
      if (data.docs) {
        const docs: SetStateAction<ClientData[]> = [];
        data.forEach((doc) => {
          docs.push(doc.data() as ClientData);
        });
        if (docs) setData(docs);
      }

      // Upload image if selected
      if (selectedFile) {
        await uploadImage();
      }

      // Fetch images from DB when the modal opens
      if (isOpen) {
        await getImagesFromBD();
      }

      // Set avatar when storage files change
      if (storageFiles.length !== 0) {
        const file = storageFiles[0];
        const url = await getDownloadURL(file);
        setAvatar(url);
      }
    };

    fetchDataAndImages();
  }, [selectedFile, isOpen, storageFiles]);
  return (
    <div className="">
      <SpecialHeading title="Law Firm Settings" mt={4} />
      {/* <div className="relative rounded shadow-sm mb-4 ">
        <Image className="w-full h-28 object-fil " src={images.joinPageWawes} />
      </div> */}
      <Divider my={4} />
      {data && (
        <DataTable
          data={data}
          columns={[
            { accessorKey: "email" },
            { accessorKey: "practiceName" },
            {
              accessorKey: "created_at",
              accessorFn: (row) => new Date(row.created_at!)
            },
            {
              accessorKey: "actions",
              cell: (value) => (
                <p
                  onClick={() => {
                    setFirm(value.row.original);
                    onOpen();
                  }}
                >
                  Edit
                </p>
              )
            }
          ]}
          sortBy="created_at"
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>LawFirm Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <DataTable/> */}
            <div className="flex  items-center gap-8 ">
              <Text fontWeight={500}>Law Firm Logo:</Text>
              <div className="flex flex-col items-center justify-center gap-8 relative">
                <Image
                  src={avatar}
                  cursor="pointer"
                  onClick={() => {
                    fileRef.current?.click();
                  }}
                  className="relative max-h-[6rem] !max-w-[12rem] bg-cover"
                />

                {isLoadingAddFile && (
                  <Skeleton className="w-[6rem] h-[6rem] !rounded-full animate-pulse opacity-25 absolute " />
                )}

                {/* <Text color="primary.darkBlue" className="font-semibold text-xl">
            {lawyer.practiceName}
          </Text>
          <Text className="text-xs">Account type: Lawyer</Text> */}
              </div>

              <Input
                type="file"
                ref={fileRef}
                onChange={(e) => {
                  if (e.target.files) {
                    setSelectedFile(e.target.files[0]);
                  }
                }}
                src=""
                accept={supportedExtensionsString}
                opacity="0"
                h="0"
                w="0"
                lineHeight="0"
                overflow="hidden"
                p="0"
                m="0"
              />
            </div>
            <Divider my={4} />
            <Text fontWeight={500}>Law Firm Name : {firm?.practiceName}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="secondaryOutline" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
