import { Divider, Flex, Spinner } from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { TargetBox } from "./inputDnD/TargetBox";

import useDocuments from "../hooks/useDocuments";
import useFileActions from "../hooks/useFileActions";
import { documentSelectors } from "../redux/documents/selectors";
import { VisaDocumentType } from "../redux/documents/types";
import { DATABASE } from "../types/tables-data";
import { UploadFilesPlaceholder } from "./UploadFilesPlaceholder";
import { DocumentsTable } from "./common/DocumentsTable";

export const DocumentsSection = () => {
  const { id: uid } = useParams();
  const evidenceDocsCollectionPath = `documents/${uid}/evidence_docs`;
  const superDocuments = useSelector(documentSelectors.groupedDocuments);

  const { superGroupedDocuments, isDocumentsEmpty, isLoadingDocuments } =
    useDocuments([
      {
        path: `/documents/${uid}/docs`,
        documentType: VisaDocumentType.Standard,
        super_class: "Standard"
      },
      {
        path: `/documents/${uid}/signed_expert_letters`,
        documentType: VisaDocumentType.SignedExpertLetters,
        super_class: "Letters"
      },
      {
        path: `/documents/${uid}/evidence_docs`,
        documentType: VisaDocumentType.Evidence,
        super_class: "Evidence"
      }
    ]);

  const areDocumentsEmpty = isDocumentsEmpty(superDocuments);

  const evidenceDocumentsStoragePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Evidences`;

  const { handleFileActions } = useFileActions();

  return (
    <Flex flexDirection="column" justifyContent="start" w="100%">
      {isLoadingDocuments && (
        <div className="flex items-center justify-center">
          <Spinner
            alignSelf="center"
            alignContent="center"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary.blue"
            size="xl"
          />
        </div>
      )}
      {!isLoadingDocuments && (
        <DndProvider backend={HTML5Backend} key={6} context={window}>
          <TargetBox
            onDrop={(item) => {
              const { files } = item;
              handleFileActions({
                files: Array.from(files),
                path: evidenceDocumentsStoragePath,
                uid: uid!,
                visaDocumentType: VisaDocumentType.Evidence
              });
            }}
          >
            <Flex flexDirection="column" textAlign="start" w="100%">
              <UploadFilesPlaceholder
                allowUploadByCategory
                tableRef="evidence"
                styleIndex={areDocumentsEmpty ? 0 : 2}
                title="Upload Files"
                subTitle="OR"
                withImg
                onUpload={(files) => {
                  if (files)
                    handleFileActions({
                      files: Array.from(files),
                      path: evidenceDocumentsStoragePath,
                      uid: uid!,
                      visaDocumentType: VisaDocumentType.Evidence
                    });
                }}
                storagePath={evidenceDocumentsStoragePath}
                collectionPath={evidenceDocsCollectionPath}
                visaDocumentType={VisaDocumentType.Evidence}
              />
            </Flex>
          </TargetBox>
          {/* Evidence Documents Table */}
          <Divider my={2} mb={4} opacity={0} />

          {superGroupedDocuments && !areDocumentsEmpty && <DocumentsTable />}
        </DndProvider>
      )}
    </Flex>
  );
};
