export interface ExtractedData {
  uploadedBy: string;
  uploadedDate: number;
  data: {
    passport: null | Record<string, string | number>;
    visa: null | Record<string, string>;
    resume: null | Record<string, string>;
    i94: null | Record<string, string>;
  };
  expertLetter?: string;
  summaryPoints?: string;
  summaryPointsHtml?: string;
  prongArguments?: string;
}

export enum VisaDocumentType {
  Standard = "standard",
  Evidence = "evidence",
  SignedExpertLetters = "letters"
}
