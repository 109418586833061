import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  VStack,
  Checkbox,
  Divider
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientContactSchema } from "./Client.schema";
import { AddressFields } from "../common/AddressFields";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import { DATABASE } from "../../types/tables-data";
import {
  ClientContactInfo,
  ClientsReducer
} from "../../redux/clients/clientsSlice";
import { clientsSelectors } from "../../redux/clients/selectors";
import { ClientContactForm } from "./ClientContactFields";

export const ContactInfo = () => {
  const params = useParams();
  const client = useSelector((state: ClientsReducer) =>
    clientsSelectors.selectClientById(state, params.caseID ?? "")
  );

  const navigate = useNavigate();
  const { updateDocument, loading } = useFirestoreDocument<ClientContactInfo>(
    DATABASE.INDIVIDUALS,
    params.caseID ?? ""
  );

  return (
    <Box className="max-w-3xl">
      <Formik
        initialValues={client}
        validationSchema={clientContactSchema}
        onSubmit={(values) => {
          const obj = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => value !== undefined)
          );
          updateDocument(obj);
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ClientContactForm />
              <div className="flex gap-4 my-8 justify-end">
                <Button
                  variant="secondaryOutline"
                  onClick={() => navigate(-1)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  variant="primaryFilled"
                  isLoading={loading}
                  type="submit"
                >
                  Update Contact Info
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
