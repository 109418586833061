import { PhoneIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Skeleton,
  SkeletonText,
  Text
} from "@chakra-ui/react";

export const ExpertInfoSkeleton = () => {
  return (
    <Box py={5}>
      <Flex
        flexDirection="column"
        justifyContent="start"
        alignContent="start"
        rowGap={8}
      >
        {/* Full name field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Organization field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Current role field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Email field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Phone input field skeleton */}
        <HStack width="20%">
          <Skeleton height="40px" borderRadius={4} width="60px">
            <IconButton icon={<PhoneIcon />} aria-label="Country code" />
          </Skeleton>
          <Skeleton height="40px" width="20%" borderRadius={4} flex="1" />
        </HStack>
      </Flex>
    </Box>
  );
};
