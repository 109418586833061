import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CollectionReference,
  DocumentData,
  addDoc,
  collection
} from "firebase/firestore";
import { ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../api/firebaseApi";
import { filterDocumentsBySupport, createUniqueFileForUpload } from "../../helpers/file_helpers";
import { DataDocs } from "../../types/tables-data";
import { addSubDocument } from "../documents/documentSlice";
import { generateDocumentUrls } from "../documents/documentsThunk";
import { VisaDocumentType } from "../documents/types";
import { RootState } from "../store";
import { setIsLoading, setUploadProgress } from "./fileUploadSlice";

export type UploadFilesProps = {
  path: string;
  uid: string;
  mainDocumentId?: string;
  type?: VisaDocumentType;
};

const getUserDocRef = (
  uid: string,
  document?: DataDocs
): CollectionReference<DocumentData> | undefined => {
  if (document?.docRef) {
    return collection(db, `${document.docRef}/docs`);
  }
  return collection(db, `documents/${uid}/evidence_docs`);
};

type CreateDocumentData = {
  fileRef: any;
  isSub?: boolean;
  category?: string;
  type?: string;
  criterion?: string;
  super_class?: string;
};

const createDocumentData = (
  data: CreateDocumentData,
  isSub: boolean
): DataDocs => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { fileRef, category, type, super_class } = data;
  // console.log("new_upload_testing",

  return {
    filePath: data.fileRef.fullPath,
    docNames: fileRef.name,
    documentTitle: isSub ? fileRef.name : "",
    uploadBy: "",
    description: "",
    super_class,
    uploadDate: Date.now(),
    isDeleted: false,
    ...(category ? { criterion: category } : {}),
    ...(type ? { type } : {})
  };

  // return {
  //   filePath: data.fileRef.fullPath,
  //   docNames: fileRef.name,
  //   documentTitle: isSub ? fileRef.name : "",
  //   uploadBy: "",
  //   description: "",
  //   uploadDate: Date.now(),
  //   isDeleted: false,
  //   ...(category ? { criterion: category } : {}),
  //   ...(type ? { type } : {})
  // };
};

export const uploadFiles = createAsyncThunk(
  "fileUpload/uploadFiles",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const { files, uploadConfiguration } = state.fileUpload;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { path, uid, mainDocument, super_class, category, type } =
      uploadConfiguration;

    const filteredFiles = filterDocumentsBySupport(files, true);

    try {
      const currentProgress = new Map<string, number>();
      const uploadPromises = filteredFiles.map(async (file) => {
        const { file: uniqueFile, path: uniquePath } = await createUniqueFileForUpload(path, file);
        return new Promise((resolve, reject) => {
          const fileRef = ref(storage, uniquePath);
          const uploadTask = uploadBytesResumable(fileRef, uniqueFile);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              currentProgress.set(file.name, progress);
              const progressObject = Object.fromEntries(currentProgress);
              dispatch(setUploadProgress(progressObject));
            },
            (error) => {
              console.error("upload_testing: Upload error", error);
              reject(error);
            },
            async () => {
              const userDocRef = getUserDocRef(uid, mainDocument);

              const documentData: CreateDocumentData = {
                fileRef,
                ...(category !== undefined && { category }),
                ...(type !== undefined && { type }),
                criterion: category ?? "",
                super_class: super_class || ""
              };

              // Call createDocumentData with the documentData object
              const document = createDocumentData(
                documentData,
                mainDocument !== undefined
              );

              if (userDocRef) {
                const addedDocument = await addDoc(userDocRef, document);
                const documentWithId: DataDocs = {
                  ...document,
                  super_class,
                  docRef: addedDocument.path,
                  id: addedDocument.id,
                  isDeleted: false
                };

                if (mainDocument !== undefined) {
                  dispatch(
                    addSubDocument({
                      data: { id: mainDocument.id!, document: documentWithId }
                    })
                  );
                }

                // Resolves the Promise with an object containing:
                // 1. status: true, indicating a successful upload.
                // 2. document: The newly added document with its Firestore ID (documentWithId).
                // 3. mainDocumentId: The main document ID or an empty string if not a sub-document.
                // This resolved value is then used in the next promise chain to generate document URLs.
                resolve({
                  status: true,
                  document: documentWithId,
                  mainDocumentId: mainDocument?.id ?? ""
                });
              }
            }
          );
        });
      });

      await Promise.all(uploadPromises).then((uploadedDocuments) => {
        // After all file upload promises are resolved, this block runs.

        uploadedDocuments.forEach(({ document, mainDocumentId, type }: any) => {
          // Dispatch an action to generate URLs for each uploaded document.
          // The documents array contains the file path and ID of the uploaded document
          // and the main document ID is passed if applicable.

          dispatch(
            generateDocumentUrls({
              documents: [
                {
                  filePath: document.filePath,
                  id: document.id,
                  criterion: document.criterion,
                  super_class: document.super_class
                }
              ],
              mainDocId: mainDocumentId
            })
          );
        });
        dispatch(setIsLoading(false));
      });

      return {};
    } catch (error: any) {
      console.error("upload_testing: Upload failed", error);
      return rejectWithValue(error.message);
    }
  }
);
