import { LegalBriefSection } from "../redux/drafts/draftsSlice";
import { DataDocs, VISAVALUE } from "./tables-data";

export type DraftDocument = {
  sop: { text: string };
  exhibit_list: { text: string; html: string; isProcessing?: boolean };
  coverLetter: { text: string };
  created_at: Date;
};

export interface GenerateDraftParams {
  individualId: string;
  draftType: string;
  expertId?: string | null;
  tone?: string | null;
  length?: string | null;
  creativity?: string | null;
  greeting?: string | null;
  useAsync?: boolean;
  referenceResume?: boolean;
  isSupportLetter?: boolean;
  templatePath?: string | null;
  fromPreviousCase?: boolean | null;
}

export interface GeneratePacketParams {
  individualId: string;
  useAsync?: boolean;
}

export enum LegalBriefType {
  LEGAL_BRIEF = "Legal brief",
  SUPPORT_LETTER = "Support letter"
}

export const VisaLegalBriefConfig: Record<VISAVALUE, LegalBriefType[]> = {
  [VISAVALUE.EMPTY]: [],
  [VISAVALUE.EB1A]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.EB1B]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.EB1C]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.EB2NIW]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.O1A]: [LegalBriefType.LEGAL_BRIEF, LegalBriefType.SUPPORT_LETTER],
  [VISAVALUE.O1B]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.L1A]: [LegalBriefType.LEGAL_BRIEF]
};

export enum LegalBriefLength {
  CONSCISE = "Conscise",
  LENGTHY = "Lengthy"
}

export type TemplateParams = {
  briefType: string | null;
  briefLength: string | null;
  existingTemplate: string | null;
  templateName: string | null;
  retainAreaOfExpertise: boolean | null;
  retainEmployerDetails: boolean | null;
  fileUploaded: boolean | null;
};

export type CustomTemplate = DataDocs & {
  templateName: string;
  templateInfoToRetain: string[];
  created_at: number;
  created_by: string;
  last_updated_by?: string | null;
  visa: VISAVALUE;
  type: CustomTemplateType;
  fromPreviousCase?: boolean;
  sections?: LegalBriefSection[];
  addedDuringGeneration?: boolean;
};

export type CustomTemplateType =
  | "legal_brief_templates"
  // | "expert_letters_templates"
  | "support_letters_templates";

export const templateTypeMap: Record<CustomTemplateType, LegalBriefType> = {
  legal_brief_templates: LegalBriefType.LEGAL_BRIEF,
  // expert_letters_templates: "Expert Letters",
  support_letters_templates: LegalBriefType.SUPPORT_LETTER
};

export type TemplateOptionKey =
  | "cb_template"
  | "existing_template"
  | "new_template"
  | "from_previous_case";

export interface TemplateOption {
  key?: string;
  title: string;
  badgeText?: string;
}

export const templateOptions: TemplateOption[] = [
  { key: "cb_template", title: "Use CaseBlink's template" },
  {
    key: "existing_template",
    title: "Choose from existing templates",
    badgeText: "BETA"
  }
  // { key: "new_template", title: "Upload new template", badgeText: "BETA" },
  // {
  //   key: "from_previous_case",
  //   title: "Upload a previous case sample",
  //   badgeText: "BETA"
  // }
];
