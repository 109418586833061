import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getExhibit, hasId, truncateText } from "../../helpers/helpers";
import { getShortString } from "../../helpers/string_helpers";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import { documentSelectors } from "../../redux/documents/selectors";
import { DATABASE } from "../../types/tables-data";
import MagicIcon from "./MagicIcon";

type CheckedItem<T> = {
  checked: boolean;
  value: string;
  id: string;
} & T;

type Props<T> = {
  list: T[];
  checkedItems: string[] | null | undefined;
  setCheckedItems: (items: string[]) => void; // Pass setter function from parent
  searchField?: keyof T;
  mergingField?: keyof T;
  withToolTip?: boolean;
  groupBy?: keyof T;
  primaryButtonText?: string;
  onConfirm: () => void;
  onClose?: () => void;
  setPromptisSelected: (value: boolean) => void;
};

export const ToolbarDropdown = <T,>({
  list,
  checkedItems,
  setCheckedItems,
  searchField,
  mergingField,
  withToolTip = false,
  groupBy,
  primaryButtonText = "Generate",
  onConfirm,
  onClose,
  setPromptisSelected
}: Props<T>) => {
  const [filter, setFilter] = useState("");
  const { id } = useParams();
  const { document } = useFirestoreDocument(DATABASE.DOCUMENTS, id ?? "", true);

  const getValue = (item: T): string => {
    let result = "";

    let exhibit = "";
    if (hasId(item) && document && "documentsTableOrder" in document) {
      exhibit =
        getExhibit(document?.documentsTableOrder as any[], item.id) ?? "";
    }

    const value = searchField ? String(item[searchField]) : "";

    // Combine exhibit and searchField value if exhibit exists
    if (exhibit) {
      result = `Exhibit ${exhibit} - ${value}`;
    }

    // Append searchField value if it exists
    if (
      searchField &&
      item &&
      typeof item === "object" &&
      searchField in item
    ) {
      result += String(item[searchField]);
    }

    return result.trim();
  };

  const getUniqueValue = (item: T): string => {
    if (typeof item === "string") return item;
    if (mergingField) return String(item[mergingField]);
    return "";
  };

  const groupItems = (list: CheckedItem<T>[]) => {
    if (!groupBy) return { "": list };

    return list.reduce<Record<string, CheckedItem<T>[]>>((groups, item) => {
      const groupKey = String(item[groupBy]) || "Uncategorized";
      if (!groups[groupKey]) groups[groupKey] = [];
      groups[groupKey].push(item);
      return groups;
    }, {});
  };

  const derivedList = list.map((item) => ({
    checked: checkedItems?.includes(getUniqueValue(item)) || false,
    value: getValue(item),
    id: getUniqueValue(item),
    ...item
  }));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleSelect = (item: CheckedItem<T>) => {
    const newCheckedItems = derivedList
      .map((_item) =>
        mergingField &&
        _item[mergingField as keyof T] === item[mergingField as keyof T]
          ? { ..._item, checked: !_item.checked }
          : _item
      )
      .filter((i) => i.checked)
      .map((i) => (mergingField ? String(i[mergingField as keyof T]) : i.id));

    setCheckedItems(newCheckedItems); // Update state in parent
  };

  const filteredList = derivedList.filter((item) =>
    item.value.toLowerCase().includes(filter.toLowerCase())
  );

  const groupedItems = groupItems(filteredList);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <Portal>
      <MenuList
        zIndex="9999"
        overflow="visible"
        ref={menuRef}
        p={0}
        m={0}
        borderWidth={0}
        bg={useColorModeValue("background.white", "background.darkWhite")}
        width="30vw"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        {/* Search Bar */}
        <Box
          p={3}
          borderBottom="1px solid"
          borderColor="stroke.stroke2"
          flexShrink={0}
        >
          <Input
            onFocus={() => {
              setPromptisSelected(true); // Notify parent to disable selection handling
            }}
            onBlur={() => {
              setPromptisSelected(false); // Re-enable selection handling
            }}
            placeholder="Search..."
            variant="filled"
            bg={useColorModeValue("background.solitude", "background.misty")}
            fontSize="sm"
            _focus={{
              borderColor: useColorModeValue(
                "primary.focusOutline",
                "primary.red"
              ),
              bg: useColorModeValue("background.white", "background.darkWhite")
            }}
            onChange={handleChange}
          />
        </Box>

        {/* Scrollable Items */}
        <Flex flexDirection="column" flex="1" overflowY="auto" p={2}>
          {Object.keys(groupedItems).map((groupKey) => (
            <Box key={groupKey} mb={2}>
              {groupKey && (
                <Text
                  fontWeight="semibold"
                  fontSize="sm"
                  px={4}
                  py={2}
                  color="text.color"
                >
                  {groupKey}
                </Text>
              )}
              {groupedItems[groupKey].map((item, index) => (
                <MenuItem
                  key={`${item.id}-${index}`}
                  closeOnSelect={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(item);
                  }}
                  _hover={{ bg: "primary.focusOutline" }}
                  py={1}
                >
                  <Checkbox isChecked={item.checked} pointerEvents="none">
                    {withToolTip ? (
                      <Tooltip label={item.value}>
                        {getShortString(item.value)}
                      </Tooltip>
                    ) : (
                      <Text fontSize="sm">{truncateText(item.value, 50)}</Text>
                    )}
                  </Checkbox>
                </MenuItem>
              ))}
            </Box>
          ))}
        </Flex>

        {/* Sticky Footer */}
        <Flex
          p={4}
          columnGap={2}
          justifyContent="end"
          borderTop="1px solid"
          borderColor="stroke.stroke2"
          justify="space-between"
          flexShrink={0}
        >
          <Button
            variant="secondaryOutline"
            fontSize="sm"
            size="sm"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            isDisabled={checkedItems?.length === 0}
            variant="secondaryOutline"
            size="sm"
            fontSize="sm"
            onClick={onConfirm}
          >
            <Box mt={1}>
              <MagicIcon color="primary.blue" />
            </Box>
            {primaryButtonText}
          </Button>
        </Flex>
      </MenuList>
    </Portal>
  );
};
