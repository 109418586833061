import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import useVisaQuestions, { Question } from "../../../hooks/useVisaQuestions";
import { individualSelectors } from "../../../redux/individual/selectors";
import { DATABASE, VISAVALUE } from "../../../types/tables-data";
import DatePickerWithCheckbox from "../../common/DatePickerWithCheckbox";
import { SaveAnswersDialog } from "./evidenceQuestions/SaveAnswersDialog";

interface FormData {
  [key: string]: any;
}

interface Props {
  visaType: string;
  isAutoGenerating?: boolean;
  aiExtractionMsg?: JSX.Element;
}

export const VisaQuestionsForm: React.FC<Props> = ({
  visaType,
  isAutoGenerating = false,
  aiExtractionMsg
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const { questions, loading, error, save } = useVisaQuestions(visaType, uid);
  const [formData, setFormData] = useState<FormData>({});
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [petitionerType, setPetitionerType] = useState<string>("");
  const [peerGroup, setPeerGroup] = useState<string>("");
  const [isSamePositionAsAbroad, setIsSamePositionAsAbroad] =
    useState<string>("");
  const [errors, setErrors] = useState<FormData>({});
  const [questionnaireData, setQuestionnaireData] = useState<FormData>({});

  const refs = useRef<{
    [key: string]:
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement
      | null;
  }>({});

  const handleInputChange = (variableName: string, value: any) => {
    setFormData({ ...formData, [variableName]: value });

    if (variableName === "petitionerType") {
      setPetitionerType(value);
    }
    if (variableName === "peerGroup") {
      setPeerGroup(value);
    }
    if (variableName === "is_offered_position_same_as_held_abroad") {
      setIsSamePositionAsAbroad(value);
    }
  };

  useEffect(() => {
    if (!uid) return () => {}; // Early return if uid is not provided

    const docRef = doc(db, DATABASE.QUESTIONNAIRES, `${uid}`);
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();

          setQuestionnaireData(data); // Dispatch action to set the fetched data
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching the document: ", error);
      }
    );

    // // Cleanup function to unsubscribe on unmount
    return () => unsubscribe();
  }, [uid]);

  useEffect(() => {
    if (Object.keys(questionnaireData).length > 0) {
      setFormData(questionnaireData);
      // Initialize petitionerType and peerGroup from fetched data if present
      setPetitionerType(questionnaireData.petitionerType || "");
      setPeerGroup(questionnaireData.peerGroup || "");
      setIsSamePositionAsAbroad(
        questionnaireData.is_offered_position_same_as_held_abroad || ""
      );
    } else if (questions.length > 0) {
      const defaultFormData = questions.reduce<FormData>((acc, question) => {
        acc[question.variableName] =
          question.fieldType === "checkbox" ? [] : "";
        return acc;
      }, {});
      setFormData(defaultFormData);
    }
  }, [questionnaireData, questions]);

  const isFieldVisible = (question: Question) => {
    if (
      (visaType === VISAVALUE.EB1A &&
        question.variableName === "employerName" &&
        petitionerType !== "Employer") ||
      (question.variableName === "peerGroupName" &&
        peerGroup.toLocaleLowerCase() === "no")
    ) {
      return false;
    }
    if (
      visaType === VISAVALUE.O1B &&
      question.variableName === "laborOrganizationName" &&
      peerGroup.toLocaleLowerCase() !== "yes"
    ) {
      return false;
    }
    if (
      visaType === VISAVALUE.EB1C &&
      question.variableName ===
        "questionnaire_description_beneficiary_offered_position_us" &&
      isSamePositionAsAbroad.toLocaleLowerCase() !== "no"
    ) {
      return false;
    }
    if (
      visaType === VISAVALUE.L1A &&
      question.variableName ===
        "questionnaire_description_beneficiary_offered_position_us" &&
      isSamePositionAsAbroad.toLocaleLowerCase() !== "no"
    ) {
      return false;
    }
    return true;
  };

  const scrollToElement = (key: never) => {
    const element = refs.current[key];
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.scrollBy(0, -140);
      }, 300);
    }
  };

  const renderQuestionInput = (question: Question) => {
    const {
      fieldType,
      variableName,
      subVariableName,
      questionText,
      options,
      subOptions,
      isRequired,
      tooltipText
    } = question;

    if (!isFieldVisible(question)) {
      return null;
    }

    const setRef = (
      el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null
    ) => {
      refs.current[variableName] = el;
    };

    return (
      <Box key={variableName} mb="4">
        <FormControl isRequired={isRequired}>
          <FormLabel mb={2} fontWeight={700}>
            {questionText}
            {tooltipText && (
              <Tooltip label={tooltipText} aria-label="Question tooltip">
                <Icon as={InfoOutlineIcon} ml={2} cursor="pointer" />
              </Tooltip>
            )}
          </FormLabel>
        </FormControl>
        {(() => {
          switch (fieldType) {
            case "text":
              return !isAutoGenerating ? (
                <Input
                  placeholder=""
                  value={formData[variableName] || ""}
                  onChange={(e) =>
                    handleInputChange(variableName, e.target.value)
                  }
                  mb={4}
                  isRequired={isRequired}
                  isInvalid={!!errors[variableName]}
                  ref={setRef}
                />
              ) : (
                <Skeleton height="40px" mb={4} />
              );
            case "textarea":
              return !isAutoGenerating ? (
                <Textarea
                  placeholder=""
                  value={formData[variableName] || ""}
                  onChange={(e) =>
                    handleInputChange(variableName, e.target.value)
                  }
                  mb={10}
                  isRequired={isRequired}
                  isInvalid={!!errors[variableName]}
                  ref={setRef}
                />
              ) : (
                <SkeletonText />
              );

            case "radio":
              return !isAutoGenerating ? (
                <RadioGroup
                  value={formData[variableName] || ""}
                  onChange={(value) => {
                    handleInputChange(variableName, value);
                    // Select the first sub-option if it exists
                    if (subVariableName && subOptions?.[value]) {
                      const firstSubOption = subOptions[value].split(", ")[0]; // Get the first sub-option
                      setFormData((prev) => ({
                        ...prev,
                        [subVariableName]: firstSubOption || "" // Set the first sub-option or clear
                      }));
                    }
                  }}
                  mb={10}
                  ref={setRef as unknown as React.RefObject<HTMLDivElement>}
                >
                  <Stack direction="column">
                    {options?.map((option, index) => {
                      const value =
                        typeof option === "string" ? option : option.value;
                      const optionText =
                        typeof option === "string" ? option : option.optionText;

                      return (
                        <Box key={index} display="flex" flexDirection="column">
                          {/* Parent Radio Button */}
                          <Radio value={value}>{optionText}</Radio>

                          {/* Show Sub-options Only if Parent is Selected */}
                          {value &&
                            formData[variableName] === value &&
                            subOptions?.[value] && (
                              <Box pl="6" mt="2">
                                <Text fontWeight="600" mb="2">
                                  Additional Details:
                                </Text>
                                <RadioGroup
                                  value={
                                    subVariableName
                                      ? formData[subVariableName]
                                      : ""
                                  }
                                  onChange={(subValue) => {
                                    if (subVariableName)
                                      setFormData((prev) => ({
                                        ...prev,
                                        [subVariableName]: subValue // Update selected sub-option
                                      }));
                                  }}
                                >
                                  <Stack direction="column">
                                    {subOptions[value]
                                      .split(", ")
                                      .map(
                                        (
                                          subOption: string,
                                          subIndex: number
                                        ) => (
                                          <Radio
                                            key={subIndex}
                                            value={subOption}
                                          >
                                            {subOption}
                                          </Radio>
                                        )
                                      )}
                                  </Stack>
                                </RadioGroup>
                              </Box>
                            )}
                        </Box>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              ) : (
                <Skeleton height="40px" mb={10} />
              );

            case "checkbox":
              return !isAutoGenerating ? (
                <CheckboxGroup
                  value={formData[variableName] || []}
                  onChange={(values) => {
                    handleInputChange(variableName, values);

                    // Check if sub-options need to be rendered
                    if (subOptions) {
                      values.forEach((value) => {
                        if (subOptions[value]) {
                          // Initialize sub-option value if not already set
                          if (subVariableName && !formData[subVariableName]) {
                            setFormData({
                              ...formData,
                              [subVariableName]: []
                            });
                          }
                        }
                      });
                    }
                  }}
                >
                  <Stack direction="column">
                    {options?.map((option, index) => {
                      const value =
                        typeof option === "string" ? option : option.value;
                      const optionText =
                        typeof option === "string" ? option : option.optionText;
                      const optionTooltip =
                        typeof option === "string"
                          ? undefined
                          : option.tooltipText;

                      return (
                        <Box key={index} display="flex" flexDirection="column">
                          {/* Parent Checkbox */}
                          <Checkbox
                            value={value}
                            ref={index === 0 ? setRef : undefined}
                            isChecked={formData[variableName]?.includes(value)} // Check if parent value is selected
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFormData((prev) => {
                                const updatedValues = isChecked
                                  ? [...(prev[variableName] || []), value]
                                  : (prev[variableName] || []).filter(
                                      (v: any) => v !== value
                                    );

                                const updatedFormData = {
                                  ...prev,
                                  [variableName]: updatedValues
                                };

                                // If unchecked, clear children in subVariableName
                                if (!isChecked && subVariableName) {
                                  updatedFormData[subVariableName] = [];
                                }

                                return updatedFormData;
                              });
                            }}
                          >
                            {optionText}
                          </Checkbox>

                          {/* Tooltip for Parent Checkbox */}
                          {optionTooltip && (
                            <Tooltip
                              label={optionTooltip}
                              aria-label="Option tooltip"
                            >
                              <Icon
                                as={InfoOutlineIcon}
                                ml={2}
                                cursor="pointer"
                              />
                            </Tooltip>
                          )}

                          {/* Sub-options */}
                          {subVariableName &&
                            value &&
                            formData[variableName]?.includes(value) &&
                            subOptions &&
                            subOptions[value] && (
                              <Box pl="6" mt="2">
                                <Text fontWeight="600" mb="2">
                                  Additional Details:
                                </Text>
                                <CheckboxGroup
                                  value={formData[subVariableName] || []} // Child values stored in subVariableName
                                  onChange={(subValues) => {
                                    setFormData((prev) => ({
                                      ...prev,
                                      [subVariableName]: subValues // Update child values only
                                    }));
                                  }}
                                >
                                  <Stack direction="column">
                                    {subOptions[value]
                                      .split(", ")
                                      .map((subOption, subIndex) => (
                                        <Checkbox
                                          key={subIndex}
                                          value={subOption}
                                        >
                                          {subOption}
                                        </Checkbox>
                                      ))}
                                  </Stack>
                                </CheckboxGroup>
                              </Box>
                            )}
                        </Box>
                      );
                    })}
                  </Stack>
                </CheckboxGroup>
              ) : (
                <Skeleton height="40px" mb={10} />
              );

            case "period":
              return !isAutoGenerating ? (
                <Flex direction="column" gap={6} my={4}>
                  {/* Start and End Dates */}
                  <Flex
                    gap={6}
                    wrap="wrap"
                    justify="space-between"
                    align="center"
                  >
                    {/* Start Date */}
                    <Card p={4} flex="1" minWidth="250px">
                      <FormLabel fontWeight="bold" fontSize="md" mb={2}>
                        Start Date
                      </FormLabel>
                      <DatePickerWithCheckbox
                        value={{
                          date: formData[variableName]?.startDate || "",
                          isMonthYearFormat:
                            formData[variableName]
                              ?.startDateIsMonthYearFormat || false
                        }}
                        onChange={(updatedValue) =>
                          handleInputChange(variableName, {
                            ...formData[variableName],
                            startDate: updatedValue.date,
                            startDateIsMonthYearFormat:
                              updatedValue.isMonthYearFormat
                          })
                        }
                      />
                    </Card>

                    {/* End Date */}
                    {formData[variableName]?.isPresent === false ||
                    formData[variableName]?.isPresent === undefined ? (
                      <Card p={4} flex="1" minWidth="250px">
                        <FormLabel fontWeight="bold" fontSize="md" mb={2}>
                          End Date
                        </FormLabel>
                        <DatePickerWithCheckbox
                          value={{
                            date: formData[variableName]?.endDate || "",
                            isMonthYearFormat:
                              formData[variableName]
                                ?.endDateIsMonthYearFormat || false
                          }}
                          onChange={(updatedValue) =>
                            handleInputChange(variableName, {
                              ...formData[variableName],
                              endDate: updatedValue.date,
                              endDateIsMonthYearFormat:
                                updatedValue.isMonthYearFormat
                            })
                          }
                        />
                      </Card>
                    ) : null}
                  </Flex>
                  {/* Present Checkbox */}
                  <Box py={4}>
                    {" "}
                    <Checkbox
                      isChecked={formData[variableName]?.isPresent || false}
                      onChange={(e) => {
                        handleInputChange(variableName, {
                          ...formData[variableName],
                          isPresent: e.target.checked
                        });
                      }}
                    >
                      <Text> Present</Text>
                    </Checkbox>
                  </Box>
                </Flex>
              ) : (
                <Skeleton height="80px" mb={4} />
              );

            case "select":
              return !isAutoGenerating ? (
                <Select
                  placeholder=""
                  value={formData[variableName] || ""}
                  onChange={(e) =>
                    handleInputChange(variableName, e.target.value)
                  }
                  mb={10}
                  isRequired={isRequired}
                  isInvalid={!!errors[variableName]}
                  ref={setRef}
                >
                  {options?.map((option, index) => {
                    const value =
                      typeof option === "string" ? option : option.value;
                    const optionText =
                      typeof option === "string" ? option : option.optionText;
                    return (
                      <option key={index} value={value}>
                        {optionText}
                      </option>
                    );
                  })}
                </Select>
              ) : (
                <Skeleton height="40px" mb={10} />
              );
            default:
              return null;
          }
        })()}
        {errors[variableName] && (
          <Text color="red.500" fontSize="sm">
            {errors[variableName]}
          </Text>
        )}
      </Box>
    );
  };

  const renderQuestionsBySection = () => {
    const groupedQuestions = questions.reduce<Record<string, Question[]>>(
      (acc, question) => {
        const section = question.section || "";
        if (!acc[section]) acc[section] = [];
        acc[section].push(question);
        return acc;
      },
      {}
    );

    return Object.entries(groupedQuestions).map(
      ([section, sectionQuestions]) => {
        // Group questions with their subquestions
        const questionTree: { parent: Question; subQuestions?: Question[] }[] =
          [];

        const questionMap: Record<string, Question[]> = {};
        sectionQuestions.forEach((q) => {
          if (q.subIndex) {
            const parentIndex = q.index ?? "";
            if (!questionMap[parentIndex]) questionMap[parentIndex] = [];
            questionMap[parentIndex].push(q);
          } else {
            questionTree.push({ parent: q });
          }
        });

        // Append subquestions under their parents
        questionTree.forEach((node) => {
          if (node.parent.index && questionMap[node.parent.index]) {
            node.subQuestions = questionMap[node.parent.index];
          }
        });

        return (
          <Box key={section} mb={6}>
            {section && (
              <Text fontSize="2xl" fontWeight="bold" mb={4}>
                {section}
              </Text>
            )}
            {questionTree.map(({ parent, subQuestions }) => (
              <Box key={parent.variableName}>
                {renderQuestionInput(parent)}
                {subQuestions &&
                  subQuestions.map((subQ) => (
                    <Box ml={6} key={subQ.variableName}>
                      {renderQuestionInput(subQ)}
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>
        );
      }
    );
  };

  const handleSaveClick = () => {
    // if (!validateForm())
    //   {
    //   toast({
    //     title: "Validation Error",
    //     description: "Please fill all required fields",
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true
    //   });
    //   setShowConfirmation(false);
    //   return;
    // }

    setShowConfirmation(true);
  };

  const handleSubmit = async () => {
    try {
      setShowConfirmation(false);
      await save(formData);
      // await fetchData();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box p={10}>
      {loading && <Text>Loading questions...</Text>}
      {error && <Text color="red.500">Error: {error.message}</Text>}
      {isAutoGenerating && aiExtractionMsg && (
        <Box mb={4}>{aiExtractionMsg}</Box>
      )}
      {!loading && renderQuestionsBySection()}
      <Flex justifyContent="center" my="6">
        <Button
          onClick={handleSaveClick}
          isDisabled={isAutoGenerating}
          my={4}
          variant="primaryFilled"
          mx="15px"
          type="button"
        >
          Save
        </Button>
      </Flex>
      <SaveAnswersDialog
        isOpen={showConfirmation}
        onConfirm={handleSubmit}
        onClose={() => setShowConfirmation(false)}
      />
    </Box>
  );
};
