import { deleteObject, listAll, ref } from "firebase/storage";
import { useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { db, storage } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

export const useDeleteFileFromDB = () => {
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);
  const dispatch = useDispatch();
  const deleteFolderContents = async (path: string) => {
    const folderRef = ref(storage, path);

    try {
      // List all items (files) and prefixes (folders) under your specified reference
      const listResults = await listAll(folderRef);

      // Check if the folder is empty or does not exist
      if (listResults.items.length === 0 && listResults.prefixes.length === 0) {
        console.log(
          `Folder ${path} does not exist or is empty. No action taken.`
        );
        return; // Return early if there are no files or subfolders
      }

      // Recursively delete all items (files)
      const deletePromises = listResults.items.map((itemRef) =>
        deleteObject(itemRef)
      );

      // If you also have subfolders, you would recursively call deleteFolderContents for each prefix
      const deleteFolderPromises = listResults.prefixes.map((prefixRef) =>
        deleteFolderContents(prefixRef.fullPath)
      );

      // Await all promises from the deletion of files and subfolders
      await Promise.all([...deletePromises, ...deleteFolderPromises]);

      console.log(`Folder ${path} and its contents were deleted successfully.`);
    } catch (error) {
      console.error("There was an error deleting the folder:", error);
    }
  };

  const onSubmitDeleteFile = async (path: string) => {
    const avatarRef = ref(storage, path);
    setIsLoadingDeleteFile(true);

    try {
      await deleteObject(avatarRef);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDeleteFile(false);
    }
  };

  const onDeleteAllClientFiles = async (
    clientId: string,
    path = DATABASE.CLIENTS
  ) => {
    const listRef = ref(storage, `${path}/documents/${clientId}`);
    const folderNames: string[] = [];
    try {
      const res = await listAll(listRef);
      res.prefixes.forEach((folderRef) => {
        folderNames.push(folderRef.name);
      });

      if (folderNames.length) {
        folderNames.forEach(async (file) => {
          const fileRef = ref(storage, `${path}/documents/${clientId}/${file}`);
          const resp = await listAll(fileRef);

          resp.items.forEach(async (itemRef) => {
            const storageRef = ref(storage, itemRef.fullPath);
            await deleteObject(storageRef);
            await deleteDoc(
              doc(db, DATABASE.DOCUMENTS, `${clientId}`, "docs", file)
            );
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onDeleteAllLawyerFiles = async (lawyerId: string) => {
    const listRef = ref(storage, `${DATABASE.LAWYERS}/avatars/${lawyerId}`);
    try {
      const res = await listAll(listRef);
      res.items.forEach(async (itemRef) => {
        const storageRef = ref(storage, itemRef.fullPath);
        await deleteObject(storageRef);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteClientAvatar = async (
    clientId: string,
    path = DATABASE.CLIENTS
  ) => {
    const listRef = ref(storage, `${path}/avatars/${clientId}`);
    try {
      const res = await listAll(listRef);
      res.items.forEach(async (itemRef) => {
        const storageRef = ref(storage, itemRef.fullPath);
        await deleteObject(storageRef);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    deleteFolderContents,
    isLoadingDeleteFile,
    onSubmitDeleteFile,
    onDeleteAllClientFiles,
    onDeleteAllLawyerFiles,
    onDeleteClientAvatar
  };
};
