import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { documentSelectors } from "../redux/documents/selectors";
import { DocumentsSlider } from "./DocumentSlider";

export const IndividualExtractedInfo = () => {
  const { superClass, id: uid, visaType } = useParams();
  const superGroupedDocuments = useSelector(documentSelectors.groupedDocuments);
  const [searchParams, setSearchParams] = useSearchParams();

  const groupedDocuments = superGroupedDocuments.find(
    (group) => group.super_class === superClass
  )?.groups;

  if (!groupedDocuments) return <p>Fetching documents.. </p>;

  const categoryFromPath = searchParams.get("category");

  const categoryFromPathIndex = groupedDocuments.findIndex(
    (group) => group.type === categoryFromPath
  );

  const defaultCategoryIndex =
    categoryFromPathIndex !== -1 ? categoryFromPathIndex : 0;

  const navigate = useNavigate();

  return (
    <SectionWrapper
      backTargetPage="Documents"
      bg="white"
      withIndividualEditBox={false}
      onClick={() => {
        const path = `/individual/${uid}/${visaType}/documents`;
        navigate(path);
      }}
    >
      <SpecialHeading
        title="Edit Document Details"
        topTitle={`${superClass?.replace("Documents", "")} documents`}
      />
      {groupedDocuments?.length === 0 ? (
        <Flex direction="column" align="center" justify="center" h="50%">
          <Heading size="md" mb={2}>
            No Documents Available
          </Heading>
          <Text textAlign="center" fontSize="sm">
            It looks like there are no {superClass} documents uploaded at the
            moment.
            <br />
            Please upload documents to start reviewing and extracting
            information.
          </Text>
        </Flex>
      ) : (
        <Box padding={0} className="flex flex-col gap-12" p={4}>
          <Select
            variant="cyan"
            onChange={(e) => {
              const indx = Number(e.target.value);
              const categorySelect = groupedDocuments[indx].type;
              searchParams.set("category", categorySelect);
              setSearchParams(searchParams);
            }}
            value={defaultCategoryIndex}
            width="fit-content"
          >
            {groupedDocuments?.map((group, indx) => (
              <option key={indx} value={indx}>
                {indx + 1}. {group.type}
              </option>
            ))}
          </Select>
          {defaultCategoryIndex !== undefined && (
            <DocumentsSlider
              categoryIndex={defaultCategoryIndex}
              documents={groupedDocuments[defaultCategoryIndex]?.subrows ?? []}
            />
          )}
        </Box>
      )}
    </SectionWrapper>
  );
};
