import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { VisaTag } from "../components/common/VisaTag";
import { DataTable } from "../components/DataTable";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { setupIntercom } from "../helpers/helpers";
import { useGetClientsData } from "../hooks/useGetClientsData";
import { useLogOut } from "../hooks/useLogOut";
import {
  ClientState,
  setActiveClientsData
} from "../redux/clients/clientsSlice";
import { clientsSelectors } from "../redux/clients/selectors";
import { individualSelectors } from "../redux/individual/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { ClientData, VISAVALUE } from "../types/tables-data";

export const CurrentCases = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const { onSubmitGetClientsData, isLoadingGetClientsData, clientsData } =
    useGetClientsData();

  const dispatch = useDispatch();
  const activeClients = useSelector(clientsSelectors.selectAll);

  const lawyerEmail = useSelector(lawyerSelectors.selectEmail);
  const individualEmail = useSelector(individualSelectors.selectEmail);

  const lawyerUid = useSelector(lawyerSelectors.selectUid);
  const individualUid = useSelector(individualSelectors.selectUid);

  const { resetIndivReduxStore } = useLogOut();

  const getClientsData = async () => {
    console.log("current cases getClientData");
    try {
      await onSubmitGetClientsData(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    resetIndivReduxStore();
  }, []);

  useEffect(() => {
    getClientsData();
    setupIntercom(lawyerEmail || individualEmail, lawyerUid || individualUid);
  }, []);

  useEffect(() => {
    dispatch(setActiveClientsData(clientsData));
  }, [clientsData]);

  const columnHelper = createColumnHelper<ClientState>();
  const columns = [
    columnHelper.accessor("id", {
      enableHiding: true
    }),
    columnHelper.accessor("email", {
      enableHiding: true
    }),
    columnHelper.accessor("name", {
      enableHiding: true,

      cell: (info) => {
        const row = info.row.original;
        const birthDay = row.birthDay
          ? dayjs(row.birthDay).format("MM/DD/YYYY")
          : "mm/dd/yyyy";

        return (
          <Box>
            <Text noOfLines={1}>{info.getValue()}</Text>
            <Text fontSize="sm" color="gray.500">
              {birthDay}
            </Text>
          </Box>
        );
      },
      header: "Name"
    }),

    columnHelper.accessor("visa", {
      cell: (info) => <VisaTag visaType={info.getValue() ?? VISAVALUE.EMPTY} />,
      id: "visa",
      header: "Visa"
    }),
    columnHelper.accessor("employerInfo.legalBusinessName", {
      cell: (info) => {
        const petitionerName =
          info.row.original.employerInfo?.legalBusinessName ||
          info.row.original.employerPetitioner ||
          "-";
        return <Text noOfLines={2}>{petitionerName}</Text>;
      },
      header: "Petitioner"
    }),
    columnHelper.accessor("createdDate", {
      cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY"),
      header: "Date Added"
    })
  ];

  return (
    <SectionWrapper
      withBackNav={false}
      bg="rgba(255, 255, 255, 0.5)"
    >
      <Box width="100%">
        <SpecialHeading title="Current cases" />
      </Box>

      {activeClients.length === 0 && isLoadingGetClientsData && (
        <div className="flex items-center justify-center">
          <Spinner
            alignSelf="center"
            alignContent="center"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary.blue"
            size="xl"
          />
        </div>
      )}
      {activeClients.length === 0 && !isLoadingGetClientsData && (
        <Heading size="sm" my="15px">
          No active cases found. <br /> Click &quot;New case&quot; to add the
          first client
        </Heading>
      )}
      {!isLoadingGetClientsData && (
        <DataTable columns={columns} data={activeClients} isSortable />
      )}
    </SectionWrapper>
  );
};
