import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useToast
} from "@chakra-ui/react";
import { useRef, useState, DragEvent, useEffect } from "react";
import { IoFolderOpen } from "react-icons/io5";
import images from "../assets/image-export-assets";
import { supportedExtensionsString2 } from "../constans";
// eslint-disable-next-line import/no-cycle
import UploadByCategoryModal from "./individualTabs/individualDocuments/UploadByCategoryModal";
import { VisaDocumentType } from "../redux/documents/types";

interface FileUploaderProps {
  title?: string;
  subTitle: string;
  tableRef?: "evidence" | "standard" | "signed_expert_letters";
  onUpload: (files: FileList | null) => void;
  withImg?: boolean;
  styleIndex?: number;
  isDisabled?: boolean;
  uploaded?: boolean;
  fileName?: string;
  // DB & Storage
  storagePath?: string | null;
  collectionPath?: string | null;
  // Upload by Category
  allowUploadByCategory?: boolean;
  superClass?: string | null;
  visaDocumentType?: VisaDocumentType | null;
}

export const UploadFilesPlaceholder = ({
  title,
  subTitle,
  tableRef,
  onUpload,
  withImg = false,
  styleIndex = 0,
  isDisabled = false,
  uploaded,
  fileName,
  allowUploadByCategory = false,
  superClass,
  collectionPath,
  storagePath,
  visaDocumentType = VisaDocumentType.Evidence
}: FileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showUploadByCatModal, setShowUploadByCatModal] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const toast = useToast();

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      onUpload(files);
      toast({
        title: "Files uploaded successfully.",
        status: "success",
        duration: 3000,
        isClosable: true
      });
    }
  };

  useEffect(() => {
    // Cleanup function to clear files on unmount
    return () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
  }, []);

  const onUploadByCatClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowUploadByCatModal(true);
  };

  const onUploadByCatModalClose = () => {
    setShowUploadByCatModal(false);
  };

  const styles = [
    {
      container: {
        bg: "primary.backgroundLight",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center" as const
      },
      buttons: {
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        marginTop: "20px"
      }
    },
    {
      container: {
        bg: isDragActive ? "primary.backgroundLight" : "",
        border: "2px dashed",
        borderColor: isDragActive ? "primary.blue" : "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center" as const
      },
      text: {
        fontWeight: "bold",
        color: "primary.darkBlue",
        marginBottom: "10px"
      }
    },

    {
      container: {
        bg: "bgColor.200",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        display: "flex" as const,
        flexDirection: "column" as const,
        alignItems: "center" as const,
        textAlign: "center" as const
      },
      buttons: {
        display: "flex",
        gap: "10px"
      }
    }
  ];

  const style = styles[styleIndex] || styles[0];

  return (
    <Flex flexDirection="column" textAlign="start">
      <Box
        bg={styleIndex === 2 ? "background.cloud" : ""}
        style={style.container}
        cursor="pointer"
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        _hover={{ bg: "primary.backgroundLight" }}
        borderColor={isDragActive ? "primary.blue" : "#ebedf0"}
        backgroundColor={isDragActive ? "primary.backgroundLight" : ""}
      >
        {styleIndex === 0 && (
          <Flex flexDirection="column" alignItems="center">
            <Flex justifyContent="start">
              {withImg && (
                <Image src={images.fileupload} height="120px" mb={4} />
              )}
            </Flex>
            <Text mt={2} fontWeight={600}>
              {title}
            </Text>
            <Text mt={2} fontWeight={600}>
              {subTitle}
            </Text>
            <Box style={style.buttons}>
              <Button variant="primaryFilled">Upload files</Button>
              {allowUploadByCategory && (
                <Button variant="secondaryOutline" onClick={onUploadByCatClick}>
                  Upload by Category
                </Button>
              )}
            </Box>
            <Text
              mt={8}
              fontSize="sm"
              fontWeight={500}
              color="text.graySecondary.smog"
            >
              Supported formats: {supportedExtensionsString2}
            </Text>
          </Flex>
        )}
        {styleIndex === 1 &&
          (uploaded ? (
            fileName
          ) : (
            <Box>
              <Text style={style.text} fontWeight="bold">
                Drag and drop here or{" "}
                <Text as="span" color="primary.blue">
                  Browse files
                </Text>
              </Text>
              <Text fontSize="sm" color="secondary.darkGray">
                Supported formats: {supportedExtensionsString2}
              </Text>
            </Box>
          ))}
        {styleIndex === 2 && (
          <>
            <Flex columnGap={4} alignItems="center" textAlign="center">
              <Text fontWeight="600">Drag and drop here</Text>
              <Text mx={4}>OR</Text>
              <Box style={style.buttons}>
                <Button size="sm" variant="secondaryOutline">
                  {title || (uploaded ? fileName : "Upload files")}
                </Button>
                <Divider orientation="vertical" />
                {allowUploadByCategory && (
                  <Button
                    size="sm"
                    variant="secondaryOutline"
                    onClick={onUploadByCatClick}
                  >
                    Upload by Category
                  </Button>
                )}
              </Box>
            </Flex>
            <Text mt={2} fontSize="sm" color="secondary.darkGray">
              Supported formats: {supportedExtensionsString2}
            </Text>
          </>
        )}
        <input
          disabled={isDisabled}
          onChange={(e) => onUpload(e.target.files)}
          className="hidden"
          multiple
          type="file"
          ref={fileInputRef}
          accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
        />
      </Box>
      <UploadByCategoryModal
        isOpen={showUploadByCatModal}
        onClose={onUploadByCatModalClose}
        storagePath={storagePath!}
        collectionPath={collectionPath!}
        visaDocumentType={visaDocumentType!}
      />
    </Flex>
  );
};

export default UploadFilesPlaceholder;
