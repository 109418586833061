import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Text,
  Textarea
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { mapVisaTypeToPath } from "../../../helpers/helpers";
import { useDocumentCategoriesAndTypes } from "../../../hooks/useDocumentCategoriesAndTypes";
import { useGetSubDocuments } from "../../../hooks/useGetSubDocuments";
import { updateDocument } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { ToastManager } from "../../../services/toast/toastManager";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
import { DropdownGeneric } from "../../common/DropdownGeneric";

interface Props {
  document: DataDocs | DataDocs | undefined;
}

const DocumentInformation: React.FC<Props> = ({ document }) => {
  if (!document) return null;

  const { visaType, id: uid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const documentTypes = useSelector(documentSelectors.documentTypes);
  const mappedVisaType = mapVisaTypeToPath(visaType!);

  const [currentDocument, setCurrentDocument] = useState<DataDocs>(document);
  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { types, reduceCategoriesToSelectedItems } =
    useDocumentCategoriesAndTypes(currentDocument, mappedVisaType);

  const documentChanged = !_.isEqual(document, currentDocument);
  const list = reduceCategoriesToSelectedItems(documentTypes, visaType!);
  const superClassOrCategoryChanged =
    document.super_class !== currentDocument?.super_class ||
    document.criterion !== currentDocument?.criterion;

  const validate = (): boolean => {
    if (currentDocument?.type === undefined) {
      setError("Document type is required");
      return false;
    }
    setError(undefined);
    return true;
  };

  const handleInputChange = (key: string, value: any) => {
    setCurrentDocument((prevState) => {
      return {
        ...prevState,
        [key]: value,
        // resetting type dropdown
        ...(key === "criterion" && {
          type: undefined
        })
      };
    });
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    setIsSubmitting(true);

    const dataPayload: DataDocs = {
      ...currentDocument,
      documentTitle: currentDocument?.documentTitle ?? ""
    };

    const docRef = doc(db, currentDocument.docRef!);

    await updateDoc(docRef, dataPayload).then(() => {
      dispatch(updateDocument(dataPayload));
      if (superClassOrCategoryChanged) {
        const { super_class, criterion, id } = dataPayload;
        const newPath = `/individual/${uid}/${visaType}/documents/${super_class}/extracted-information?category=${criterion}&docId=${id}`;
        const run = setTimeout(() => {
          navigate(newPath);
        }, 1000);
      }
    });
    setIsSubmitting(false);
    ToastManager.getInstance().showToast(
      DATABASE.DOCUMENTS,
      "success",
      "updated"
    );
  };

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (documentChanged) {
        e.preventDefault();
        return true;
      }
      return false;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [documentChanged]);

  useEffect(() => {
    validate();
  }, [currentDocument]);

  return (
    <>
      <Flex flexDirection="column" gap={4}>
        <Box>
          <FormLabel>Category</FormLabel>
          <DropdownGeneric
            singleSelect
            withArrow
            label={currentDocument?.criterion || "Select Category"}
            getSelectedItem={(item) => {
              if (item !== undefined) {
                handleInputChange("criterion", item.category);
                handleInputChange("super_class", item.super_class);
              }
            }}
            groupBy="super_class"
            mergingField="super_class"
            searchField="category"
            list={list}
          />
        </Box>

        <Box>
          <FormLabel>Document Type</FormLabel>
          <Dropdown
            selectedValue={currentDocument?.type}
            title="Type"
            onItemSelect={(value) => {
              handleInputChange("type", value);
            }}
            list={types}
          />
        </Box>
        {currentDocument?.type === "Other" && (
          <Input
            placeholder="Document title"
            value={currentDocument?.documentTitle ?? ""}
            onChange={(e) => {
              handleInputChange("documentTitle", e.target.value);
            }}
          />
        )}
        {error && <Text color="red">{error}</Text>}
        <Box flexDirection="column">
          <FormLabel htmlFor="description">Additional Description</FormLabel>
          <Textarea
            id="description"
            size="lg"
            resize="vertical"
            placeholder="Please explain why this document is relevant and how it showcases the applicant's accomplishments."
            value={currentDocument?.description ?? ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </Box>
        {currentDocument?.autoTitle !== undefined && (
          <Box>
            <FormLabel htmlFor="ai-title">Document Title</FormLabel>
            <Textarea
              size="lg"
              resize="vertical"
              value={currentDocument?.autoTitle ?? ""}
              onChange={(e) => handleInputChange("autoTitle", e.target.value)}
              name="ai-title"
              id="ai-title"
            />
          </Box>
        )}
      </Flex>

      <Divider my={4} visibility="hidden" />

      <Box display="flex" justifyContent="end" gap={2}>
        <Button
          variant="secondaryOutline"
          onClick={() => {
            setCurrentDocument({ ...document });
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primaryFilled"
          isLoading={isSubmitting}
          onClick={handleSubmit}
          isDisabled={!documentChanged}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default DocumentInformation;
