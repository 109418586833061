import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  VStack
} from "@chakra-ui/react";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  setDoc
} from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import {
  downloadFileAsBlob,
  getFileName,
  handleUnsupportedExtension
} from "../../../helpers/file_helpers";
import { getKeyByValue } from "../../../helpers/helpers";
import { getNextDocumentName } from "../../../helpers/string_helpers";
import { useAddFileToDB } from "../../../hooks/useAddFileToDB";
import { useDeleteFileFromDB } from "../../../hooks/useDeleteFileFromDB";
import { useGetFilesFromDB } from "../../../hooks/useGetFilesFromDB";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { templatesSelectors } from "../../../redux/templates/selectors";
import {
  CustomTemplate,
  CustomTemplateType,
  LegalBriefType,
  templateTypeMap,
  VisaLegalBriefConfig
} from "../../../types/drafts.type";
import { DATABASE, VISAVALUE } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import { CustomBox } from "../../common/CustomBox";
import { templatesWarningMsg } from "../../../consts/strings";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fromPreviousCase: boolean;
};

const UploadNewTemplateModal = ({
  isOpen,
  onClose,
  fromPreviousCase
}: Props) => {
  // State Management
  const [visaType, setVisaType] = useState<string | undefined>(undefined);
  const [uploadTo, setUploadTo] = useState<string | undefined>(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<
    CustomTemplate | undefined
  >(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const [generatedTemplateId, setGeneratedTemplateId] = useState<string | null>(
    null
  );
  const [areaOfExpertise, setAreaOfExpertise] = useState(false);
  const [employerDetails, setEmployerDetails] = useState(false);
  const [docRef, setDocRef] = useState<DocumentReference<DocumentData> | null>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [templateName, setTemplateName] = useState<string | undefined>(
    undefined
  );

  // Selectors
  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const firstName = useSelector(lawyerSelectors.selectFirstName);
  const lastName = useSelector(lawyerSelectors.selectLastName);
  const customTemplates = useSelector(templatesSelectors.selectAll);

  // Hooks
  const { deleteFolderContents } = useDeleteFileFromDB();
  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();
  const navigate = useNavigate();

  // Constants
  const storagePath = `${DATABASE.LAWYERS}/documents/${lawyerId}`;
  const collectionPath = `${DATABASE.CUSTOM_TEMPLATES}/${lawyerId}/custom_templates`;

  const [templateTypeStrings, setTemplateTypeStrings] = useState<string[]>([]);

  const generateDocumentId = async () => {
    console.log("generate_document_id: Function called");
    const colRef = collection(db, collectionPath);

    if (!lawyerId || !colRef) {
      console.log("generate_document_id: Missing required parameters", {
        lawyerId,
        colRef
      });
      return null;
    }

    const docRef = doc(colRef);
    console.log("generate_document_id: Generated document reference", {
      docRef
    });

    const generatedId = docRef.id;
    console.log("generate_document_id: Generated document ID", { generatedId });

    setGeneratedTemplateId(generatedId);
    console.log(
      "generate_document_id: Updated state with generatedTemplateId",
      { generatedId }
    );

    setDocRef(docRef);
    console.log("generate_document_id: Updated state with docRef", { docRef });

    return generatedId;
  };

  const handleFileChange = async (
    e?: React.ChangeEvent<HTMLInputElement> | null,
    files?: File[] | null
  ) => {
    console.log("handle_file_change: Triggered with arguments", {
      event: e,
      files
    });

    const templateId = generatedTemplateId || (await generateDocumentId());
    console.log("handle_file_change: Resolved templateId", { templateId });

    const file = e?.target?.files?.[0] || files?.[0] || null;
    console.log("handle_file_change: Resolved file", { file });

    if (file && !handleUnsupportedExtension(file.name, fileRef) && templateId) {
      console.log("handle_file_change: File is valid and will be set", {
        file
      });
      setSelectedFile(file);
    } else {
      console.log(
        "handle_file_change: File is invalid or templateId is missing",
        {
          file,
          templateId
        }
      );
    }
  };

  const uploadImage = async (templateId: string, file: File) => {
    // setIsUploadingFile(true);
    onClose();
    if (file && templateId) {
      try {
        const folderPath = `${storagePath}/${templateId}`;
        const filePath = `${folderPath}/${file.name}`;

        const templateType = getKeyByValue(
          templateTypeMap,
          uploadTo
        ) as CustomTemplateType;
        const documentPath = `${collectionPath}/${templateId}`;

        await deleteFolderContents(folderPath);
        await onSubmitAddFile(file, filePath);
        await onSubmitGetFiles(folderPath);

        const template: CustomTemplate = {
          templateName: templateName || "",
          created_at: Date.now(),
          uploadDate: Date.now(),
          uploadBy: lawyerId,
          uploadedByName: `${firstName} ${lastName}`,
          created_by: `${firstName} ${lastName}`,
          docNames: file.name,
          filePath,
          isDeleted: false,
          templateInfoToRetain: [
            areaOfExpertise ? "area_of_expertise" : null,
            employerDetails ? "employer_details" : null
          ].filter((item): item is string => item !== null),
          visa: (visaType as VISAVALUE) ?? VISAVALUE.EMPTY,
          type: templateType,
          fromPreviousCase
        };

        // Submit template to Firestore here
        const docRef = doc(db, documentPath); // Reference to the document
        await setDoc(docRef, template); // Upload the template object
        console.log("Template successfully uploaded to Firestore");
        navigate("/studio/templates");
        // Submit template to Firestore here
      } finally {
        setIsUploadingFile(false);
        setIsLoadingDoc(false);
      }
    }
  };

  const handleConfirm = async () => {
    setIsUploadingFile(true);
    const templateId = generatedTemplateId || selectedTemplate?.id;
    if (templateId && selectedFile) {
      await uploadImage(templateId, selectedFile);
    }
    setIsUploadingFile(false);
  };

  useMemo(() => {
    setTemplateName(getNextDocumentName(customTemplates));
  }, [customTemplates]);

  useEffect(() => {
    setIsLoadingDoc(true);
    if (storageFiles.length) {
      const lastFileRef = storageFiles[storageFiles.length - 1];
      downloadFileAsBlob(lastFileRef)
        .then((objectURL) => {
          setFileUrl(objectURL);
          setFileName(lastFileRef.name);
        })
        .finally(() => setIsLoadingDoc(false));
    }
  }, [storageFiles]);

  useEffect(() => {
    if (visaType) {
      const newTemplatesTypes = VisaLegalBriefConfig[visaType as VISAVALUE];
      setTemplateTypeStrings(newTemplatesTypes);
      if (newTemplatesTypes.length === 1) {
        setUploadTo(templateTypeStrings[0]);
      }
    }
  }, [visaType]);

  // Reset fields when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setVisaType(undefined);
      setUploadTo(undefined);
      setSelectedTemplate(undefined);
      setAreaOfExpertise(false);
      setEmployerDetails(false);
      setSelectedFile(null);
      setGeneratedTemplateId(null);
      setDocRef(null);
      setFileUrl(null);
      setFileName(null);
    }
  }, [isOpen]);

  // Render
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {fromPreviousCase
            ? "Upload a Previous Case Sample"
            : "Upload new template"}
          <Tag
            size="sm"
            variant="outline"
            colorScheme="blue"
            borderRadius="md"
            mt={1}
            px={2}
            mx={2}
            fontWeight="bold"
            fontSize="xs"
          >
            <TagLabel>BETA</TagLabel>
          </Tag>
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="start">
            <FormControl>
              <FormLabel>Template Name</FormLabel>
              <Input
                placeholder="Untitled template"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Visa Type</FormLabel>
              <Dropdown
                selectedValue={visaType}
                title="Visa Type"
                onItemSelect={(value) => {
                  setVisaType(value);
                  setUploadTo(undefined);
                }}
                list={Object.values(VISAVALUE).filter((value) => value !== "")}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Upload To</FormLabel>
              <Dropdown
                selectedValue={uploadTo}
                title="Upload To"
                onItemSelect={setUploadTo}
                list={templateTypeStrings}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>
                Information to maintain from the template (Optional)
              </FormLabel>
              <VStack alignItems="start">
                <Checkbox
                  isChecked={areaOfExpertise}
                  onChange={(e) => setAreaOfExpertise(e.target.checked)}
                >
                  Area of expertise
                </Checkbox>
                <Checkbox
                  isChecked={employerDetails}
                  onChange={(e) => setEmployerDetails(e.target.checked)}
                >
                  Employer details
                </Checkbox>
              </VStack>
            </FormControl>

            <FormControl>
              <FormLabel>Upload File</FormLabel>
              <UploadFilesPlaceholder
                styleIndex={1}
                title="Drag and drop additional files to bundle with this document here"
                subTitle="Click Here to Upload"
                onUpload={(files) => handleFileChange(null, Array.from(files!))}
                uploaded={selectedFile !== null}
                fileName={
                  selectedFile ? getFileName(selectedFile.name) : undefined
                }
              />
            </FormControl>
          </VStack>
          <CustomBox type="softRed" fontSize="small" my={2}>
            {templatesWarningMsg}
          </CustomBox>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondaryOutline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primaryFilled"
            onClick={handleConfirm}
            isLoading={isUploading || isUploadingFile}
            isDisabled={
              !templateName || !visaType || !uploadTo || !selectedFile
            }
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadNewTemplateModal;
