import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../api/firebaseApi";
import { setSubDocuments } from "../redux/documents/documentSlice";
import { generateDocumentUrls } from "../redux/documents/documentsThunk";
import { useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";

export const useGetSubDocuments = (document: DataDocs) => {
  const dispatch = useTypedDispatch();

  const isEvidence = document.docRef?.split("/").includes("evidence_docs");

  useEffect(() => {
    if (document) {
      const fetchSubDocuments = async () => {
        // ask John if we can run a migration on documents to use
        // sub_docs or docs to avoid such conditions
        const collectionRef = collection(db, `${document.docRef}/docs`);

        const subDocuments: DataDocs[] = [];

        const filteredQuery = query(
          collectionRef,
          where("isDeleted", "in", [false, null])
        );

        const querySnapshot = await getDocs(filteredQuery);

        querySnapshot.forEach((doc) => {
          const subDocument = doc.data() as DataDocs;
          subDocuments.push({
            id: doc.id,
            ...subDocument,
            docRef: doc.ref.path,
            isDeleted: subDocument.isDeleted ?? false
          });
        });

        dispatch(
          setSubDocuments({
            data: { documents: subDocuments, id: document.id ?? "" }
          })
        );

        dispatch(
          generateDocumentUrls({
            documents: subDocuments?.map((doc) => ({
              id: doc.id as string,
              filePath: doc.filePath as string,
              criterion: document.criterion ?? "",
              super_class: document.super_class ?? ""
            })),
            mainDocId: document.id
          })
        );
      };

      fetchSubDocuments();
    }
  }, [document]);
};
