import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Checkbox,
  Divider
} from "@chakra-ui/react";
import { useFormikContext, Field } from "formik";
import { useParams } from "react-router-dom";
import { ClientState } from "../../redux/clients/clientsSlice";
import { InputField } from "../InputField";
import { AddressFields } from "../common/AddressFields";

export const ClientContactForm = () => {
  const { values, errors, touched } = useFormikContext<ClientState>();
  const params = useParams();
  const newCase = !params.caseID;

  return (
    <VStack
      spacing={4}
      align="stretch"
      sx={{
        input: {
          bg: "white",
          _hover: { bg: "gray.200" },
          borderRadius: "md"
        },
        button: {
          borderRadius: "md"
        }
      }}
    >
      <InputField label="Email Address" name="email" isRequired />

      {!newCase && (
        <>
          <Field name="phone">
            {({ field, form }: any) => (
              <InputField
                {...field}
                name="phone"
                id="phone"
                placeholder="Phone Number"
                label="Phone Number"
              />
            )}
          </Field>

          <Heading
            size="16"
            textAlign="left"
            fontWeight="700"
            color="primary.blue"
            textDecoration="underline"
          >
            Physical Address
          </Heading>
          <AddressFields
            name="physicalAddress"
            errors={errors}
            touched={touched}
          />

          <Heading
            size="16"
            textAlign="left"
            fontWeight="700"
            color="primary.blue"
            textDecoration="underline"
          >
            Mailing Address
          </Heading>
          <Field name="similarAddress">
            {({ field }: any) => (
              <Checkbox isChecked={field.value} {...field}>
                Same as Physical Address
              </Checkbox>
            )}
          </Field>
          {!values?.similarAddress && (
            <AddressFields
              name="mailingAddress"
              errors={errors}
              touched={touched}
            />
          )}
        </>
      )}
      <Divider />
    </VStack>
  );
};
