const isFeatureAccessible = (featureId: string, accessibleFeatures: string[]): boolean => {
    console.log(`Checking accessibility for featureId: ${featureId}`);
    console.log(`Accessible features: ${JSON.stringify(accessibleFeatures)}`);
    
    const isAccessible = accessibleFeatures.includes(featureId);
    
    console.log(`Feature accessibility result: ${isAccessible}`);
    return isAccessible;
};


export { isFeatureAccessible };
