import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";

interface Props {
  title: string;
  onItemSelect: (value: any) => void;
  list: any[];
  selectedValue: any;
  isDisabled?: boolean;
  type?: "custom_brief_templates" | null;
}

export const Dropdown = ({
  title,
  onItemSelect,
  list,
  selectedValue,
  isDisabled,
  type
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredList = useMemo(() => {
    return list.filter((item) => {
      console.log("item from filtered list: ", item);

      // Expert case with `expertName`
      if (title === "Expert" && item.expertName) {
        return item.expertName.toLowerCase().includes(searchTerm.toLowerCase());
      }

      // Object with `title` property
      if (typeof item === "object" && item.title) {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      }

      // Object with `templateName` property
      if (typeof item === "object" && item.templateName) {
        return item.templateName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
      }

      // Plain strings
      if (typeof item === "string") {
        return item.toLowerCase().includes(searchTerm.toLowerCase());
      }

      // Numbers
      if (typeof item === "number") {
        return item.toString().includes(searchTerm);
      }

      // Unsupported types
      return false;
    });
  }, [list, searchTerm]);

  const renderTitle = () => {
    if (typeof selectedValue === "object" && selectedValue !== null) {
      // Expert Letter case
      if (title === "Expert" && selectedValue.expertName) {
        return selectedValue.expertName;
      }

      // Custom templates case
      if (
        type &&
        type === "custom_brief_templates" &&
        selectedValue.templateName
      ) {
        return selectedValue.templateName;
      }

      return selectedValue.title || title;
    }
    return selectedValue || title;
  };

  const renderMenuItem = (item: any) => {
    let content;

    if (typeof item === "object" && item !== null) {
      // Expert Letter case
      if (title === "Expert" && item.expertName) {
        content = item.expertName;
      } else if (
        type &&
        type === "custom_brief_templates" &&
        item.templateName
      ) {
        // Custom templates case
        content = item.templateName;
      } else {
        content = item.title;
      }
    } else {
      // Default case for non-object items
      content = item;
    }

    return (
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Box>{content}</Box>
        {item?.badgeText && (
          <Badge colorScheme="blue" variant="outline" ml={2}>
            {item.badgeText}
          </Badge>
        )}
      </Flex>
    );
  };

  useEffect(() => {
    console.log("list from Dropdown : ", list);
  }, [list]);

  return (
    <Box w="100%">
      <Menu>
        <MenuButton
          id="buttton22"
          isDisabled={isDisabled}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="add"
          width="fit-content"
          borderRadius={8}
        >
          {renderTitle()}
        </MenuButton>
        <MenuList py={2} px={0} maxHeight={300} overflowY="auto">
          <Flex flexDirection="column">
            <Box px={2}>
              <Input
                autoFocus
                placeholder="Search..."
                variant="filled"
                onChange={(e) => setSearchTerm(e.target.value)}
                my={2}
              />
            </Box>
            {filteredList.map((item, index) => (
              <MenuItem
                key={index}
                autoFocus={false}
                onClick={() => onItemSelect(item)}
              >
                {renderMenuItem(item)}
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>
    </Box>
  );
};
