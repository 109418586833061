import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBlob, ref } from "firebase/storage";
import { storage } from "../../api/firebaseApi";
import { GenerateDocumentsProps } from "../../helpers/helpers";

export const generateDocumentUrls = createAsyncThunk(
  "document/generateDocumentUrls",
  async (
    { documents, mainDocId }: GenerateDocumentsProps,
    { rejectWithValue }
  ) => {
    try {
      const result: {
        id: string;
        fileUrl: string;
        mainDocId: string | undefined;
        super_class: string;
        criterion: string;
      }[] = [];
      const promises = documents.map(async (document) => {
        const fileRef = ref(storage, document.filePath);
        const fileBlob = await getBlob(fileRef);
        const fileUrl = URL.createObjectURL(fileBlob);
        result.push({
          id: document.id,
          fileUrl,
          mainDocId,
          super_class: document.super_class || "Processing",
          criterion: document.criterion || "Processing"
        });
      });
      await Promise.allSettled(promises);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const generateSingleDocumentUrl = createAsyncThunk(
  "document/generateSingleDocumentUrl",
  async (
    document: {
      id: string;
      filePath: string;
      super_class: string;
      criterion: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const fileRef = ref(storage, document.filePath);
      const fileBlob = await getBlob(fileRef);
      const fileUrl = URL.createObjectURL(fileBlob);
      return {
        id: document.id,
        fileUrl,
        super_class: document.super_class || "Processing",
        criterion: document.criterion || "Processing"
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
