import {
  Query,
  Timestamp,
  collection,
  getDocs,
  orderBy,
  query
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import useFirestoreDocument from "./useFirestoreDocument";

export interface OptionType {
  optionText: string;
  tooltipText?: string;
  value?: string;
}

type SubOptions = {
  [category: string]: string;
};

export type Period = {
  startDate: Timestamp;
  endDate: Timestamp;
  isPresent: boolean;
  startDateIsMonthYearFormat: boolean;
  endDateIsMonthYearFormat: boolean;
};
export interface Question {
  id: string;
  fieldType: "radio" | "checkbox" | "text" | "textarea" | "select" | "period";
  isRequired: boolean;
  options?: (string | OptionType)[];
  subOptions?: SubOptions | null;
  questionText: string;
  variableName: string;
  subVariableName?: string | null;
  description: string;
  index?: number | null;
  subIndex?: string | null;
  tooltipText?: string;
  section?: string | null;
  subQuestions?: Question[] | null;
}

const useVisaQuestions = (visaType?: string | null, uid?: string | null) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const { createDocument } = useFirestoreDocument<Record<string, string>>(
    DATABASE.QUESTIONNAIRES,
    uid || ""
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!visaType) {
        return;
      }

      try {
        setLoading(true);

        const questionsRef = collection(
          db,
          `visa_questions/${visaType}/questions`
        );

        // Check if the `index` field exists in any document
        const initialSnapshot = await getDocs(questionsRef);

        const indexExists = initialSnapshot.docs.some(
          (doc) => "index" in doc.data()
        );

        const questionsQuery: Query = indexExists
          ? query(questionsRef, orderBy("index"))
          : questionsRef;

        const questionsSnapshot = await getDocs(questionsQuery);

        const fetchedQuestions: Question[] = questionsSnapshot.docs.map(
          (doc) => ({ id: String(doc.id), ...doc.data() } as Question)
        );

        console.log("testing o1b: fetchedQuestions:", fetchedQuestions);

        setQuestions(fetchedQuestions);
      } catch (e) {
        console.error("testing o1b: Error fetching questions:", e);
        setError(e as Error);
      } finally {
        console.log("testing o1b: Fetch operation completed");
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const save = async (formData: Record<string, string>) => {
    if (!uid) return;
    await createDocument(formData);
  };

  return { questions, loading, error, save };
};

export default useVisaQuestions;
