import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Grid,
  IconButton,
  Switch,
  Text,
  useToast
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { convertFromHtml } from "../../../../api/draftsApi";
import { db } from "../../../../api/firebaseApi";
import { triggerDownload } from "../../../../helpers/file_helpers";
import { useIndivSop } from "../../../../hooks/useSopDrafts";
import { useUserRoleAndSubscription } from "../../../../hooks/useUserRoleAndSubscription";
import { LegalBriefSection } from "../../../../redux/drafts/draftsSlice";
import { draftsSelectors } from "../../../../redux/drafts/selectors";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { DATABASE } from "../../../../types/tables-data";
import ExportMenu from "../../../common/ExportMenu";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { SectionWrapper } from "../../../common/SectionWrapper";
import { SpecialHeading } from "../../PanelLayout";

export const EditSopDraft = () => {
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const sop = useSelector(draftsSelectors.selectLegalBrief);
  const { isSuperAdmin } = useUserRoleAndSubscription();
  const [userAccessEnabled, setUserAccessEnabled] = useState(
    sop?.value.accessEnabled ?? false
  );
  const { updateSop, isLoadingSop } = useIndivSop(uid);
  const [expandedSections, setExpandedSections] = useState(
    sop?.value.sections?.map((_: any, index: any) => index) || []
  );
  const [highlightedSection, setHighlightedSection] = useState<number | null>(
    null
  );
  const [isDownloadingFullDraft, setIsDownloadingFullDraft] =
    useState<boolean>(false);

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const downloadFullDraft = async (format: string) => {
    setIsDownloadingFullDraft(true);
    try {
      const { data, filename } = await convertFromHtml({
        format,
        individualId: uid,
        firebasePath: `drafts/${uid}`,
        documentField: "sop",
        htmlField: "html",
      });
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
    } finally {
      setIsDownloadingFullDraft(false);
    }
  };

  const toggleSectionFromHeader = (index: number) => {
    setExpandedSections((prevExpanded: number[]) => {
      const isExpanded = prevExpanded.includes(index);

      // If expanding the section, set it as highlighted
      if (!isExpanded) {
        setHighlightedSection(index);
      } else {
        setHighlightedSection(null); // Remove highlight if collapsing
      }

      return isExpanded
        ? prevExpanded.filter((i) => i !== index) // Collapse if expanded
        : [...prevExpanded, index]; // Expand if collapsed
    });
  };

  // For expanding and scrolling when clicked from the contents table
  const expandSectionFromTable = (index: number) => {
    setExpandedSections((prevExpanded: number[]) => {
      // Ensure the section is expanded
      if (!prevExpanded.includes(index)) {
        return [...prevExpanded, index];
      }
      return prevExpanded;
    });

    // Scroll to the section after expanding it
    setTimeout(() => {
      sectionRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 50);
  };

  const handleSwitchChange = async (checked: boolean) => {
    setUserAccessEnabled(checked);
    try {
      const docRef = doc(db, `${DATABASE.DRAFTS}/${id}`);
      await updateDoc(docRef, {
        "sop.accessEnabled": checked
      });

      toast({
        title: "Success",
        description: `User access has been ${
          checked ? "enabled" : "disabled"
        }.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (error) {
      console.error("Failed to update user access:", error);
      toast({
        title: "Error",
        description: "Failed to update user access.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    }
  };

  const handleSubmit = async (
    html: string,
    plainText: string,
    sectionIndex: number
  ) => {
    const updatedSections = [...(sop?.value.sections || [])];
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      html,
      text: plainText
    };
    if (uid) await updateSop(updatedSections, userAccessEnabled);
  };

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = sectionRefs.current.indexOf(
            entry.target as HTMLDivElement
          );

          // Highlight in table only if the section is open and fully in view
          if (entry.isIntersecting && expandedSections.includes(index)) {
            setHighlightedSection(index);
          }
        });
      },
      { threshold: 0.1 } // Trigger only when fully in view
    );

    return () => {
      observer.current?.disconnect();
    };
  }, [expandedSections]);

  const setSectionRef = useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      sectionRefs.current[index] = el;

      // Observe only if the element is available and observer is initialized
      if (el && observer.current) {
        observer.current.observe(el);
      }
    },
    []
  );

  const renderContent = () => {
    if (isLoadingSop) {
      return <CircularProgress isIndeterminate color="primary.blue" />;
    }

    if (!sop?.value.sections?.length) {
      return (
        <Box fontSize="18px" textAlign="center">
          Click Generate in the previous page for a first draft.
        </Box>
      );
    }
    return (
      <Grid templateColumns="14vw 1fr" gap={6} width="100%">
        {/* Sidebar Navigation */}
        <Box
          bg="background.cloud"
          p={4}
          rounded="md"
          position="sticky"
          top="20px"
          height="fit-content"
        >
          <Text fontWeight="bold" mb={4} color="primary.blue">
            Contents
          </Text>

          <Box as="ul" listStyleType="disc" pl={4}>
            {sop?.value?.sections?.map(
              (section: LegalBriefSection, index: number) => (
                <Box
                  as="li"
                  key={index}
                  cursor="pointer"
                  color={
                    highlightedSection === index &&
                    expandedSections.includes(index)
                      ? "primary.red"
                      : "text.display.light"
                  }
                  mb={2}
                  onClick={() => expandSectionFromTable(index)}
                  _hover={{ color: "primary.red" }}
                  listStyleType="inherit"
                >
                  {section.display_name || `Section ${index + 1}`}
                </Box>
              )
            )}
          </Box>
        </Box>

        {/* Main Content */}
        <Box>
          <Flex flexDirection="column">
            {sop?.value?.sections?.map(
              (section: LegalBriefSection, index: number) => (
                <Box
                  key={index}
                  ref={setSectionRef(index)}
                  mb={6}
                  p={4}
                  bg="background.white"
                  boxShadow="sm"
                  rounded="md"
                  border="1px solid"
                  borderColor="stroke.stroke2"
                  
                >
                  <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => toggleSectionFromHeader(index)}
                  >
                    <IconButton
                      variant="outlineIconButton"
                      aria-label="Toggle section"
                      icon={
                        expandedSections.includes(index) ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        )
                      }
                      size="sm"
                      mr={4}
                      pointerEvents="none"
                    />
                    <Text
                      fontSize="lg"
                      fontWeight={500}
                      color="text.grayPrimary.charcoal"
                    >
                      {section.display_name || `Section ${index + 1}`}
                    </Text>
                  </Flex>
                  {expandedSections.includes(index) && (
                    <Box padding={4}>
                      <RichTextEditor
                        customHeight="auto"
                        text={section.html || section.text || ""}
                        saveText={(html, plainText) =>
                          handleSubmit(html, plainText, index)
                        }
                        editorId={`editor-${index}`}
                      />
                    </Box>
                  )}
                </Box>
              )
            )}
          </Flex>
        </Box>
      </Grid>
    );
  };

  return (
    <SectionWrapper withIndividualEditBox={false} bg="white" backTargetPage="Drafts" onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}>
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <Flex justifyContent="space-between" width="100%">
          <SpecialHeading title="Edit Draft" backNav subTitle="LEGAL BRIEF" />
          <Flex
            justifyContent="end"
            alignItems="center"
            mb={2}
            columnGap={6}
            width="100%"
          >
            {isSuperAdmin && (
              <Flex alignItems="center" gap={2}>
                <Switch
                  isChecked={userAccessEnabled}
                  onChange={(e) => handleSwitchChange(e.target.checked)}
                  colorScheme="blue"
                />
                <Box>Enable user access</Box>
              </Flex>
            )}
            {isDownloadingFullDraft ? (
              <CircularProgress isIndeterminate color="primary.blue" />
            ) : (
              <ExportMenu
                isDownloading={false}
                handleDownload={downloadFullDraft}
                leftIcon={<DownloadIcon />}
                text="Download full draft"
              />
            )}
          </Flex>
        </Flex>

        {renderContent()}
        <Flex justifyContent="right" w="100%" columnGap={4} mt={4}>
          <Button
            variant="primaryFilled"
            onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}
            width="fit-content"
          >
            Back to Drafts
          </Button>
        </Flex>
      </Flex>
    </SectionWrapper>
  );
};
