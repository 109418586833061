import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Grid,
  IconButton,
  Input,
  Text,
  useToast
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { convertFromHtml } from "../../api/draftsApi";
import ExportMenu from "../../components/common/ExportMenu";
import { RichTextEditor } from "../../components/common/RichTextEditor";
import { SectionWrapper } from "../../components/common/SectionWrapper";
import { SpecialHeading } from "../../components/individualTabs/PanelLayout";
import { triggerDownload } from "../../helpers/file_helpers";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { CustomTemplate } from "../../types/drafts.type";
import { DATABASE } from "../../types/tables-data";

const EditTemplate = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [templateName, setTemplateName] = useState<string | undefined>(
    undefined
  );
  const [summaryHtml, setSummaryHtml] = useState<string | undefined>(undefined);
  const [isDownloadingFullDraft, setIsDownloadingFullDraft] =
    useState<boolean>(false);

  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const firstName = useSelector(lawyerSelectors.selectFirstName);
  const lastName = useSelector(lawyerSelectors.selectLastName);
  const toast = useToast();
  const { document, listenAndFetch, updateDocument, loading } =
    useFirestoreDocument<CustomTemplate>(
      DATABASE.CUSTOM_TEMPLATES,
      templateId || "",
      false,
      lawyerId
        ? `${DATABASE.CUSTOM_TEMPLATES}/${lawyerId}/custom_templates`
        : "",
      true
    );

  const [expandedSections, setExpandedSections] = useState<number[]>([]);
  const [highlightedSection, setHighlightedSection] = useState<number | null>(
    null
  );
  const [sections, setSections] = useState(document?.sections || []);
  const [tempSections, setTempSections] = useState(document?.sections || []);
  const [didInitialize, setDidInitialize] = useState<boolean>(false);

  useEffect(() => {
    if (document?.sections) {
      // Set sections for the main view
      setSections(document.sections);

      // Only update tempSections if it hasn't been set already
      setTempSections((prevTempSections: any) => {
        if (prevTempSections.length === 0) {
          return document.sections;
        }
        return prevTempSections; // Keep the current tempSections if already set
      });
    }
  }, [document]);

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  // 1. Fetch once on mount
  useEffect(() => {
    listenAndFetch();
  }, []);

  // 2. Only set local state the first time doc is non-null
  useEffect(() => {
    if (!didInitialize && document) {
      setTemplateName(document.templateName);
      setDidInitialize(true);
    }
  }, [document, didInitialize]);

  const toggleSectionFromHeader = (index: number) => {
    setExpandedSections((prevExpanded) => {
      const isExpanded = prevExpanded.includes(index);
      if (!isExpanded) {
        setHighlightedSection(index);
      } else {
        setHighlightedSection(null);
      }
      return isExpanded
        ? prevExpanded.filter((i) => i !== index)
        : [...prevExpanded, index];
    });
  };

  const expandSectionFromTable = (index: number) => {
    setExpandedSections((prevExpanded) => {
      if (!prevExpanded.includes(index)) {
        return [...prevExpanded, index];
      }
      return prevExpanded;
    });

    requestAnimationFrame(() => {
      sectionRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    });
  };

  const downloadFullDraft = async (format: string) => {
    setIsDownloadingFullDraft(true);
    try {
      const { data, filename } = await convertFromHtml({
        format,
        individualId: "",
        firebasePath: `${DATABASE.CUSTOM_TEMPLATES}/${lawyerId}/custom_templates/${templateId}`,
        documentField: "",
        htmlField: "sections_after_review_html"
      });
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
    } finally {
      setIsDownloadingFullDraft(false);
    }
  };
  const setSectionRef = useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      sectionRefs.current[index] = el;
    },
    []
  );

  const updateSection = async (
    html: string,
    plainText: string,
    sectionIndex: number
  ) => {
    setTempSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex] = {
        ...updatedSections[sectionIndex],
        sections_after_review: html,
        text: plainText
      };
      console.log("TempSections Updated:", updatedSections);
      return updatedSections;
    });
  };

  const handleSave = async (
    html: string,
    plainText: string,
    sectionIndex: number
  ) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      sections_after_review_html: html,
      sections_after_review: plainText
    };
    setSections(updatedSections);
    await updateDocument({ ...document, sections: updatedSections });
  };

  const handleSaveAll = async () => {
    // debouncedUpdateSection.cancel(); // Cancel pending updates
    await updateDocument({
      ...document,
      templateName,
      sections: tempSections
    }); // Use the latest `sections` state
    navigate("/studio/templates");
  };

  // useEffect(() => {
  //   return () => {
  //     debouncedUpdateSection.cancel();
  //   };
  // }, [debouncedUpdateSection]);

  const renderContent = () => {
    if (loading) {
      return (
        <Flex justifyContent="center">
          <CircularProgress isIndeterminate color="primary.blue" />
        </Flex>
      );
    }

    if (!document?.sections?.length) {
      return (
        <Box fontSize="18px" textAlign="center">
          No sections available. Add sections to your template.
        </Box>
      );
    }

    return (
      <Grid templateColumns="14vw 1fr" gap={6} width="100%">
        {/* Sidebar Navigation */}
        <Box
          bg="background.cloud"
          p={4}
          rounded="md"
          position="sticky"
          top="20px"
          height="fit-content"
        >
          <Text fontWeight="bold" mb={4} color="primary.blue">
            Contents
          </Text>
          <Box as="ul" listStyleType="disc" pl={4}>
            {sections.map((section, index) => (
              <Box
                as="li"
                key={index}
                cursor="pointer"
                color={
                  highlightedSection === index &&
                  expandedSections.includes(index)
                    ? "primary.red"
                    : "text.display.light"
                }
                mb={2}
                onClick={() => expandSectionFromTable(index)}
                _hover={{ color: "primary.red" }}
              >
                {section.section_name || `Section ${index + 1}`}
              </Box>
            ))}
          </Box>
        </Box>

        {/* Main Content */}
        <Box>
          <Flex flexDirection="column">
            {document?.sections.map((section, index) => (
              <Box
                key={index}
                ref={setSectionRef(index)}
                mb={6}
                p={4}
                bg="background.white"
                boxShadow="sm"
                rounded="md"
                border="1px solid"
                borderColor="stroke.stroke2"
              >
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => toggleSectionFromHeader(index)}
                >
                  <IconButton
                    variant="outlineIconButton"
                    aria-label="Toggle section"
                    icon={
                      expandedSections.includes(index) ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )
                    }
                    size="sm"
                    mr={4}
                  />
                  <Text fontSize="lg" fontWeight={500}>
                    {section.section_name || `Section ${index + 1}`}
                  </Text>
                </Flex>
                {expandedSections.includes(index) && (
                  <RichTextEditor
                    key={index}
                    text={
                      sections[index].sections_after_review_html ||
                      sections[index].sections_after_review ||
                      ""
                    }
                    saveText={(html, plainText) =>
                      handleSave(html, plainText, index)
                    }
                    updateText={(html, plainText) =>
                      updateSection(html, plainText || "", index)
                    }
                    editorId={`editor-${index}`}
                  />
                )}
              </Box>
            ))}
          </Flex>
        </Box>
      </Grid>
    );
  };

  return (
    <SectionWrapper backTargetPage="Templates" bg="background.white">
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <Flex justifyContent="space-between" width="100%">
          <SpecialHeading title="Edit Template" topTitle="CaseBlink Studio" />
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {isDownloadingFullDraft ? (
              <CircularProgress isIndeterminate color="primary.blue" />
            ) : (
              <ExportMenu
                isDownloading={false}
                handleDownload={downloadFullDraft}
                leftIcon={<DownloadIcon />}
                text="Download full draft"
              />
            )}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          flexDir="column"
          w="full"
          h="full"
          rowGap={4}
          mt={4}
        >
          <Box mb={2} justifyContent="start" w="fit-content">
            <Text fontSize="sm" fontWeight="bold" mb={2}>
              Template Name
            </Text>
            <Input
              // variant="filled"
              value={templateName || ""}
              onChange={(e) => setTemplateName(e.target.value)}
              placeholder="Enter template name"
              size="md"
            />
          </Box>

          {renderContent()}
        </Flex>

        <Flex justifyContent="end" w="100%" mt={4} gap={4}>
          <Button
            onClick={handleSaveAll}
            variant="primaryFilled"
            isLoading={loading}
          >
            Save and Go to Templates
          </Button>
        </Flex>
      </Flex>
    </SectionWrapper>
  );
};

export default EditTemplate;
