import { visaTypeToPath, VISAVALUE } from "../types/tables-data";

const getShortString = (arg: string | undefined, limit = 20) => {
  if (arg) {
    if (arg.length > 20) return arg.substring(0, limit).concat("...");
    return arg;
  }
  return "";
};

// Helper Functions
export const getNextDocumentName = (
  documents: { templateName?: string }[]
): string => {
  // filter only "Untitled template..."
  const names = documents
    .map((doc) => doc.templateName)
    .filter((name) => name?.startsWith("Untitled template"));

  // extract the number from each name, default to 0 if we cannot parse
  const maxIndex = names
    .map((name) => {
      const match = name?.match(/^Untitled template\s+(\d+)/);
      if (!match) {
        return 0;
      }
      return parseInt(match[1], 10) || 0;
    })
    .reduce((max, num) => Math.max(max, num), 0);

  // increment by 1
  return `Untitled template ${maxIndex + 1}`;
};


const isValidVisaKey = (
  visa: any
): visa is Exclude<VISAVALUE, VISAVALUE.EMPTY> => {
  return visa in visaTypeToPath;
};

export { getShortString, isValidVisaKey };
