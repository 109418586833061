import { Tr } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";

export const SubGroupDiv = ({ metadata, id, children }: any) => {
  const { setNodeRef, isDragging, transform, transition, attributes } =
    useSortable({
      id,
      data: { metadata }
    });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };
  return (
    <Tr key={id} style={style} ref={setNodeRef} {...attributes}>
      {children}
    </Tr>
  );
};

export const GroupedDiv = ({ id, children, metadata }: any) => {
  const { setNodeRef, isDragging, transform, transition } = useSortable({
    id,
    data: { metadata }
  });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 1 : 0,
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };
  return (
    <Tr key={id} style={style} ref={setNodeRef}>
      {children}
    </Tr>
  );
};
