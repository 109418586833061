import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Skeleton
} from "@chakra-ui/react";
import { FieldAttributes, FieldConfig, useField } from "formik";

interface Props extends FieldConfig, FieldAttributes<any> {
  label: string;
  isRequired?: boolean;
  isLoaded?: boolean;
  withInfo?: React.ReactNode;
  placeholder?: string;
}

export const InputField = ({
  label,
  isRequired = false,
  isLoaded,
  withInfo,
  placeholder,
  ...props
}: Props) => {
  const [field, meta] = useField(props.name);

  return (
    <FormControl
      variant="fixed"
      isRequired={isRequired}
      isInvalid={meta.touched && !!meta.error}
      className="whitespace-nowrap"
    >
      <FormLabel className="inline-flex">
        {label} {withInfo}
      </FormLabel>
      <Skeleton isLoaded={!isLoaded}>
        <Input {...field} type={props?.type} placeholder={placeholder} />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </Skeleton>
    </FormControl>
  );
};
