import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  ChakraProps,
  Tag,
  TagLabel,
  Flex,
  Box,
  Spacer
} from "@chakra-ui/react";
import { FieldConfig, useField } from "formik";
import { Select } from "chakra-react-select";
import { VISAVALUE } from "../types/tables-data";

interface Props extends FieldConfig, ChakraProps {
  label: string;
  placeHolder: string;
  options: Record<Exclude<VISAVALUE, VISAVALUE.EMPTY>, string> | string[];
  isRequired?: boolean;
  enableSearch?: boolean;
  formikHelpers?: any;
  variant?: string;
}

export const SelectField = ({
  label,
  options,
  placeHolder,
  isRequired = false,
  enableSearch = false,
  formikHelpers,
  variant,
  ...props
}: Props) => {
  const [field, meta, helpers] = formikHelpers ?? useField(props.name);
  const { setValue } = helpers;

  // Type guard to check if options is a Record<VISAVALUE, string>
  const isRecord = (
    options: Props["options"]
  ): options is Record<Exclude<VISAVALUE, VISAVALUE.EMPTY>, string> =>
    !Array.isArray(options);

  // Convert options to a format compatible with chakra-react-select
  const chakraSelectOptions = isRecord(options)
    ? Object.entries(options).map(([key, value]) => ({
        label: (
          <Flex alignItems="center" justifyContent="space-between" gap={2}>
            <Box>{key}</Box>
            <Spacer />
            {options[key as Exclude<VISAVALUE, VISAVALUE.EMPTY>] !== "" && (
              <Tag
                size="sm"
                variant="outline"
                colorScheme="blue"
                borderRadius="md"
                px={3}
                py={1}
                fontWeight="bold"
                fontSize="xs"
              >
                <TagLabel>
                  {options[key as Exclude<VISAVALUE, VISAVALUE.EMPTY>]}
                </TagLabel>
              </Tag>
            )}
          </Flex>
        ),
        value: key
      }))
    : options.map((option) => ({
        label: option,
        value: option
      }));

  const handleChange = (selectedOption: any) => {
    setValue(selectedOption?.value || ""); // Update Formik field value
  };

  return (
    <FormControl
      variant="fixed"
      {...(props as ChakraProps)}
      isRequired={isRequired}
      isInvalid={meta.touched && !!meta.error}
      w="max"
    >
      <FormLabel>{label}</FormLabel>
      <Select
        variant={variant}
        options={chakraSelectOptions}
        placeholder={placeHolder}
        value={
          (chakraSelectOptions as any).find(
            (option: any) => option.value === field.value
          ) || null
        }
        onChange={handleChange}
        isSearchable={enableSearch}
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: "md",
            width: "fit-content",
            minWidth: "250px",
            maxWidth: "400px"
          }),
          menu: (provided) => ({
            ...provided,
            bg: "transparent",
            maxWidth: "400px"
          }),
          input: (provided) => ({
            ...provided,
            bg: "transparent !important"
          })
        }}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
