import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Select,
  Switch
} from "@chakra-ui/react";
import {
  closestCenter,
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { Field, FieldArray, Formik } from "formik";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { GiConfirmed } from "react-icons/gi";
import { useParams } from "react-router-dom";
import { RowDragHandleCell } from "../components/common/DraggableRow";
import { InputField } from "../forms/InputField";
import { Question } from "../hooks/useVisaQuestions";

const SortableItem = ({
  id,
  children
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    width: "100%"
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes}>
      {children}
    </Box>
  );
};

const DroppableZone = ({
  id,
  children
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const { active, isOver, setNodeRef, node, over, rect, listeners, transform } =
    useSortable({
      id,
      data: {
        type: "section-zone"
      }
    });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    borderColor: active ? "blue" : "gray",
    backgroundColor: "white",
    width: "100%",
    border: "1px solid black",
    height: "auto"
  };

  return (
    <Box ref={setNodeRef} style={style}>
      {children}
    </Box>
  );
};

export const VisaQuestionnaireBuilder = () => {
  const { visa } = useParams();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [sections, setSections] = useState<Record<string, Question[]>>({});
  const db = getFirestore();

  useEffect(() => {
    const fetchQuestions = async () => {
      const questionsRef = collection(db, `visa_questions/${visa}/questions`);
      const q = query(questionsRef, orderBy("index")); // Sort by index

      const querySnapshot = await getDocs(q);
      const fetchedQuestions: Question[] = querySnapshot.docs.map<Question>(
        (doc) => ({
          ...(doc.data() as Question),
          id: doc.id
        })
      );
      const sectionsTemp: Record<string, Question[]> = {};

      fetchedQuestions.forEach((question) => {
        const { section } = question;
        const sectionDefault =
          !section || section === "" ? "Unassigned" : section;
        if (!sectionDefault) return;
        if (!sectionsTemp[sectionDefault]) {
          sectionsTemp[sectionDefault] = [question];
        } else {
          sectionsTemp[sectionDefault].push(question);
        }
      });
      setSections(sectionsTemp);
    };

    fetchQuestions();
  }, []);

  const handleUpdateOrder = async (): Promise<void> => {
    const batch = writeBatch(db);

    const allQuestions = Object.values(sections).flatMap((val) => val);

    try {
      for (const [index, question] of allQuestions.entries()) {
        const questionRef = doc(
          db,
          `visa_questions/${visa}/questions/${question.id}`
        );
        // eslint-disable-next-line object-shorthand
        batch.update(questionRef, { index: index });
      }

      await batch.commit();
    } catch (error) {
      alert("error when updating the order");
    }
  };

  const handleSectionRename = async (
    oldName: string,
    newName: string
  ): Promise<void> => {
    if (oldName === newName) return;

    const batch = writeBatch(db);
    const updatedSections = { ...sections };

    updatedSections[newName] = updatedSections[oldName] || [];
    delete updatedSections[oldName];

    updatedSections[newName].forEach((question) => {
      const questionRef = doc(
        db,
        `visa_questions/${visa}/questions/${question.id}`
      );
      batch.update(questionRef, { section: newName });
    });

    try {
      await batch.commit();
      setSections(updatedSections);
    } catch (error) {
      console.error("Error renaming section:", error);
    }
  };
  const [activeId, setActiveId] = useState(null);
  // Add a new question
  const addQuestion = async (section?: string) => {
    const newQuestion: Question = {
      id: "", // Firestore will generate this
      fieldType: "text",
      index: questions.length + 1,
      isRequired: false,
      questionText: `question_${questions.length + 1}`,
      variableName: `question_${questions.length + 1}`,
      description: "Add a description here",
      options: [],
      tooltipText: "",
      section: section === "Unassigned" ? "" : section,
      subVariableName: null,
      subIndex: null,
      subOptions: null,
      subQuestions: null
    };

    try {
      const questionsRef = collection(db, `visa_questions/${visa}/questions`);
      const docRef = await addDoc(questionsRef, newQuestion);
      const questionWithId = { ...newQuestion, id: docRef.id };
      if (!sections.Unassigned) {
        setSections((sections) => ({
          ...sections,
          Unassigned: [questionWithId]
        }));
      } else if (newQuestion.section) {
        setSections((sections) => ({
          ...sections,
          [newQuestion.section!]: [
            ...sections[newQuestion.section!],
            questionWithId
          ]
        }));
        setQuestions([...questions, questionWithId]);
      } else {
        setSections((sections) => ({
          ...sections,
          Unassigned: [...sections.Unassigned, questionWithId]
        }));
      }
      setQuestions([...questions, questionWithId]);
    } catch (error) {
      console.error("Error adding question: ", error);
    }
  };
  const handleDragStart = (event: any) => {
    const { active } = event;

    setActiveId(active.id);
  };
  const addSection = () => {
    setSections((sections) => ({
      ...sections,
      [`Section${Object.keys(sections).length + 1}`]: []
    }));
  };

  // Delete a question
  const deleteQuestion = async (questionId: string, sectionName: string) => {
    try {
      const updatedQuestions = sections[sectionName].filter(
        (question) => question.id !== questionId
      );
      const questionRef = doc(
        db,
        `visa_questions/${visa}/questions/${questionId}`
      );
      await deleteDoc(questionRef);

      setSections((sections) => ({
        ...sections,
        [sectionName]: updatedQuestions
      }));
      // setQuestions(questions.filter((_, i) => _.id !== questionId));
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  // Save changes for a single question
  const saveQuestion = async (updatedQuestion: Question) => {
    try {
      const questionRef = doc(
        db,
        `visa_questions/${visa}/questions/${updatedQuestion.id}`
      );
      await updateDoc(questionRef, { ...updatedQuestion });
      console.log(`Question ${updatedQuestion.id} saved successfully!`);
    } catch (error) {
      console.error("Error saving question:", error);
    }
  };

  // Handle drag-and-drop sorting
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  function moveKeyBefore(
    obj: Record<string, any>,
    keyToMove: string,
    beforeKey: string
  ): Record<string, any> {
    const entries = Object.entries(obj);
    const result: [string, any][] = [];

    for (const [key, value] of entries) {
      if (key === beforeKey) {
        if (keyToMove in obj) {
          result.push([keyToMove, obj[keyToMove]]);
        }
      }
      if (key !== keyToMove) {
        result.push([key, value]);
      }
    }

    return Object.fromEntries(result);
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    // shuffling sections
    if (
      active.data.current &&
      over.data.current &&
      over.data.current.type === "section-zone" &&
      active.data.current.type === "section-zone"
    ) {
      const activeIndex = Object.keys(sections).indexOf(activeId.toString());
      const overIndex = Object.keys(sections).indexOf(overId.toString());

      const copyOfSections = { ...sections };
      if (activeIndex < overIndex) {
        const updatedSections = moveKeyBefore(
          copyOfSections,
          overId.toString(),
          activeId.toString()
        );
        setSections(updatedSections);
      } else {
        const updatedSections = moveKeyBefore(
          copyOfSections,
          activeId.toString(),
          overId.toString()
        );
        setSections(updatedSections);
      }
    }

    // If shuffling questions within a section
    if (activeId !== overId) {
      const sectionName = Object.keys(sections).find((section) =>
        sections[section].some((q) => q.id === activeId)
      );

      if (sectionName) {
        const updatedQuestions = arrayMove(
          sections[sectionName],
          sections[sectionName].findIndex((q) => q.id === activeId),
          sections[sectionName].findIndex((q) => q.id === overId)
        );

        setSections((prevSections) => ({
          ...prevSections,
          [sectionName]: updatedQuestions
        }));
      }
    }
  };

  useEffect(() => {
    handleUpdateOrder();
  }, [sections]);

  return (
    <div>
      <h1>Edit Questions for Visa: {visa}</h1>
      <Button onClick={() => addQuestion()} colorScheme="green" mb={4}>
        Add Question
      </Button>
      <Button onClick={addSection} colorScheme="green" mb={4}>
        Add Section
      </Button>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext
          items={[...Object.keys(sections)]}
          strategy={verticalListSortingStrategy}
        >
          {/* Sections */}
          {Object.entries(sections).map(([sectionName, questions]) => (
            <DroppableZone key={sectionName} id={sectionName}>
              <div className="flex">
                <RowDragHandleCell rowId={sectionName} />
                <Input
                  defaultValue={sectionName}
                  readOnly={sectionName === "Unassigned"}
                  onBlur={(e) =>
                    handleSectionRename(sectionName, e.target.value)
                  }
                />
                <IconButton icon={<GiConfirmed />} aria-label="" />
              </div>
              <SortableContext
                items={questions.map((q) => q.id)}
                strategy={verticalListSortingStrategy}
              >
                <Accordion allowMultiple>
                  {questions.map((question, index) => (
                    <SortableItem key={question.id} id={question.id}>
                      <div className="flex w-full flex-grow pl-4">
                        <RowDragHandleCell rowId={question.id} />
                        <AccordionItem className="bg-white my-2 flex-1">
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                {question.questionText ||
                                  `Question ${index + 1}`}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            pb={4}
                            className="flex flex-col gap-4"
                          >
                            <Formik
                              initialValues={question}
                              enableReinitialize // Ensure form updates with the latest question data
                              onSubmit={(values) => saveQuestion(values)}
                            >
                              {({ values, handleSubmit, handleChange }) => (
                                <form onSubmit={handleSubmit}>
                                  <InputField
                                    label="Question Label"
                                    name="questionText"
                                    as={Input}
                                    placeholder="Question Text"
                                    mb={3}
                                  />
                                  <InputField
                                    label="ToolTip Text"
                                    name="tooltipText"
                                    as={Input}
                                    placeholder="ToolTip Text"
                                    mb={3}
                                  />
                                  <InputField
                                    label="Variable Name"
                                    name="variableName"
                                    as={Input}
                                    placeholder="Variable Name"
                                    mb={3}
                                    readOnly
                                  />
                                  <InputField
                                    label="Description"
                                    name="description"
                                    as={Input}
                                    placeholder="Description"
                                    mb={3}
                                  />
                                  <FormLabel htmlFor="fieldType">
                                    Field Type
                                  </FormLabel>
                                  <Field as={Select} name="fieldType" mb={3}>
                                    <option value="text">Text</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="radio">Radio</option>
                                    <option value="textarea">Textarea</option>
                                    <option value="select">Select</option>
                                    <option value="period">Period</option>
                                  </Field>
                                  {values.fieldType === "radio" ||
                                  values.fieldType === "checkbox" ||
                                  values.fieldType === "select" ? (
                                    <FieldArray
                                      name="options"
                                      render={(optionHelpers) => (
                                        <div>
                                          <h4
                                            style={{
                                              textAlign: "left",
                                              marginBottom: "8px"
                                            }}
                                          >
                                            Options:
                                          </h4>
                                          {values.options?.map(
                                            (option, optIndex) => (
                                              <Box
                                                key={optIndex}
                                                display="flex"
                                                alignItems="center"
                                                mb={2}
                                              >
                                                <Field
                                                  name={`options[${optIndex}]`}
                                                  as={Input}
                                                  placeholder="Option"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    optionHelpers.remove(
                                                      optIndex
                                                    )
                                                  }
                                                  ml={2}
                                                  colorScheme="red"
                                                >
                                                  Delete
                                                </Button>
                                              </Box>
                                            )
                                          )}
                                          <Button
                                            onClick={() =>
                                              optionHelpers.push("New Option")
                                            }
                                            colorScheme="blue"
                                            mt={2}
                                          >
                                            Add Option
                                          </Button>
                                        </div>
                                      )}
                                    />
                                  ) : null}
                                  <FormControl
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <FormLabel htmlFor="email-alerts" mb="0">
                                      Required Field
                                    </FormLabel>
                                    <Switch
                                      id="isRequired"
                                      name="isRequired"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                  <div className="flex gap-2 justify-end">
                                    <Button
                                      variant="destructiveFilled"
                                      onClick={() =>
                                        deleteQuestion(question.id, sectionName)
                                      }
                                      mt={4}
                                    >
                                      Delete Question
                                    </Button>
                                    <Button
                                      type="submit"
                                      colorScheme="blue"
                                      mt={4}
                                    >
                                      Save Question
                                    </Button>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </AccordionPanel>
                        </AccordionItem>
                      </div>
                    </SortableItem>
                  ))}
                </Accordion>
                <Button
                  onClick={() => addQuestion(sectionName)}
                  colorScheme="green"
                  mb={4}
                >
                  Add Question
                </Button>
              </SortableContext>
            </DroppableZone>
          ))}
        </SortableContext>
        {/* {JSON.stringify(sections, null, 2)} */}
        {/* <DragOverlay>
          {activeId ? (
            <div className="flex !w-32 h-8 flex-grow">
              <RowDragHandleCell rowId={activeId} />
              <Box bg="white" p={4} w="100%">
                {activeQuestion?.questionText}
              </Box>
            </div>
          ) : null}
        </DragOverlay> */}
      </DndContext>
    </div>
  );
};
