import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { RichTextEditor } from "../components/common/RichTextEditor";
import DocumentInformation from "../components/individualTabs/individualDocuments/DocumentInformation";
import EditMainDocModal from "../components/individualTabs/individualDocuments/EditMainDocModal";
import { updateDocument } from "../redux/documents/documentSlice";
import { DataDocs } from "../types/tables-data";
import DocumentPreviewCard from "./DocumentPreviewCard";

type Props = {
  exhibit: string;
  document: DataDocs;
};

export const DocumentDetailsSection: React.FC<Props> = ({
  exhibit,
  document
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { super_class, documentType, docRef } = document;
  const isEvidence = docRef?.split("/").includes("evidence_docs");

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const panelRefs = useRef<
    Record<string, HTMLButtonElement | HTMLDivElement | null>
  >({});

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  const handleAccordionToggle = (panelKey: string, isOpen: boolean) => {
    if (!isOpen && panelRefs.current[panelKey]) {
      _.debounce(() => {
        panelRefs.current[panelKey]?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }, 200)();
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();

  useEffect(() => {
    // implement the params logic here
    if (
      document &&
      !searchParams.has("category") &&
      !searchParams.has("docId")
    ) {
      newQueryParameters.set("category", document.criterion!);
      newQueryParameters.set("docId", document.id!);
      setSearchParams(newQueryParameters);
    }
  }, []);

  return (
    <Box className="h-full">
      <Heading size="md" mb={4}>
        Edit details
      </Heading>
      {document ? (
        <Box key={document.uid} className=" rounded-md my-2 h-full">
          <Text fontWeight="500" textAlign="left" fontSize="14" mb={2}>
            EXHIBIT {exhibit}
          </Text>
          <Accordion defaultIndex={[2]} allowMultiple gap={2}>
            {/* Document basic information */}

            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    ref={(el) => {
                      panelRefs.current.documentInfo = el;
                    }}
                    onClick={() => {
                      handleAccordionToggle("documentInfo", isExpanded);
                    }}
                  >
                    Document information
                    <Spacer /> <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <DocumentInformation document={document} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* Additional information */}
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => {
                      handleAccordionToggle("additionalInfo", isExpanded);
                    }}
                  >
                    Additional information
                    <Spacer /> <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    ref={(el) => {
                      panelRefs.current.additionalInfo = el;
                    }}
                  >
                    <DocumentPreviewCard document={document} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* AI generated Summary */}
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => {
                      handleAccordionToggle("summary", isExpanded);
                    }}
                  >
                    AI-generated Summary
                    <Spacer /> <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    ref={(el) => {
                      panelRefs.current.summary = el;
                    }}
                  >
                    <Box className="h-full">
                      <RichTextEditor
                        customHeight="auto"
                        saveText={async (html, text) => {
                          if (!document.docRef) return;

                          setIsSaving(true);
                          const docRef = doc(db, document?.docRef || "");
                          await updateDoc(docRef, {
                            summary: text,
                            summaryHtml: html
                          });
                          dispatch(
                            updateDocument({
                              ...document,
                              summary: text,
                              summaryHtml: html
                            })
                          );

                          setIsSaving(false);
                        }}
                        text={document?.summaryHtml || document?.summary || ""}
                      />
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>

          {documentToEdit !== undefined && (
            <EditMainDocModal
              isDeleting={false}
              document={documentToEdit}
              headerText="Edit Main Document"
              isOpen={isEditOpen}
              onClose={onEditClose}
            />
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
