import {
  Box,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Flex,
  Heading
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { TextFromApiBlock } from "../components/textFromApiBlock";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { useBriefBlurbs } from "../hooks/useBriefBlurbs";
import { useGetClientDocuments } from "../hooks/useGetClientDocuments";
import { useProngArguments } from "../hooks/useProngArguments";
import { clientsSelectors } from "../redux/clients/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { clickedStyles } from "../styles/common-styles";
import { DataDocs } from "../types/tables-data";

export const ExtractedInfoPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    extractedData,
    onSaveSummaryPoints,
    onSaveProngArguments,
    isLoadingSaveProngArguments,
    isLoadingSaveSummaryPoints
  } = useAddExtractInfo();

  const [showSidebar, setShowSidebar] = useState(false);

  const { onSubmitGetClientDocs, dbDocuments } = useGetClientDocuments();

  const {
    onSubmitProngArguments,
    apiResponseProngArguments,
    isLoadingProngArguments
  } = useProngArguments();

  const { onSubmitBriefBlurbs, apiResponseBriefBlurbs, isLoadingBriefBlurbs } =
    useBriefBlurbs();

  const [docsData, setDocsData] = useState<DataDocs[] | null>(null);
  const [summaryPointsResults, setSummaryPointsResults] = useState<
    string | null
  >(null);
  const [briefBlurbsArgs, setBriefBlurbsArgs] = useState<string | null>(null);

  useEffect(() => {
    if (params.caseID) {
      onSubmitGetClientDocs();
    }
  }, [params.caseID]);

  useEffect(() => {
    if (dbDocuments) {
      const docs = dbDocuments.map((data) => data.documents);
      setDocsData(docs);
    }
  }, [dbDocuments]);

  const allClients = useSelector(clientsSelectors.selectAll);
  const indexClient = allClients.findIndex(
    (client) => client.id === params.caseID
  );

  const { visa } = useSelector(clientsSelectors.selectAll)[indexClient];

  useEffect(() => {
    if (apiResponseProngArguments) {
      onSubmitBriefBlurbs(visa, apiResponseProngArguments);
    }
  }, [apiResponseProngArguments]);

  useEffect(() => {
    if (apiResponseBriefBlurbs) {
      setBriefBlurbsArgs(apiResponseBriefBlurbs);
    }
  }, [apiResponseBriefBlurbs]);

  const lawyerFirstName = useSelector(lawyerSelectors.selectFirstName);
  const lawyerLastName = useSelector(lawyerSelectors.selectLastName);

  const handleSaveSummaryPoints = async () => {
    if (summaryPointsResults) {
      await onSaveSummaryPoints({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        summaryPoints: summaryPointsResults,
        summaryPointsHtml: ""
      });
    }
  };
  const handleDeleteSummaryPoints = async () => {
    await onSaveSummaryPoints({
      firstName: lawyerFirstName,
      lastName: lawyerLastName,
      summaryPoints: "",
      summaryPointsHtml: ""
    });
  };
  const handleSaveProngArguments = async () => {
    if (briefBlurbsArgs) {
      await onSaveProngArguments({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        prongArguments: briefBlurbsArgs
      });
    }
  };

  const handleDeleteProngArguments = async () => {
    await onSaveProngArguments({
      firstName: lawyerFirstName,
      lastName: lawyerLastName,
      prongArguments: ""
    });
  };

  const loadingProngArguments = isLoadingProngArguments || isLoadingBriefBlurbs;

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Heading size="md">A.I. extracted information</Heading>
            </CardHeader>
            {/* <CardBody width="50%">
              {extractedData?.data.passport && (
                <>
                  <Box fontWeight="700" mb="30px" textAlign="left">
                    Passport extracted information
                  </Box>
                  <FormControl my="20px" variant="floating" id="firstName">
                    <Input
                      placeholder=" "
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>First name</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="lastName">
                    <Input
                      placeholder=" "
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Last name</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="passportNumber">
                    <Input
                      placeholder=" "
                      name="passportNumber"
                      value={formik.values.passportNumber}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Passport number</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="dateOfIssue">
                    <Input
                      placeholder=" "
                      name="dateOfIssue"
                      type="date"
                      min="1900-01-01"
                      max={maxInputDateValue}
                      value={formik.values.dateOfIssue}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Passport issuing date</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="expirationDate">
                    <Input
                      placeholder=" "
                      name="expirationDate"
                      type="date"
                      min="1900-01-01"
                      max="2100-01-01"
                      value={formik.values.expirationDate}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Passport expiry date</FormLabel>
                  </FormControl>

                  <FormControl
                    my="20px"
                    variant="floating"
                    id="passportCountry"
                  >
                    <Input
                      placeholder=" "
                      name="passportCountry"
                      value={formik.values.passportCountry}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Passport country</FormLabel>
                  </FormControl>
                </>
              )}
              {extractedData?.data.resume && (
                <>
                  <Box fontWeight="700" mb="30px" textAlign="left">
                    Resume extracted information
                  </Box>
                  <FormControl my="20px" variant="floating" id="email">
                    <Input
                      placeholder=" "
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Email address</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="lastrole">
                    <Input
                      placeholder=" "
                      name="lastrole"
                      value={formik.values.lastRole}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Last Role</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="lastemployer">
                    <Input
                      placeholder=" "
                      name="lastemployer"
                      value={formik.values.lastEmployer}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Last Employer</FormLabel>
                  </FormControl>

                  <FormControl
                    my="20px"
                    variant="floating"
                    id="lastrolestartdate"
                  >
                    <Input
                      placeholder=" "
                      name="lastrolestartdate"
                      value={formik.values.lastRoleStartDate}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Last Role Start Date</FormLabel>
                  </FormControl>

                  <FormControl
                    my="20px"
                    variant="floating"
                    id="lastroleenddate"
                  >
                    <Input
                      placeholder=" "
                      name="lastroleenddate"
                      value={formik.values.lastRoleEndDate}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Last Role End Date</FormLabel>
                  </FormControl>
                </>
              )}
              {extractedData?.data.i94 && (
                <>
                  <Box fontWeight="700" mb="30px" textAlign="left">
                    I-94 extracted information
                  </Box>
                  <FormControl
                    my="20px"
                    variant="floating"
                    id="i94RecordNumber"
                  >
                    <Input
                      placeholder=" "
                      name="i94RecordNumber"
                      value={formik.values.i94RecordNumber}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>I-94 number</FormLabel>
                  </FormControl>

                  <FormControl
                    my="20px"
                    variant="floating"
                    id="mostRecentDateOfEntry"
                  >
                    <Input
                      placeholder=" "
                      name="mostRecentDateOfEntry"
                      value={formik.values.mostRecentDateOfEntry}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Most recent date of entry</FormLabel>
                  </FormControl>
                </>
              )}
              {extractedData?.data.visa && (
                <>
                  <Box fontWeight="700" mb="30px" textAlign="left">
                    US Visa information
                  </Box>
                  <FormControl my="20px" variant="floating" id="visaNumber">
                    <Input
                      placeholder=" "
                      name="visaNumber"
                      value={formik.values.visaNumber}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Visa number</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="visaType">
                    <Input
                      placeholder=" "
                      name="visaType"
                      value={formik.values.visaType}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Visa type</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="expirationDate">
                    <Input
                      placeholder=" "
                      name="expirationDate"
                      value={formik.values.visaExpirationDate}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Visa expiry date</FormLabel>
                  </FormControl>
                </>
              )}
            </CardBody> */}
            {!extractedData?.summaryPoints && (
              <Box>Resume summary points are loading...</Box>
            )}
            {extractedData?.summaryPoints && (
              <>
                <TextFromApiBlock
                  text={extractedData.summaryPoints}
                  title="Resume summary points"
                />
                <Button
                  bg="bgColor.300"
                  color="text.100"
                  mx="15px"
                  type="button"
                  onClick={handleDeleteSummaryPoints}
                  isLoading={isLoadingSaveSummaryPoints}
                >
                  Delete
                </Button>
              </>
            )}
            {summaryPointsResults && !extractedData?.summaryPoints && (
              <>
                <TextFromApiBlock
                  text={summaryPointsResults}
                  title="Resume summary points"
                />
                <Button
                  bg="bgColor.300"
                  color="text.100"
                  mx="15px"
                  type="button"
                  onClick={handleSaveSummaryPoints}
                  isLoading={isLoadingSaveSummaryPoints}
                >
                  Save
                </Button>
              </>
            )}
            {loadingProngArguments && !extractedData?.prongArguments && (
              <Box my="15px">Arguments are loading...</Box>
            )}
            {extractedData?.prongArguments && (
              <>
                <TextFromApiBlock
                  text={extractedData.prongArguments}
                  title="Arguments"
                />
                <Button
                  bg="bgColor.300"
                  color="text.100"
                  mx="15px"
                  type="button"
                  onClick={handleDeleteProngArguments}
                  isLoading={isLoadingSaveProngArguments}
                >
                  Delete
                </Button>
              </>
            )}
            {briefBlurbsArgs && !extractedData?.prongArguments && (
              <>
                <TextFromApiBlock text={briefBlurbsArgs} title="Arguments" />
                <Button
                  bg="bgColor.300"
                  color="text.100"
                  mx="15px"
                  type="button"
                  onClick={handleSaveProngArguments}
                  isLoading={isLoadingSaveProngArguments}
                >
                  Save
                </Button>
              </>
            )}
            <CardFooter my="25px" w="100%" justifyContent="flex-end">
              <Button
                bg="bgColor.300"
                color="text.100"
                type="button"
                onClick={() => navigate(`/cases/${params.caseID}/packet`)}
                mx="15px"
              >
                Back
              </Button>
            </CardFooter>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
