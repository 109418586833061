import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import images from "../assets/image-export-assets";
import { getSubdomain } from "../helpers/helpers";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { firmConfiguration } from "../constans";

export const NavBarLogo = () => {
  const lawyer = useSelector(lawyerSelectors.selectAll);
  const subdomain = getSubdomain();
  const isCaseblink = !Object.keys(firmConfiguration).includes(subdomain);
  return (
    <>
      {isCaseblink && <Image src={images.newCaseBlinkLogo} height="40px" />}
      {!isCaseblink && lawyer.lawFirmLogo && (
        <Image src={lawyer.lawFirmLogo} height="40px" />
      )}
    </>
  );
};
