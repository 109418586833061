import { defineStyleConfig } from "@chakra-ui/react";

// define the base component styles
const baseStyle = {
  // bg: "white",
  textAlign: "left",
  borderRadius: "md",
};

// export the component theme
export const containerTheme = defineStyleConfig({ baseStyle });
