import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Textarea
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import images from "../../assets/image-export-assets";
import { documentSelectors } from "../../redux/documents/selectors";
import { ToolbarDropdown } from "./ToolbarDropdown";

type AIToolbarProps = {
  showToolbar: boolean;
  changingText: boolean;
  quillRef: any;
  setSelectedText: (value: React.SetStateAction<string | undefined>) => void;
  handleAIButtonClick: (
    rewriteOption?: string,
    toneOption?: string,
    customPrompt?: string,
    evidencesToHighlight?: string[]
  ) => Promise<void>;
  quillToolbarHeight: any;
  selectedRange: any;
  bounds: any;
  setAllowOverflow: React.Dispatch<React.SetStateAction<boolean>>;
  setPromptisSelected: React.Dispatch<React.SetStateAction<boolean | null>>;
  evidencesToHighlight: string[];
  setEvidencesToHighlight: React.Dispatch<React.SetStateAction<string[]>>;
};
const toneOptions = [
  "Formal",
  "Persuasive",
  "Concise",
  "Objective",
  "Educational"
];
const rewriteOptions = ["Make Shorter", "Make Longer", "Paraphrase"];

const MIN_HEIGHT = 200;

export const AIToolbar = ({
  quillToolbarHeight,
  showToolbar,
  changingText,
  quillRef,
  handleAIButtonClick,
  setSelectedText,
  selectedRange,
  bounds,
  setAllowOverflow,
  setPromptisSelected,
  evidencesToHighlight,
  setEvidencesToHighlight
}: AIToolbarProps) => {
  const evidenceDocuments = useSelector(documentSelectors.evidenceDocs);
  const [activeMenu, setActiveMenu] = useState<number | null>(null);

  const [promptText, setPromptText] = useState("");
  const [promptError, setPromptError] = useState<string | null>(null);
  const [aiToolbarPosition, setAiToolbarPosition] = useState({
    left: 0,
    top: 0
  });
  const aiToolbarRef = useRef<HTMLDivElement>(null);
  const [menuMaxHeight, setMenuMaxHeight] = useState<number | undefined>(
    undefined
  );
  const [prompt, setPromptSelected] = useState<null | boolean>(false);

  // Editor container height, used to check for overflow
  const editorHeight = quillRef.current?.getEditor().root.clientHeight;
  const editorWidth = quillRef.current?.getEditor().root.clientWidth;

  useEffect(() => {
    if (selectedRange && showToolbar) {
      if (aiToolbarRef && aiToolbarRef.current) {
        const aitoolbarWidth = aiToolbarRef.current.clientWidth;
        const aitoolbarHeight = aiToolbarRef.current.clientHeight;


        const editorWidth = quillRef.current?.getEditor().root.clientWidth;
        const editorHeight = quillRef.current?.getEditor().root.clientHeight;

        // Determine if toolbar overlaps or overflows
        const isPositionOverlap = bounds.top < quillToolbarHeight;

        const calculatedLeft =
          bounds.left + aitoolbarWidth > editorWidth
            ? bounds.left - (aitoolbarWidth - bounds.width)
            : bounds.left;

        const baseTopPosition = isPositionOverlap
          ? bounds.top + bounds.height + quillToolbarHeight
          : bounds.top - 12;

        const calculatedTop = Math.min(
          baseTopPosition,
          editorHeight - aitoolbarHeight - 10 // Prevent overflow at the bottom
        );

        setAiToolbarPosition({ left: calculatedLeft, top: calculatedTop });
      }
    }
  }, [selectedRange, showToolbar]);

  const showReferenceMenu = window.location.pathname.includes("individual");

  return showToolbar ? (
    <div
      ref={aiToolbarRef}
      className="ai-toolbar min-w-36"
      style={{
        position: "absolute",
        left: `${aiToolbarPosition.left}px`,
        top: `${aiToolbarPosition.top}px`,
        zIndex: 2,
        // padding: "5px 10px",
        paddingInline: "5px",
        backgroundColor: "#f0f0f0",
        borderColor: "#307F8D",
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)"
      }}
    >
      {changingText ? (
        <div className="inline-flex gap-4 items-center px-4  ">
          <div>Changing text...</div>
          <CircularProgress isIndeterminate />
        </div>
      ) : (
        <div className="flex max-w-md">
          <Menu
            key="1"
            variant="prompt"
            isOpen={activeMenu === 1}
            onOpen={() => setActiveMenu(1)}
            onClose={() => setActiveMenu(null)}
          >
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
            >
              Rewrite
            </MenuButton>
            <Portal>
            <MenuList zIndex="9999" overflow="visible">
              {rewriteOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => handleAIButtonClick(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
            </Portal>
          </Menu>
          <div className="flex-1">
            <Divider orientation="vertical" mx={1} />
          </div>
          <Menu
            key="2"
            variant="prompt"
            isOpen={activeMenu === 2}
            onOpen={() => setActiveMenu(2)}
            onClose={() => setActiveMenu(null)}
          >
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
            >
              Change tone
            </MenuButton>
            <Portal>
            <MenuList
              maxHeight={`${menuMaxHeight}px`}
              zIndex="9999"
              overflow="visible"
            >
              {toneOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => handleAIButtonClick(undefined, option)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
            </Portal>
          </Menu>
          <div className="flex-1">
            <Divider orientation="vertical" mx={1} />
          </div>
          <Menu
            key="3"
            closeOnBlur={false}
            isOpen={activeMenu === 3}
            onOpen={() => setActiveMenu(3)}
            onClose={() => setActiveMenu(null)}
          >
            <MenuButton
              as={Button}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
              onClick={() => {
                const quill = quillRef.current?.getEditor();
                if (selectedRange) {
                  const getText = quill?.getText(
                    selectedRange.index,
                    selectedRange.length
                  );
                  if (getText) setSelectedText(getText);
                }
              }}
            >
              Prompt
            </MenuButton>
            <Portal>
            <MenuList
              zIndex="9999"
              overflow="visible"
              p={0}
              m={0}
              borderWidth={0}
              bg="white"
              // zIndex="9999"
              // overflow="visible"
            >
              <Box className="flex flex-col p-4 gap-2">
                <FormControl isInvalid={promptError !== null}>
                  <Textarea
                    onFocus={() => {
                      setPromptisSelected(true); // Notify parent to disable selection handling
                    }}
                    onBlur={() => {
                      setPromptisSelected(false); // Re-enable selection handling
                    }}
                    value={promptText}
                    onChange={(e) => {
                      const text = e.target.value;
                      setPromptText(text);
                      if (text === "") {
                        setPromptError("Prompt can't be empty");
                      } else {
                        setPromptError(null);
                      }
                    }}
                    placeholder="Ask AI to edit or generate... "
                    onClick={(e) => e.stopPropagation()} // Prevent propagation to Quill
                  />
                </FormControl>
                <Button
                  placeSelf="end"
                  variant="primaryOutline"
                  px={4}
                  onClick={async () => {
                    if (promptText === "") {
                      setPromptError("Prompt can't be empty");
                      return;
                    }
                    await handleAIButtonClick(undefined, undefined, promptText);
                    setPromptText("");
                  }}
                  leftIcon={<Image src={images.aiGenerate} />}
                >
                  Generate
                </Button>
              </Box>
            </MenuList>
            </Portal>
          </Menu>
          <div className="flex-1">
            <Divider orientation="vertical" mx={1} />
          </div>
          {showReferenceMenu && (
            <Menu
              key="4"
              closeOnBlur={false}
              isOpen={activeMenu === 4}
              onOpen={() => setActiveMenu(4)}
              onClose={() => setActiveMenu(null)}
            >
              <MenuButton
                as={Button}
                variant="neutralStateButton"
                rounded="0px"
                borderRadius={0}
                px={2}
                rightIcon={<ChevronDownIcon />}
                onClick={() => {
                  const quill = quillRef.current?.getEditor();
                  if (selectedRange) {
                    const getText = quill?.getText(
                      selectedRange.index,
                      selectedRange.length
                    );
                    if (getText) setSelectedText(getText);
                  }
                }}
              >
                Reference
              </MenuButton>

              <ToolbarDropdown
                setPromptisSelected={setPromptisSelected}
                setCheckedItems={setEvidencesToHighlight}
                onClose={() => setActiveMenu(null)}
                onConfirm={async () => {
                  if (evidencesToHighlight.length < 0) {
                    setPromptError("Select documents to reference.");
                    return;
                  }
                  await handleAIButtonClick(
                    undefined,
                    undefined,
                    undefined,
                    evidencesToHighlight
                  );
                  setEvidencesToHighlight([]);
                }}
                groupBy="criterion"
                list={evidenceDocuments}
                checkedItems={evidencesToHighlight}
                mergingField="id"
                searchField="autoTitle"
              />
            </Menu>
          )}
        </div>
      )}
    </div>
  ) : null;
};
