// Standard Documents
const COMMON_DOCUMENTS = [
  "Passport",
  "Visa",
  "I-94",
  "Resume | CV",
  "I-140 Approval Notice",
  "Educational Documents",
  "Academic Evaluation"
];

const EB1A_ADDITIONAL_DOCUMENTS = [
  "Status Documents",
  "Employment Contract or Offer Letter",
  "Position Job Description"
];

export const visaDocuments: { [key: string]: string[] } = {
  "EB-2-NIW": [...COMMON_DOCUMENTS],
  "O-1A": [...COMMON_DOCUMENTS],
  "EB-1A": [...COMMON_DOCUMENTS, ...EB1A_ADDITIONAL_DOCUMENTS]
};

// Evidence Documents
export const criteriaDocuments: {
  [key: string]: { category: string; documentTypes: { title: string }[] }[];
} = {
  "O-1A": [
    {
      category: "Awards (nationally or internationally recognized)",
      documentTypes: [
        { title: "Award for excellence in the field of endeavor" },
        { title: "Information or criteria used for the award" },
        { title: "Other" }
      ]
    },
    {
      category: "Memberships in professional associations",
      documentTypes: [
        { title: "Memberships in professional associations" },
        {
          title:
            "Information about the professional association, such as membership requirements"
        },
        { title: "Other" }
      ]
    },
    {
      category: "Media or publications about the applicant",
      documentTypes: [
        {
          title:
            "Published article written by others about the applicant’s work"
        },
        { title: "Information about the publication" },
        { title: "Other" }
      ]
    },
    {
      category: "Participating as a judge of the work of others",
      documentTypes: [
        { title: "Participation as a judge of others / peer-review" },
        { title: "Other" }
      ]
    },
    {
      category:
        "Original scientific, scholarly, or business-related contributions of major significance",
      documentTypes: [
        { title: "Patent, trademarks, copyrights, or licenses" },
        { title: "Other" }
      ]
    },
    {
      category:
        "Authorship of scholarly articles, professional journals, or other major media",
      documentTypes: [
        { title: "Publication/article written by the applicant" },
        { title: "Presentation at conferences" },
        { title: "Citation records" },
        { title: "Google scholar page" },
        { title: "Other" }
      ]
    },
    {
      category:
        "Employed in a critical or essential capacity for organizations with a distinguished reputation",
      documentTypes: [
        { title: "Offer letter or employment contract" },
        { title: "Other" }
      ]
    },
    {
      category: "Commanded a high salary or will command a high salary",
      documentTypes: [
        {
          title:
            "Proof of commanding a high salary (paystubs, offer letter, contract)"
        },
        { title: "Other" }
      ]
    },
    {
      category: "Comparable evidence of extraordinary ability",
      documentTypes: [
        { title: "Entrepreneur documents: Business model" },
        { title: "Entrepreneur documents: Contract agreement" },
        { title: "Entrepreneur documents: Financial support" },
        { title: "Entrepreneur documents: Letters from customers" },
        { title: "Entrepreneur documents: Investment letters" },
        { title: "Professional Certificates" },
        { title: "Other" }
      ]
    },
    {
      category: "Other",
      documentTypes: [
        { title: "Client Statement" },
        { title: "Internal Questionnaire" },
        { title: "Expert Evaluation" }
      ]
    }
  ],
  "EB-1A": [
    {
      category: "Awards (nationally or internationally recognized)",
      documentTypes: [
        { title: "Award for excellence in the field of endeavor" },
        { title: "Information or criteria used for the award" },
        { title: "Other" }
      ]
    },
    {
      category: "Memberships in associations",
      documentTypes: [
        { title: "Memberships in professional associations" },
        {
          title:
            "Information about the professional association, such as membership requirements"
        },
        { title: "Other" }
      ]
    },
    {
      category: "Media or publications about the applicant",
      documentTypes: [
        {
          title:
            "Published article or major media publication about the applicant’s work"
        },
        { title: "Information about the publication or media" },
        { title: "Other" }
      ]
    },
    {
      category: "Participating as a judge of the work of others",
      documentTypes: [
        { title: "Participation as a judge of others / peer-review" },
        { title: "Other" }
      ]
    },
    {
      category:
        "Original contributions of major significance: Scientific, scholarly, artistic, athletic, or business",
      documentTypes: [
        { title: "Patent, trademarks, copyrights, or licenses" },
        { title: "Other" }
      ]
    },
    {
      category: "Authorship of scholarly articles",
      documentTypes: [
        { title: "Publication/article written by the applicant" },
        { title: "Presentation at conferences" },
        { title: "Citation records" },
        { title: "Google scholar page" },
        { title: "Other" }
      ]
    },
    {
      category: "Artistic exhibitions or showcases",
      documentTypes: [
        {
          title:
            "Evidence that work has been displayed at artistic exhibitions or showcases"
        },
        { title: "Information about the exhibit" },
        { title: "Other" }
      ]
    },
    {
      category: "Leading or critical role in distinguished organizations",
      documentTypes: [
        { title: "Offer letter or employment contract" },
        { title: "Information about the organization" },
        { title: "Other" }
      ]
    },
    {
      category: "Command of a high salary or other remuneration",
      documentTypes: [
        {
          title:
            "Proof of commanding a high salary (paystubs, offer letter, contract)"
        },
        { title: "Other" }
      ]
    },
    {
      category: "Commercial success in performing arts",
      documentTypes: [
        { title: "Box office receipts, video sales, or other record of sales" },
        { title: "Other" }
      ]
    },
    {
      category: "Comparable evidence of extraordinary ability",
      documentTypes: [
        { title: "Entrepreneur documents: Business model" },
        { title: "Entrepreneur documents: Contract agreement" },
        { title: "Entrepreneur documents: Financial support" }
      ]
    }
  ],
  "EB-2-NIW": []
};

export const templatesWarningMsg =
    "CaseBlink is optimized to generate high-quality drafts. Choosing your own templates may lead to a regression in quality, including outdated regulations if your templates are not up to date.";
