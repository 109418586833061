import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "../api/firebaseApi";
import { useGetDocumentTypes } from "../hooks/useGetDocumentTypes";
import { useLogOut } from "../hooks/useLogOut";
import PageWrapper from "../pages/PageWrapper";
import { documentSelectors } from "../redux/documents/selectors";
import SessionService from "../services/session/sessionService";
import { FileExtensionDialog } from "./common/FileExtensionDialog";
import { FileUploadWindow } from "./common/FileUploadWindow";

export const ProtectedRoute = () => {
  const documentTypes = useSelector(documentSelectors.documentTypes);
  const { onSubmitLogOut } = useLogOut();
  const [sessionService, setSessionService] = useState<SessionService | null>(
    null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const { fetchDocumentTypesAndUpdate } = useGetDocumentTypes();

  useEffect(() => {
    // Listen for changes to authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is authenticated
        if (!sessionService) {
          const service = new SessionService(auth, onSubmitLogOut);
          service.startSessionCheck();
          setSessionService(service);
          fetchDocumentTypesAndUpdate();
        }
        setIsAuthenticated(true);
      } else {
        // User is not authenticated
        setIsAuthenticated(false);
        if (sessionService) {
          sessionService.stopSessionCheck();
          setSessionService(null);
        }
      }
      setLoading(false);
    });

    // Cleanup function to unsubscribe from auth listener and stop session service
    return () => {
      if (sessionService) {
        sessionService.stopSessionCheck();
      }
      unsubscribe();
    };
  }, [sessionService, onSubmitLogOut, fetchDocumentTypesAndUpdate]);

  // Show a loading indicator while checking authentication state
  if (loading) {
    return <div>Loading...</div>;
  }

  // If not authenticated, redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected components
  return (
    <div>
      <div className="fixed bottom-0 right-8 z-[1500] bg-white rounded-t-3xl shadow-2xl">
        <FileUploadWindow id="" path="" />
        <FileExtensionDialog />
      </div>

      <main className="flex-grow  xl:mx-auto 2x:w-[1024px]">
        <div className="flex flex-col h-[100vh] ">
          <PageWrapper component={<Outlet />} />
        </div>
      </main>
    </div>
  );
};
