import { useDispatch } from "react-redux";
import { fetchDocumentCategoriesAndTypes } from "../api/OpenAIApi";
import { setDocumentTypes } from "../redux/documents/documentSlice";

export const useGetDocumentTypes = () => {
  const dispatch = useDispatch();

  const fetchDocumentTypesAndUpdate = async () => {
    let data = await fetchDocumentCategoriesAndTypes();
    if ("signed_expert_letters" in data) {
      data = { ...data, letters: { ...data.signed_expert_letters } };
      delete data.signed_expert_letters;
    }
    dispatch(setDocumentTypes(data));
    console.log("FETCHING DOCUMENT TYPES", {
      data
    });
  };

  return { fetchDocumentTypesAndUpdate };
};
