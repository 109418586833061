import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SuperGroupedDocument } from "../../helpers/helpers";
import {
  DataDocs,
  SubdocumenstWithParentId,
  SubdocumentWithParentId
} from "../../types/tables-data";
import {
  generateDocumentUrls,
  generateSingleDocumentUrl
} from "./documentsThunk";
import { ExtractedData, VisaDocumentType } from "./types";

export interface DocumentState {
  documents: DataDocs[];
  groupedDocuments: SuperGroupedDocument[];
  extractedData: ExtractedData | null;
  selectedEvidenceDoc: DataDocs | null;
  evidenceExhibitMap: Record<string, string> | null;
  standardExhibitMap: Record<string, string> | null;
  isLoadingGetEvidence: boolean;
  isLoadingDocuments: boolean | null;
  documentTypes: Record<string, any> | null;
  loadedDocuments: string[];
}

const initialState: DocumentState = {
  documents: [],
  groupedDocuments: [],
  selectedEvidenceDoc: null,
  extractedData: null,
  evidenceExhibitMap: null,
  standardExhibitMap: null,
  isLoadingGetEvidence: false,
  isLoadingDocuments: null,
  documentTypes: null,
  loadedDocuments: []
};

export interface DocumentReducer {
  document: DocumentState;
}

export interface RemoveDocPayload {
  evidenceDocId: string;
  docId: string;
}

export interface RemoveStandardDocument {
  filePath: string;
}

export interface DocumentsPayload {
  type?: VisaDocumentType;
  data: DataDocs[] | DataDocs[];
}

export interface DocumentPayload {
  type?: VisaDocumentType;
  data: DataDocs | DataDocs;
}

export interface SubDocumentPayload {
  data: SubdocumentWithParentId;
}

export interface SubDocumentsPayload {
  data: SubdocumenstWithParentId;
}

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocumentTypes: (state, action: PayloadAction<any>) => {
      state.documentTypes = action.payload;
    },
    setGroupedDocuments: (
      state,
      action: PayloadAction<SuperGroupedDocument[]>
    ) => {
      state.groupedDocuments = [...action.payload];
    },
    // ROOT DOCUMENT LEVEL //
    addNewDocument: (state, action: PayloadAction<DataDocs>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { criterion, super_class } = action.payload;

      const newDocument: DataDocs = {
        ...action.payload,
        criterion:
          criterion === undefined || criterion === ""
            ? "Processing"
            : criterion,
        super_class:
          super_class === undefined || super_class === ""
            ? "Processing"
            : super_class
      };

      // if (!superClass || !criterion) return;

      // Find or create the super class group
      let superGroup = state.groupedDocuments.find(
        (group) => group.super_class === newDocument.super_class
      );

      if (!superGroup) {
        superGroup = {
          super_class: newDocument.super_class!,
          groups: []
        };
        state.groupedDocuments.push(superGroup);
      }

      // Find or create the type group
      let group = superGroup.groups.find(
        (g) => g.type === newDocument.criterion
      );
      if (!group) {
        group = {
          type: newDocument.criterion!,
          index: -1,
          subrows: [],
          expanded: false
        };
        superGroup.groups.push(group);
      }

      const newDocumentIndex = group.subrows.findIndex(
        (doc) => doc.id === newDocument.id
      );
      // We check if document exist to avoid duplicates
      if (newDocumentIndex === -1) group.subrows.push(newDocument);
    },
    deleteDocument: (state, action: PayloadAction<DataDocs>) => {
      const documentToDelete = action.payload;
      const { super_class: superClass, criterion } = documentToDelete;

      if (!superClass || !criterion) return;

      // Find the super class group
      const superGroup = state.groupedDocuments.find(
        (group) => group.super_class === superClass
      );

      if (superGroup) {
        // Find the type group
        const group = superGroup.groups.find((g) => g.type === criterion);
        if (group) {
          // Remove the document from the subrows
          group.subrows = group.subrows.filter(
            (doc) => doc.id !== documentToDelete.id
          );

          // Remove the group if it's empty
          if (group.subrows.length === 0) {
            superGroup.groups = superGroup.groups.filter(
              (group) => group.type !== criterion
            );
          }
        }
      }
    },
    updateDocument: (state, action: PayloadAction<DataDocs>) => {
      const updatedDocument = action.payload;
      const { super_class: superClass, criterion } = updatedDocument;

      if (!superClass || !criterion) return;

      // Check if document exist on the Processing super_class and remove it
      const superGroupProcessing = state.groupedDocuments.find(
        (group) => group.super_class === "Processing"
      );

      if (superGroupProcessing) {
        const group = superGroupProcessing.groups.find(
          (g) => g.type === "Processing"
        );
        if (group && criterion) {
          const subrowIndex = group.subrows.findIndex(
            (doc) => doc.id === updatedDocument.id
          );
          if (subrowIndex !== -1) {
            group.subrows = group.subrows.filter(
              (doc) => doc.id !== updatedDocument.id
            );

            if (group.subrows.length === 0) {
              superGroupProcessing.groups = superGroupProcessing.groups.filter(
                (group) => group.type !== "Processing"
              );
            }
          }
        }
      }

      // Find the super class group
      let superGroup = state.groupedDocuments.find(
        (group) => group.super_class === superClass
      );

      if (superGroup) {
        // Find the type group
        let group = superGroup.groups.find((g) => g.type === criterion);
        if (group) {
          // Update the document in the subrows
          const subrowIndex = group.subrows.findIndex(
            (doc) => doc.id === updatedDocument.id
          );
          if (subrowIndex !== -1) {
            group.subrows[subrowIndex] = {
              ...updatedDocument,
              sub_documents: group.subrows[subrowIndex].sub_documents ?? [],
              docUrl: group.subrows[subrowIndex].docUrl
            };
          } else {
            group.subrows = [...group.subrows, updatedDocument];
          }
        } else if (!group) {
          group = {
            type: criterion,
            index: -1,
            subrows: [updatedDocument],
            expanded: false
          };
          superGroup.groups.push(group);
        }
      } else {
        superGroup = {
          super_class: superClass,
          groups: [
            {
              expanded: true,
              index: -1,
              subrows: [updatedDocument],
              type: criterion
            }
          ]
        };
        state.groupedDocuments.push(superGroup);
      }
    },
    // SUB DOCUMENT LEVEL //
    setSubDocuments: (state, action: PayloadAction<SubDocumentsPayload>) => {
      const {
        data: { documents, id }
      } = action.payload;

      for (const superGroup of state.groupedDocuments) {
        for (const group of superGroup.groups) {
          const document = group.subrows.find((doc) => doc.id === id);
          if (document) {
            document.sub_documents = documents;
            return;
          }
        }
      }
    },
    addSubDocument: (state, action: PayloadAction<SubDocumentPayload>) => {
      const { data } = action.payload;
      for (const superGroup of state.groupedDocuments) {
        for (const group of superGroup.groups) {
          const document = group.subrows.find((doc) => doc.id === data.id);
          if (document) {
            if (!document.sub_documents) {
              document.sub_documents = [];
            }
            document.sub_documents.push(data.document as DataDocs);
            return;
          }
        }
      }
    },
    deleteSubDocument: (state, action: PayloadAction<SubDocumentPayload>) => {
      const {
        data: { document: subDocument, id }
      } = action.payload;
      for (const superGroup of state.groupedDocuments) {
        for (const group of superGroup.groups) {
          const document = group.subrows.find((doc) => doc.id === id);
          if (document && document.sub_documents) {
            document.sub_documents = document.sub_documents.filter(
              (subDoc) => subDoc.id !== subDocument.id
            );
            return;
          }
        }
      }
    },
    updateSubDocument: (state, action: PayloadAction<SubDocumentPayload>) => {
      const {
        data: { document: subDocument, id }
      } = action.payload;
      for (const superGroup of state.groupedDocuments) {
        for (const group of superGroup.groups) {
          const document = group.subrows.find((doc) => doc.id === id);
          if (document && document.sub_documents) {
            const subDocumentIndex = document.sub_documents.findIndex(
              (doc) => doc.id === subDocument.id
            );
            if (subDocumentIndex !== -1) {
              document.sub_documents[subDocumentIndex] = { ...subDocument };
            }
            return;
          }
        }
      }
    },
    setIsLoadingGetEvidence: (state, action: PayloadAction<boolean>) => {
      state.isLoadingGetEvidence = action.payload;
    },
    setIsLoadingDocuments: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDocuments = action.payload;
    },
    setEvidenceExhibitMap: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      const data = action.payload;
      state.evidenceExhibitMap = data;
    },
    setStandardExhibitMap: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      const data = action.payload;
      state.standardExhibitMap = data;
    },

    removeAllEvidenceExtractions: (state) => {
      state.documents.forEach((evidence) => {
        evidence.extracted_argument = null;
      });
    },

    setExtractedDataD: (state, action: PayloadAction<ExtractedData | null>) => {
      state.extractedData = action.payload;
    },
    // always keep the documentTypes intact
    clearAllDocuments: (state) => ({
      ...initialState,
      documentTypes: state.documentTypes
    })
  },
  extraReducers: (builder) => {
    builder.addCase(generateDocumentUrls.fulfilled, (state, action) => {
      action.payload.forEach(
        ({ id, fileUrl, mainDocId, criterion, super_class }) => {
          // Find the super_class group
          const superGroup = state.groupedDocuments.find(
            (group) => group.super_class === super_class
          );

          if (superGroup) {
            // Find the specific group by criterion (category)
            const categoryGroup = superGroup.groups.find(
              (group) => group.type === criterion
            );

            if (categoryGroup) {
              if (mainDocId) {
                // Find the main document within the subrows
                const mainDoc = categoryGroup.subrows.find(
                  (doc) => doc.id === mainDocId
                );

                if (mainDoc) {
                  // Find the sub-document and update its docUrl
                  const subDocIndex = mainDoc.sub_documents?.findIndex(
                    (subDoc) => subDoc.id === id
                  );

                  if (
                    subDocIndex &&
                    subDocIndex !== -1 &&
                    mainDoc.sub_documents
                  ) {
                    mainDoc.sub_documents[subDocIndex].docUrl = fileUrl;
                  }
                }
              } else {
                // Find the document within the subrows and update its docUrl
                const doc = categoryGroup.subrows.find((doc) => doc.id === id);
                if (doc) {
                  doc.docUrl = fileUrl;
                }
              }
            }
          }
        }
      );
    });

    builder.addCase(generateSingleDocumentUrl.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, fileUrl, super_class, criterion } = action.payload;
      // Find the super_class group
      const superGroup = state.groupedDocuments.find(
        (group) => group.super_class === super_class
      );

      if (superGroup) {
        // Find the specific group by criterion (category)
        const categoryGroup = superGroup.groups.find(
          (group) => group.type === criterion
        );

        if (categoryGroup) {
          // Find the document within the subrows and update its docUrl
          const doc = categoryGroup.subrows.find((doc) => doc.id === id);
          if (doc) {
            doc.docUrl = fileUrl;
          }
        }
      }
    });
  }
});

export const {
  setDocumentTypes,

  updateDocument,
  addNewDocument,
  deleteDocument,
  setExtractedDataD,
  clearAllDocuments,
  removeAllEvidenceExtractions,
  addSubDocument,
  setSubDocuments,
  deleteSubDocument,
  setEvidenceExhibitMap,
  setStandardExhibitMap,
  setIsLoadingGetEvidence,
  updateSubDocument,
  setIsLoadingDocuments,
  setGroupedDocuments
} = documentSlice.actions;

export default documentSlice.reducer;
