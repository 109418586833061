import { Box, Divider, VStack } from "@chakra-ui/react";
import { PacketGenerateSection } from "./individualPacket/PacketGenerateSection";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const Packet = ({ mainTabIndex, subTabIndex }: Props) => {
  return (
    <Box>
      <VStack
        alignItems="stretch"
        gap={8}
        divider={<Divider my={8} />}
        w="100%"
      >
        <PacketGenerateSection />
      </VStack>
    </Box>
  );
};
