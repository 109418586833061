import { Field } from "formik";
import { Box, Stack } from "../_index";
import { InputField } from "../InputField";

export const AddressFields = ({ name, usBased = false }: any) => {
  return (
    <Box>
      <Stack spacing={4}>
        <Field name={`${name}.street`}>
          {({ field }: any) => (
            <InputField
              {...field}
              name={`${name}.street`}
              id={`${name}.street`}
              placeholder="Street"
              label="Street"
            />
          )}
        </Field>
        <div className="flex gap-4">
          <Field name={`${name}.city`}>
            {({ field }: any) => (
              <InputField
                {...field}
                name={`${name}.city`}
                id={`${name}.city`}
                placeholder="City"
                label="City"
              />
            )}
          </Field>

          <Field name={`${name}.state`}>
            {({ field }: any) => (
              <InputField
                {...field}
                name={`${name}.state`}
                id={`${name}.state`}
                placeholder="State"
                label="State"
              />
            )}
          </Field>

          <Field name={`${name}.zipCode`}>
            {({ field }: any) => (
              <InputField
                {...field}
                name={`${name}.zipCode`}
                id={`${name}.zipCode`}
                placeholder="Zip Code"
                label="Zip Code"
              />
            )}
          </Field>
        </div>

        {!usBased && (
          <InputField
            name={`${name}.country`}
            id={`${name}.country`}
            placeholder="Country"
            label="Country"
          />
        )}
      </Stack>
    </Box>
  );
};
