import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../api/firebaseApi";
import { ClientLawyerData } from "../redux/client-case/activeClientSlice";
import { LawyerState } from "../redux/lawyer/lawyerSlice";
import { DATABASE } from "../types/tables-data";
import { useLogOut } from "./useLogOut";

export const useGetLawyerData = () => {
  const [isLoadingGetLawyerData, setIsLoadingGetLawyerData] = useState(false);
  const [isNoLawyerData, setIsNoLawyerData] = useState(false);
  const [lawyerData, setLawyerData] = useState<ClientLawyerData | null>(null);
  const [fullLawyerData, setFullLawyerData] = useState<LawyerState | null>(
    null
  );
  const { onSubmitLogOut } = useLogOut();

  const onSubmitGetLawyerData = async (lawyerId: string) => {
    const docRef = doc(db, DATABASE.LAWYERS, lawyerId);
    setIsLoadingGetLawyerData(true);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLawyerData({
          lawyerEmail: docSnap.data().email || "",
          lawyerFirstName: docSnap.data().firstName || "",
          lawyerLastName: docSnap.data().lastName || ""
        });
        setFullLawyerData({
          firstName: docSnap.data().firstName,
          middleName: docSnap.data().middleName,
          lastName: docSnap.data().lastName,
          pronouns: docSnap.data().pronouns,
          title: docSnap.data().title,
          email: docSnap.data().email,
          address: docSnap.data().address,
          state: docSnap.data().state,
          city: docSnap.data().city,
          zipcode: docSnap.data().zipcode,
          phone: docSnap.data().phone,
          practiceName: docSnap.data().practiceName,
          lawFirm: docSnap.data().lawFirm || "",
          website: docSnap.data().website,
          avatarName: docSnap.data().avatarName || "",
          avatarSrc: docSnap.data().avatarSrc || "",
          uid: lawyerId,
          role: docSnap.data().role || "",
          subscription: docSnap.data().subscription || {
            status: "freemium",
            request: false
          },
          hasAcceptedTerms: docSnap.data().hasAcceptedTerms || false,
          hideQualityReview: docSnap.data().hideQualityReview || false,
          betaFeatures: docSnap.data().betaFeatures || []
        });
      } else {
        setIsNoLawyerData(true);
        onSubmitLogOut();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingGetLawyerData(false);
    }
  };

  return {
    isLoadingGetLawyerData,
    lawyerData,
    fullLawyerData,
    onSubmitGetLawyerData,
    isNoLawyerData
  };
};
