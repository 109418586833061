import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  FormLabel,
  SkeletonText,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { doc, onSnapshot, serverTimestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import useEvidenceProngs, { Source } from "../../../hooks/useEvidenceProngs";
import { individualSelectors } from "../../../redux/individual/selectors";
import { DATABASE, VISAVALUE, visaFromPath } from "../../../types/tables-data";
import { CustomBox } from "../../common/CustomBox";
import { AddSourceDialog } from "./evidenceQuestions/AddSourceDialog";
import { DeleteSourceDialog } from "./evidenceQuestions/DeleteSourceDialog";
import { VisaQuestionsForm } from "./VisaQuestionsForm";

export const EvidenceQuestions = () => {
  const { id, visaType } = useParams();
  const toast = useToast();

  const [index, setIndex] = useState<number[]>([0, 1, 2]);
  const [showSourceDialog, setShowSourceDialog] = useState<boolean>(false);
  const [showSourceDeletion, setShowSourceDeletion] = useState<boolean>(false);
  const [sourceToDelete, setSourceToDelete] = useState<Source | null>(null);
  const uid = id ?? useSelector(individualSelectors.selectUid);

  const {
    deleteSource,
    deleteLoading,
    getLargestIndex,
    addSourcesToProng1,
    addSourcesLoading,
    prongs,
    saveData
  } = useEvidenceProngs(uid);

  const [prong1Data, setProng1Data] = useState({
    areaOfExpertise: "",
    specialization: "",
    summary: "",
    description: "",
    sources: []
  });

  const [isAutoGenerating, setIsAutoGenerating] = useState(false);

  // Listen for questionnaires auto-filling status
  useEffect(() => {
    const docRef = doc(db, DATABASE.QUESTIONNAIRES, uid);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        const { status = {} } = data;
        const isProcessing = status?.status === "Processing";
        setIsAutoGenerating(isProcessing);
      }
    });
    return () => unsubscribe();
  }, []);

  const [prong2Data, setProng2Data] = useState({
    qualifications: "",
    pastAchievements: "",
    impactOnField: "",
    workUtilization: "",
    futurePlans: ""
  });

  const [prong3Data, setProng3Data] = useState({
    laborWaiverExplanation: ""
  });

  useEffect(() => {
    if (prongs !== null) {
      setProng1Data({
        areaOfExpertise: prongs.prong1Data?.areaOfExpertise || "",
        specialization: prongs.prong1Data?.specialization || "",
        summary: prongs.prong1Data?.summary || "",
        description: prongs.prong1Data?.description || "",
        sources: prongs.prong1Data?.sources || []
      });

      setProng2Data({
        qualifications: prongs.prong2Data?.qualifications || "",
        pastAchievements: prongs.prong2Data?.pastAchievements || "",
        impactOnField: prongs.prong2Data?.impactOnField || "",
        workUtilization: prongs.prong2Data?.workUtilization || "",
        futurePlans: prongs.prong2Data?.futurePlans || ""
      });

      setProng3Data({
        laborWaiverExplanation: prongs.prong3Data?.laborWaiverExplanation || ""
      });
    }
  }, [prongs]);

  const handleSubmit = async () => {
    const dataToSave = {
      prong1Data,
      prong2Data,
      prong3Data
    };

    try {
      await saveData(`${DATABASE.QUESTIONNAIRES}/${uid}/`, dataToSave);

      toast({
        title: "Success",
        description: " Information saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      setIndex([]);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleAccordionChange = (expandedIndex: number[]) => {
    setIndex(expandedIndex);
  };
  const handleAddSource = async (data: any) => {
    try {
      const largestIndex = await getLargestIndex();
      data.exhibit = largestIndex + 1;
      data.createdAt = serverTimestamp();
      await addSourcesToProng1([data]);

      toast({
        title: "Success",
        description: "Source added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleDeleteSource = async (sourceId: string) => {
    try {
      await deleteSource(sourceId);
      // If no error occurred, display a success toast
      toast({
        title: "Success",
        description: "Source added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const openDeleteConfirmation = (source: Source) => {
    setSourceToDelete(source);
    setShowSourceDeletion(true);
  };

  const isEB2 = visaType ? visaFromPath[visaType] === VISAVALUE.EB2NIW : false;

  const eb2 = () => {
    return (
      <Flex flexDirection="column" textAlign="left">
        <Flex flexDirection="column">
          {isAutoGenerating && (
            <CustomBox type="info">
              <Text fontSize="15px">
                AI is now extracting the information from the resume.
              </Text>
            </CustomBox>
          )}
          {/* Prongs Loading Component */}
        </Flex>
        <Card ml="0">
          <Accordion
            allowMultiple
            index={index}
            onChange={handleAccordionChange}
          >
            {/* Prong 1 */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text> Prong 1 questions</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p={10}>
                {/* Prong 1 questions */}

                <FormLabel mb={2} fontWeight={700}>
                  What is the applicant's area of expertise?
                </FormLabel>
                <Text fontSize="sm" color="gray.500" mb={2} as="i">
                  For example: Data Science, Machine Learning Engineering,
                  Biomedical Engineering, Biological Sciences
                </Text>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    placeholder=""
                    value={prong1Data.areaOfExpertise}
                    onChange={(e) =>
                      setProng1Data({
                        ...prong1Data,
                        areaOfExpertise: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel mb={2} fontWeight={700}>
                  Does the applicant have a specialization area within their
                  area of expertise?
                </FormLabel>
                <Text fontSize="sm" color="gray.500" mb={2} as="i">
                  For example: Healthcare Data Science, Natural Language
                  Processing, Genetics
                </Text>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    placeholder=""
                    value={prong1Data.specialization}
                    onChange={(e) =>
                      setProng1Data({
                        ...prong1Data,
                        specialization: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel mb={2} fontWeight={700}>
                  Summarize the applicant's proposed endeavor in 1-2 sentences
                </FormLabel>
                <Text fontSize="sm" color="gray.500" mb={2} as="i">
                  For example: The applicant's proposed endeavor is to advance
                  the field of soil science and its role in maximizing crop
                  production, food and water security, and greenhouse gas
                  emissions.
                </Text>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    placeholder=""
                    value={prong1Data.summary}
                    onChange={(e) =>
                      setProng1Data({
                        ...prong1Data,
                        summary: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel mb={2} fontWeight={700}>
                  Describe the substantial merit and national importance of the
                  proposed endeavor.
                </FormLabel>
                <Text fontSize="sm" color="gray.500" mb={2} as="i">
                  Provide a detailed description of the applicant's work and its
                  national importance. Describe the applicant's specific
                  projects and goals rather than just listing the general job
                  duties of the applicant's position. Use lay terms and describe
                  any complicated concepts.
                </Text>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    placeholder=""
                    value={prong1Data.description}
                    onChange={(e) =>
                      setProng1Data({
                        ...prong1Data,
                        description: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>
              </AccordionPanel>
            </AccordionItem>

            {/* Prong 2 */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Prong 2 questions
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p={10}>
                {/* Prong 2 questions */}
                <FormLabel htmlFor="qualifications" fontWeight={700}>
                  Describe the applicant's qualifications
                </FormLabel>

                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    id="qualifications"
                    placeholder=""
                    value={prong2Data.qualifications}
                    onChange={(e) =>
                      setProng2Data({
                        ...prong2Data,
                        qualifications: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel htmlFor="pastAchievements" fontWeight={700}>
                  How do the applicant's past achievements make them qualified
                  to improve on the applicant's proposed work?
                </FormLabel>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    id="pastAchievements"
                    placeholder=""
                    value={prong2Data.pastAchievements}
                    onChange={(e) =>
                      setProng2Data({
                        ...prong2Data,
                        pastAchievements: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel htmlFor="impactOnField" fontWeight={700}>
                  What impact has the applicant's past work already had in the
                  field?
                </FormLabel>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    id="impactOnField"
                    placeholder=""
                    value={prong2Data.impactOnField}
                    onChange={(e) =>
                      setProng2Data({
                        ...prong2Data,
                        impactOnField: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel htmlFor="workUtilization" fontWeight={700}>
                  How are others using the applicant's work?
                </FormLabel>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    id="workUtilization"
                    placeholder=""
                    value={prong2Data.workUtilization}
                    onChange={(e) =>
                      setProng2Data({
                        ...prong2Data,
                        workUtilization: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>

                <FormLabel htmlFor="futurePlans" fontWeight={700}>
                  What are the applicant's future plans to advance their field
                  of work?
                </FormLabel>
                <SkeletonText
                  isLoaded={!isAutoGenerating}
                  my="8"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="3"
                >
                  <Textarea
                    id="futurePlans"
                    placeholder=""
                    value={prong2Data.futurePlans}
                    onChange={(e) =>
                      setProng2Data({
                        ...prong2Data,
                        futurePlans: e.target.value
                      })
                    }
                    mb={10}
                  />
                </SkeletonText>
              </AccordionPanel>
            </AccordionItem>

            {/* Prong 3 */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Prong 3 questions
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p={10}>
                {/* Prong 3 questions */}
                <FormLabel htmlFor="laborWaiverExplanation" fontWeight={700}>
                  Waiver of labor certification
                </FormLabel>
                <Text fontSize="sm" color="gray.500" mb={2} as="i">
                  Write a paragraph explaining why it would be beneficial to the
                  U.S. to waive the requirements of a job offer and thus of a
                  labor certification. Note if there are any urgent
                  considerations, if there is a potential for job creation, or
                  if the applicant's role is especially unique and a labor
                  certification is impractical.
                </Text>
                <Textarea
                  id="laborWaiverExplanation"
                  placeholder=""
                  value={prong3Data.laborWaiverExplanation}
                  onChange={(e) =>
                    setProng3Data({
                      ...prong3Data,
                      laborWaiverExplanation: e.target.value
                    })
                  }
                  mb={10}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Flex w="100%" justifyContent="center" my={6}>
            <Button
              isDisabled={isAutoGenerating}
              my={4}
              variant="primaryFilled"
              mx="15px"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
          {showSourceDeletion && sourceToDelete && (
            <DeleteSourceDialog
              source={sourceToDelete}
              onConfirm={() => {
                if (sourceToDelete.id) {
                  handleDeleteSource(sourceToDelete.id);
                  setShowSourceDeletion(false);
                }
              }}
              setShowSourceDeletion={setShowSourceDeletion}
              isLoading={deleteLoading}
            />
          )}
          {showSourceDialog && (
            <AddSourceDialog
              onConfirm={(data) => {
                handleAddSource(data);
                setShowSourceDialog(false);
              }}
              setShowSourceDialog={setShowSourceDialog}
              isLoading={addSourcesLoading}
            />
          )}
        </Card>
      </Flex>
    );
  };

  // TODO : generate formdata interfaces for all visa questions in db
  interface Prong1Data {
    areaOfExpertise?: string;
  }
  interface FormData {
    generalField?: string;
    applicantField?: string;
    prong1Data?: Prong1Data;
    academicField?: string;
  }

  const questionsSection = () => {
    // Conditional rendering logic
    let content = null;

    if (visaType == null) {
      content = null; // Render nothing if visaType is null
    } else if (isEB2) {
      content = eb2(); // Render the EB2 component or function if isEB2 is true
    } else {
      content = (
        <VisaQuestionsForm
          isAutoGenerating={isAutoGenerating}
          visaType={visaFromPath[visaType]}
          aiExtractionMsg={
            <CustomBox type="info">
              <Text fontSize="15px">
                AI is now extracting the information from the resume.
              </Text>
            </CustomBox>
          }
        />
      ); // Render VisaQuestionsForm for other visa types
    }

    return (
      <>
        {/* {renderCustomBox} */}
        {content}
      </>
    );
  };

  return questionsSection();
};
