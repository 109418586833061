import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useUpdateUser } from "../hooks/useUpdateUser";
import { DATABASE } from "../types/tables-data";
import { betaFeatures } from "../constans";

// Mapping for converting between presentation and database variable names
const featureMapping: Record<string, string> = {
  "Legal Brief: Custom Templates": "legal_brief_templates",
  "Another Feature Name": "another_feature_variable", // Add more mappings as needed
};

interface BetaAccessModalProps {
  isOpen: boolean;
  userId: string | undefined;
  userName: string;
  existingFeatures: string[]; // Current features from Firestore
  closeModal: () => void;
}

const BetaAccessModal: React.FC<BetaAccessModalProps> = ({
  isOpen,
  userId,
  userName,
  existingFeatures,
  closeModal
}) => {
  const { updateBetaFeatureAccess } = useUpdateUser();
  const [isLoading, setIsLoading] = useState(false);

  // Convert existing features from database format to presentation format
  const convertToPresentation = (features: string[]) =>
    features.map((feature) =>
      Object.keys(featureMapping).find(
        (key) => featureMapping[key] === feature
      ) || feature
    );

  // Convert selected features to database format before storing
  const convertToDatabase = (feature: string) =>
    featureMapping[feature] || feature;

  const [selectedFeatures, setSelectedFeatures] = useState<string[]>(
    convertToPresentation(existingFeatures)
  );

  const handleFeatureToggle = async (feature: string, isChecked: boolean) => {
    if (!userId) return;
    setIsLoading(true);

    try {
      const featureToStore = convertToDatabase(feature);
      await updateBetaFeatureAccess(
        userId,
        DATABASE.LAWYERS,
        featureToStore,
        isChecked
      );
      if (isChecked) {
        setSelectedFeatures((prev) => [...prev, feature]);
      } else {
        setSelectedFeatures((prev) => prev.filter((f) => f !== feature));
      }
    } catch (error) {
      console.error("Error updating beta feature access:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Beta Features for {userName}</ModalHeader>
        <ModalBody>
          <Text>Manage beta feature access for this user.</Text>
          <CheckboxGroup value={selectedFeatures}>
            {betaFeatures.map((feature) => (
              <Checkbox
                key={feature}
                isChecked={selectedFeatures.includes(feature)}
                onChange={(e) => handleFeatureToggle(feature, e.target.checked)}
                isDisabled={isLoading}
              >
                {feature}
              </Checkbox>
            ))}
          </CheckboxGroup>
          {isLoading && <Spinner size="sm" ml="10px" />}
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} isDisabled={isLoading}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BetaAccessModal;
