import { HamburgerIcon, UnlockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner
} from "@chakra-ui/react";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, Link as LinkDom, useNavigate } from "react-router-dom";
import { getFirebaseUsers } from "../../../api/OpenAIApi";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";
import BetaAccessModal from "../../../components/betaAccessModal";

export const AdminUsers = () => {
  interface User {
    uid: string;
    email: string;
    phoneNumber: string;
    role: string;
    firstName: string;
    lastName: string;
    lawFirmName: string;
    disabled: boolean;
    emailVerified: boolean;
    betaFeatures: string[];
    lastSignedInAt: Timestamp;
    createdAt: Timestamp;
  }

  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);

  const [users, setUsers] = useState<User[]>([]);
  const [tokens, setTokens] = useState<string[]>([]);
  const [tokenIndex, setTokenIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserName, setSelectedUserName] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );
  const [showBetaModal, setShowBetaModal] = useState<boolean>(false);

  const perPage = 25;

  const getUserData = async (
    orderBy = "",
    uid = "",
    email = "",
    maxResults = perPage,
    pageToken = ""
  ) => {
    setIsLoading(true);
    try {
      if (pageToken === "") {
        setTokenIndex(0);
      }
      const usersData = await getFirebaseUsers(
        orderBy,
        uid,
        email,
        maxResults,
        pageToken
      );
      setUsers(usersData.users);
      if (
        usersData.nextPageToken &&
        !tokens.includes(usersData.nextPageToken)
      ) {
        setTokens([...tokens, usersData.nextPageToken]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = (destination: string) => {
    if (destination === "previous") {
      setTokenIndex(tokenIndex - 1);
      getUserData("", "", searchQuery, perPage, tokens[tokenIndex]);
    }
    if (destination === "next") {
      setTokenIndex(tokenIndex + 1);
      getUserData("", "", searchQuery, perPage, tokens[tokenIndex]);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleSearch = () => {
    setTokens([]);
    getUserData("", "", searchQuery);
  };

  const pageSize = 25; // Number of users per page

  const handleBetaAccess = (user: User) => {
    setSelectedUserId(user.uid);
    setSelectedUserName(
      user.firstName ? `${user.firstName} ${user.lastName}` : user.email
    );

    // Fetch beta features for the user (or pass from existing state)
    const userBetaFeatures = user.betaFeatures || [];
    setShowBetaModal(true);
  };

  const renderUserContent = () => {
    const [currentPage, setCurrentPage] = useState(1);

    if (isLoading) {
      return (
        <Spinner
          alignSelf="center"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.blue"
          size="xl"
        />
      );
    }

    if (users && users.length > 0) {
      const lastIndex = currentPage * pageSize;
      const firstIndex = lastIndex - pageSize;
      const currentUsers = users.slice(firstIndex, lastIndex);
      return (
        <Box className="overflow-x-scroll overflow-y-scroll max-h-[70vh]">
          <table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0 10px"
            }}
          >
            <thead>
              <tr>
              <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Action
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Edit
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Role
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  First Name
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Last Name
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  LawFirm
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={user.uid}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#e9e9e9",
                    border: "1px solid #ddd",
                    cursor: "pointer"
                  }}
                >
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HamburgerIcon />}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => handleBetaAccess(user)}
                          icon={<UnlockIcon />}
                        >
                          Beta access
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    <Link
                      to={`/admin/users/${user.uid}/edit`}
                      key={user.uid}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Edit
                    </Link>
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.email}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.role}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.firstName}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.lastName}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.lawFirmName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Box display="flex" justifyContent="center" mt="20px">
            <Button
              variant="secondaryOutline"
              type="button"
              mt="20px"
              mr="20px"
              isDisabled={tokenIndex === 0}
              onClick={() => changePage("previous")}
            >
              Previous
            </Button>
            <Button
              variant="primaryFilled"
              type="button"
              mt="20px"
              onClick={() => changePage("next")}
              isDisabled={tokenIndex === tokens.length}
            >
              Next
            </Button>
          </Box>
        </Box>
      );
    }
    return (
      <Heading size="sm" my="15px">
        No users found. <br /> Use the system to add users.
      </Heading>
    );
  };

  return (
    <Flex flexDirection="column" flex="1" className="p-4 overflow-scroll">
      <Box py="15px" width="100%">
      <SpecialHeading title="User Management" />
      </Box>
      <LinkDom
        to="new"
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          display: "block",
          textAlign: "left"
        }}
      >
        Create a new user
      </LinkDom>
      <Flex mt="20px" alignItems="center">
        <Input
          placeholder="Search for user..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          mr="10px"
          width="20%"
        />
        <Button onClick={handleSearch} variant="primaryFilled">
        Search
        </Button>
      </Flex>
      {renderUserContent()}

      {/* Beta Access Modal */}
      <BetaAccessModal
        userId={selectedUserId}
        userName={selectedUserName}
        isOpen={showBetaModal}
        closeModal={() => setShowBetaModal(false)}
        existingFeatures={
          users.find((u) => u.uid === selectedUserId)?.betaFeatures || []
        }
      />
    </Flex>
  );
};

