import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr
} from "@chakra-ui/react";
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  MinusIcon,
  WarningIcon
} from "@chakra-ui/icons";
import { uploadFileSelectors } from "../../redux/file-upload/selectors";
import {
  setExpanded,
  setFiles,
  setVisibilty
} from "../../redux/file-upload/fileUploadSlice";
import { filterDocumentsBySupport } from "../../helpers/file_helpers";

export const FileUploadWindow = ({
  path,
  id
}: {
  path: string;
  id: string;
}) => {
  const dispatch = useDispatch();
  const {
    files,
    error,
    isLoading,
    uploadProgress,
    visible,
    expanded: isExpanded
  } = useSelector(uploadFileSelectors.selectAll);

  const uploadProgressValues = Object.values(uploadProgress);
  const uploading =
    uploadProgressValues.length > 0 &&
    uploadProgressValues.reduce((acc, currentValue) => acc + currentValue, 0) %
      uploadProgressValues.length !==
      0;

  const supportedFiles = filterDocumentsBySupport(files, true);
  const unsupportedFiles = filterDocumentsBySupport(files, false);

  return (
    <Box
      style={{ display: `${visible ? "block" : "none"}` }}
      className="max-h-96"
    >
      <div className="w-full flex justify-between items-center gap-2 px-4 py-2">
        <p className="font-medium">
          {uploading
            ? `uploading ${supportedFiles.length} files`
            : `${supportedFiles.length} uploads completed`}{" "}
        </p>
        <div className="flex gap-2">
          <IconButton
            variant="primaryFilled"
            icon={!isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            aria-label=""
            onClick={() => dispatch(setExpanded(!isExpanded))}
          />
          <IconButton
            variant="outlineIconButton"
            icon={<CloseIcon />}
            aria-label=""
            onClick={() => {
              dispatch(setVisibilty(false));
              dispatch(setFiles([]));
            }}
          />
        </div>
      </div>
      <div className="relative max-h-72 overflow-auto">
        <Collapse in={isExpanded} className="">
          <TableContainer className="">
            <Table variant="simple" className="  ">
              <Thead>
                <Tr>
                  <Th>File Name</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {supportedFiles &&
                  supportedFiles?.map((file) => (
                    <Tr key={file.name}>
                      <Tooltip label={file.name}>
                        <Td>{file.name}</Td>
                      </Tooltip>
                      <Td>
                        {isLoading ? (
                          <CircularProgress
                            value={uploadProgress[file.name] || 0}
                            min={0}
                            max={100}
                          />
                        ) : (
                          "Uploaded"
                        )}
                      </Td>
                    </Tr>
                  ))}
                <Divider as={Tr} />
                {unsupportedFiles &&
                  unsupportedFiles?.map((file) => (
                    <Tr key={file.name}>
                      <Td>{file.name}</Td>
                      <Td className="!text-center">
                        <Tooltip label="Unsupported extension">
                          <WarningIcon color="primary.red" />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Collapse>
      </div>
    </Box>
  );
};
