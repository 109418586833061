import { useToast } from "@chakra-ui/react";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../api/firebaseApi";
import { fetchFiles, generatePdfThumbnail } from "../helpers/helpers";
import { setTemplates } from "../redux/templates/templatesSlice";
import { CustomTemplate } from "../types/drafts.type";
import { DATABASE } from "../types/tables-data";

// ---- Additional utilities for file fetching & thumbnails ----
// import { fetchFiles, generatePdfThumbnail } from "../utils/fileUtils";

interface FirestoreHookOptions {
  listen?: boolean;
  fetchFiles?: boolean; // <--- Add a flag to optionally fetch files
  withThumbnails?: boolean; // <--- Add a flag for generating PDF thumbnails
}

/**
 * This custom hook fetches documents from a Firestore collection,
 * and optionally fetches associated file URLs (and PDF thumbnails)
 * for each document. The data is dispatched to Redux once the fetch
 * (and file retrieval) is complete.
 */
function useFirestoreCollection<T>(
  collectionPath?: string,
  options: FirestoreHookOptions = {
    listen: false,
    fetchFiles: false,
    withThumbnails: false
  }
) {
  const dispatch = useDispatch();
  const toast = useToast();

  const [documents, setDocuments] = useState<T[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { listen, fetchFiles: shouldFetchFiles, withThumbnails } = options;

  const handleDispatch = useCallback(
    (data: T[]) => {
      if (!collectionPath) return;
      const firstSegment = collectionPath.split("/")[0];
      switch (firstSegment as DATABASE) {
        case DATABASE.CUSTOM_TEMPLATES:
          dispatch(setTemplates({ templates: data as CustomTemplate[] }));
          break;
        default:
          console.log("Unknown database type", { firstSegment });
          break;
      }
    },
    [collectionPath, dispatch]
  );

  /**
   * Optional function to fetch files and (optionally) generate thumbnails
   * for a single document object. Adjust to your data model as needed.
   */
  const fetchDocFilesAndThumbnails = async (docData: any) => {
    const finalData = { ...docData };

    // If the document has a filePath, fetch the file URL(s).
    if (shouldFetchFiles && finalData.filePath) {
      try {
        const docUrl = await fetchFiles([finalData.filePath]).then(
          (urls) => urls[0] || null
        );
        finalData.docUrl = docUrl ?? "";

        // Optionally generate a PDF thumbnail if it's a PDF
        if (withThumbnails && finalData.filePath.endsWith(".pdf") && docUrl) {
          finalData.thumbnail = await generatePdfThumbnail(docUrl);
        }
      } catch (err) {
        console.error("Error fetching file or thumbnail:", err);
      }
    }

    return finalData;
  };

  useEffect(() => {
    if (!collectionPath) {
      console.log("useFirestoreCollection: No collectionPath provided");
      return;
    }
    const colRef = collection(db, collectionPath);

    // Exclude documents with isDeleted === true
    const q = query(colRef, where("isDeleted", "==", false));
    let unsubscribe: (() => void) | undefined;

    const handleError = (err: any) => {
      console.error("Error fetching data:", err);
      setError(err);
      setLoading(false);
      toast({
        title: "Error fetching data",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true
      });
    };

    if (listen) {
      // 1) Real-time updates via onSnapshot
      unsubscribe = onSnapshot(
        q,
        async (snapshot) => {
          try {
            // --- Map Firestore docs to data array ---
            const dataPromises = snapshot.docs.map(async (doc) => {
              let docData = {
                id: doc.id,
                path: doc.ref.path,
                ...doc.data()
              };

              // --- Optionally fetch file URLs / PDF thumbnails ---
              docData = await fetchDocFilesAndThumbnails(docData);

              return docData;
            });

            // --- Wait for all file fetches ---
            const data = (await Promise.all(dataPromises)) as T[];
            setDocuments(data);
            handleDispatch(data);
          } catch (err) {
            handleError(err);
          } finally {
            setLoading(false);
          }
        },
        (err) => {
          handleError(err);
        }
      );
    } else {
      // 2) One-time fetch using getDocs
      const fetchData = async () => {
        setLoading(true);
        try {
          const snapshot = await getDocs(q);
          const dataPromises = snapshot.docs.map(async (doc) => {
            let docData = {
              id: doc.id,
              ...doc.data()
            };

            // --- Optionally fetch file URLs / PDF thumbnails ---
            docData = await fetchDocFilesAndThumbnails(docData);

            return docData;
          });

          const data = (await Promise.all(dataPromises)) as T[];
          setDocuments(data);
          handleDispatch(data);
        } catch (err) {
          handleError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }

    // Cleanup listener if `listen` was true
    /* eslint-disable consistent-return */
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    /* eslint-enable consistent-return */
  }, [
    collectionPath,
    listen,
    shouldFetchFiles,
    withThumbnails,
    handleDispatch,
    toast
  ]);

  return { documents, loading, error };
}

export default useFirestoreCollection;
